export const UsersSection = () => {
  return (
    <div>
      <center>
        <h3>
          <strong>
            Términos y condiciones de uso de uTriper por parte de Tour Usuarios
          </strong>
        </h3>
      </center>
      <br />
      Los términos y condiciones de uso pactados en este documento (“
      <strong>Términos y Condiciones</strong>”) constituyen un contrato legal y
      aplicable entre usted (“<strong>Usted</strong>” y “
      <strong>Tour Usuario</strong>”) como futuro o actual usuario de la
      Plataforma (como se define más adelante) interesado en visitar la
      información y comprar los de servicios turísticos y/o actividades de
      entretenimiento y servicios ofrecidos en la misma (la (s) “
      <strong>Experiencia(s) Turística(s)</strong>”) y Global Triper Colombia
      S.A.S. ("<strong>uTriper</strong>”, “<strong>Nosotros</strong>” o “
      <strong>nuestro</strong>”). Estos Términos y Condiciones rigen su uso de
      los contenidos, productos y servicios ofrecidos a través de la plataforma
      de uTriper, alojados en, a discreción de uTriper: (i) el sitio web{' '}
      <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a> , otros dominios (urls),
      (ii) aplicativos móviles que se puedan descargar por medio de tiendas
      virtuales de aplicaciones y conjunto de sistemas (de forma conjunta, la “
      <strong>Plataforma</strong>”).
      <br />
      <br />
      Cualquier persona que desee acceder o hacer uso de la Plataforma o las
      Experiencias Turísticas que en esta se ofrecen, podrá hacerlo sujetándose
      a los presentes Términos y Condiciones, así como a políticas y principios
      incorporados al presente documento. Al acceder navegar y utilizar nuestra
      Plataforma y/o al realizar alguna reserva, Usted acepta y se obliga a
      cumplir los siguientes Términos y Condiciones. Por favor, revise estos
      Términos y Condiciones de manera detallada y cuidadosa. Los servicios que
      ponemos a su disposición a través de la Plataforma, están sujetos a estos
      Términos y Condiciones. En todo caso, cualquier persona que no acepte los
      presentes Términos y Condiciones, deberá abstenerse de utilizar la
      Plataforma y/o adquirir las Experiencias Turísticas que en su caso sean
      ofrecidos.
      <br />
      <br />
      El contenido que se encuentra en la Plataforma pertenece y que es
      gestionado por uTriper se pone su disposición únicamente para su uso
      personal y no para usos comerciales, en caso de que Usted desee utilizar
      la Plataforma para fines comerciales como lo es el ofrecer servicios
      turísticos y/o actividades de entretenimiento, Usted deberá referirse a
      los “Términos y Condiciones De Uso de Tour Operadores” los cuales se
      encuentran disponibles aquí.
      <br />
      <br />
      Fecha de entrada en vigencia: 1 de febrero de 2022
      <br />
      <br />
      Fecha de publicación: 1 de febrero de 2022
      <br />
      <br />
      <strong>
        1. <u>Servicios de uTriper</u>
      </strong>
      <br />
      <br />
      <strong>1. 1. Servicios.</strong> uTriper es una Plataforma que actúa como
      intermediaria y le permite al Tour Usuario acceder a las Experiencias
      Turísticas que son ofertadas, anunciadas, publicadas, vendidas y
      promocionadas (según corresponda) por los usuarios que ofrecen dichas
      Experiencias Turísticas (el “<strong>Tour Operador</strong>”) también
      registrado en la Plataforma. uTriper le da la posibilidad al Tour Usuario
      de explorar las ofertas de las Experiencias Turísticas, guardar las
      ofertas de su elección en su perfil para adquirirlas luego, enviar
      mensajes de datos al Tour Operador solicitando información adicional,
      reservar y pagar las Experiencias Turísticas, entre otros servicios
      adicionales (los “<strong>Servicios</strong>”). Las Partes reconocen que
      uTriper no funciona como operador de servicios turísticos, y que las
      Experiencias en la Plataforma son ofrecidas y ejecutadas por el Tour
      Operador con total independencia.
      <br />
      <br />
      Toda persona que tenga acceso a la Plataforma será considerada como Tour
      Usuario bajo la interpretación de los presentes Términos y Condiciones,
      sin importar si adquiere o no las Experiencias Turísticas. El simple hecho
      de navegar por la Plataforma lo constituye en Tour Usuario. Sin perjuicio
      de lo anterior, al adquirir las Experiencias Turísticas, el Tour Usuario
      establece una relación contractual independiente con el Tour Operador del
      cual adquirió dichas Experiencias Turísticas y por lo tanto uTriper queda
      excluido de esta relación contractual y de las obligaciones que puedan
      derivarse de esta.
      <br />
      <br />
      <strong>
        2. <u>Registro en uTriper</u>
      </strong>
      <br />
      <br />
      <strong>2. 1. Cuenta Tour Usuario.</strong> Para navegar en la Plataforma,
      el Tour Usuario deberá crear una cuenta en la Plataforma. Para crear su
      cuenta, Usted debe ingresar su dirección de correo electrónico y elegir su
      contraseña (la “<strong>Cuenta Tour Usuario</strong>”). Estos datos
      corresponden a sus credenciales para ingresar y navegar a la Plataforma.
      Posterior a la creación de la Cuenta Tour Usuario, Usted recibirá un
      código de verificación de seguridad en el correo indicado, con lo cual
      contará con un tiempo de 30 minutos para que Usted pueda validar la
      creación de su cuenta. Esta verificación es necesaria para que uTriper
      pueda validar los datos ingresados por Usted.
      <br />
      <br />
      <strong>2. 2. Set-up de su perfil.</strong> Si el Tour Usuario quiere
      hacer uso los Servicios ofrecidos en la Plataforma, deberá crear un perfil
      asociado a la Cuenta de Tour Usuario. Para esto, uTriper le requerirá
      diligenciar un formulario de ingreso el cual le solicitará ciertos datos
      personales como: nombre completo, dirección de correo electrónico, número
      de documento de identificación, número de teléfono.
      <br />
      <br />
      <strong>2. 3. Veracidad de la información y verificación.</strong> Es
      responsabilidad del Tour Usuario brindar información lícita, real, exacta,
      veraz y actualizada y por lo tanto uTriper se reserva el derecho de
      rechazar cualquier solicitud de inscripción o de cancelar una inscripción
      previamente aceptada, sin que esté obligado a comunicar o exponer las
      razones de su decisión y sin que ello genere algún derecho a indemnización
      o resarcimiento. uTriper no se responsabiliza por la certeza de los datos
      personales provistos por los Tour Usuarios ni por los Tour Operadores.
      <br />
      <br />
      <strong>
        3. <u>Proceso de compra de las Experiencias Turísticas</u>
      </strong>{' '}
      El Tour Usuario podrá navegar por la Plataforma para conocer las
      diferentes ofertas y anuncios de las Experiencias Turísticas que los Tour
      Operadores exhiban. Las condiciones, requisitos y descripción de las
      Experiencias Turísticas serán establecidas por cada Tour Operador en su
      propio perfil.
      <br />
      <br />
      <strong>3. 1. Reservas Experiencias Turísticas.</strong> El Tour Usuario
      que desee adquirir una Experiencia Turística, deberá realizar la reserva
      de la misma. Al momento de realizar la reserva, uTriper le hará un cobro
      no reembolsable por la reserva correspondiente al 10% del valor total de
      la Experiencia Turística (la “<strong>Reserva</strong>”) más el valor
      correspondiente al procesamiento de pagos. Para lo cual, Usted deberá
      diligenciar los datos de su tarjeta de crédito para que uTriper pueda
      realizar el cobro correspondiente.
      <br />
      <br />
      <strong>3. 2.</strong> Antes de confirmar el pago de la Reserva por favor
      asegúrese de verificar minuciosamente los requisitos y las condiciones de
      la Experiencia Turística que va a adquirir. Es su responsabilidad haber
      leído detenidamente la descripción y las condiciones especiales de la
      Experiencia Turística de su elección. Las condiciones de pago,
      terminación, políticas de cancelaciones y reembolsos se evidenciará en
      cada oferta de cada Experiencia Turística.
      <br />
      <br />
      <strong>3. 3.</strong> Una vez realizada la Reserva correspondiente, el
      Tour Usuario es responsable de informar al Tour Operador cualquier
      requisito o necesidades especiales que Usted o un miembro de su grupo
      pueda necesitar como resultado de cualquier problema médico
      <br />
      <br />
      <strong>3. 4.</strong> <strong>Correo Confirmatorio</strong>uTriper le
      enviará un correo electrónico de confirmación de la Reserva sobre la
      Experiencia Turística que usted reservó. Dicho correo contará con las
      condiciones generales de la Experiencia Turística en cuanto a costos,
      fechas oportunas de pago, condiciones específicas de cancelación y de
      requisitos de la misma.
      <br />
      <br />
      <strong>3. 5.</strong> El Tour Usuario reconoce y acepta que uTriper no es
      el responsable de proveer las Experiencias Turísticas, así como no es
      proveedor, expendedor, agente, distribuidor y en general ningún tipo de
      comercializador de las Experiencias Turísticas que exhibe en la Plataforma
      ya que opera como un intermediario que permite el encuentro de Tour
      Usuarios y Tour Operadores para la adquisición de Experiencias Turísticas.
      <br />
      <br />
      <strong>
        4. <u>Precio y forma de pago.</u>
      </strong>{' '}
      Todos los precios de las Experiencias Turísticas son establecidos por el
      Tour Operador y ya cuentan con los impuestos correspondientes de ser el
      caso, por lo tanto, uTriper no participa en la determinación de dichos
      precios ni en las posibles ofertas o descuentos de estas. Los precios
      asociados a entradas están establecidos por persona o grupo de acuerdo con
      cada Experiencia Turística.
      <br />
      <br />
      <strong>
        4 . 1.<u>Formas de pago:</u>
      </strong>
      . Dependiendo de la configuración establecida por el Tour Operador, las
      Experiencias Turísticas tendrán alguna de las siguientes modalidades de
      pago:
      <br />
      <br />
      <dl>
        <dd>
          <strong>(a) Pago total anticipado:</strong>El Tour Usuario deberá
          realizar la totalidad del pago del precio de la Experiencia Turística
          (es decir, deberá cancelar el 100% del precio) por medio de la
          Plataforma en el momento de realizar la Reserva.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b) Pago Parcial:</strong> El Tour Usuario deberá pagar
          anticipadamente al momento de la reserva únicamente el valor
          correspondiente a la Reserva a través de la Plataforma, y, el valor
          correspondiente al excedente (es decir el 90% del precio de la
          Experiencia Turística) deberá ser cancelada directamente al Tour
          Operador en el momento de la Experiencias Turística. Cada Tour
          Operador contará con su propio método de pago, por lo que uTriper no
          se hará responsable por disponibilidad de métodos de pago ni tampoco
          por su funcionamiento.
        </dd>
        <dd>
          <br />
          <br />
          <strong>4. 2. Procresamiento de pago.</strong> uTriper facilita, por
          medio de procesadores de pagos externos, el pago correspondiente a la
          Reserva y/o a las Experiencias Turísticas. uTriper recibe el dinero
          correspondiente al pago total de las Experiencias Turísticas en nombre
          del Tour Operador y por cuenta del Tour Usuario.
          <br />
          <br />
          uTriper utiliza el procesador de pago de un tercero (el{' '}
          <strong>Procesador de Pago</strong>) para vincular su cuenta de la
          tarjeta de crédito a la Plataforma y a las Experiencias Turísticas. El
          procesamiento de pagos o créditos, según sea de aplicación, con
          respecto al uso que haga de la Plataforma y las Experiencias
          Turísticas estará sujeto a las condiciones y políticas de privacidad
          del Procesador del Pago y el emisor de su tarjeta además de a estos
          Términos y Condiciones. uTriper no será responsable de ningún error
          del Procesador de Pago. En relación con su uso de estos servicios,
          uTriper obtendrá determinados datos de la transacción, que uTriper
          utilizará únicamente con arreglo a su Privacidad y a su Aviso de
          Cookies.
          <br />
          <br />
          <strong>
            <u>5. Derecho de Retracto y Reversión de pago.</u>
          </strong>
          <br />
          <br />
          En virtud de la Ley 1480 de 2011, Usted podrá retractarse de su
          compra, tanto de la Reserva como de la compra de la Tour Experiencia,
          siempre que lo haga dentro de cinco (5) días hábiles siguientes a la
          compra, y en el entendido que no haya comenzado la prestación o la
          fecha de la Experiencia Turística. En dado caso, se devolverá el
          dinero al Tour Usuario el precio correspondiente a la Experiencia
          Turística o Reserva, según sea el caso, sin que proceda a hacer
          descuentos o retenciones por concepto alguno. En todo caso la
          devolución del dinero al Tour Usuario no excederá treinta (30) días
          calendario desde el momento en que ejerció el derecho.
          <br />
          <br />
          Así mismo, Usted tiene derecho a solicitar la reversión del pago
          cuando sea objeto de fraude, o corresponda a una operación no
          solicitada, o la Tour Experiencia adquirida no sea prestada por el
          Tour Operador, o esta no corresponda a la solicitada. Para que proceda
          la reversión en el pago Usted deberá presentar queja ante el Tour
          Operador dentro de los 5 días hábiles siguientes a la fecha en que
          tuvo conocimiento de la operación fraudulenta, no solicitada o no
          prestada por el Tour Operador. Igualmente, deberá notificar al emisor
          del instrumento de pago electrónico utilizado para realizar la compra,
          el cual, en conjunto con los demás participantes del proceso de pago,
          procederán a reversar la transacción al Tour Operador.
          <br />
          <br />
          <strong>
            <u>6. Modificaciones, Suspensión y Notificaciones.</u>
          </strong>
          <br />
          <br />
          <strong>6. 1. Modificaciones.</strong> uTriper se reserva el derecho
          de modificar y ajustar, sin previo aviso, el diseño y contenido de la
          Plataforma, así como algunos o todos los Servicios, y/o añadir
          servicios nuevos. Asimismo, uTriper se reserva el derecho de modificar
          y revisar los Términos y Condiciones en cualquier momento y sin previo
          aviso. Cuando se lleve a cabo una modificación, uTriper se publicará
          los Términos y Condiciones modificados (“
          <strong>Versión Nueva</strong>”) en la Plataforma para que los
          usuarios puedan leer los cambios correspondientes. Así mismo, uTriper
          le notificará al Tour Usuario a través de la dirección de correo
          suministrada o mediante cualquier otro medio que sea adecuado, la
          Versión Nueva.
          <br />
          <br />
          La Versión Nueva estará vigente desde el momento de su publicación,
          pero no se aplicará en forma retroactiva. El Tour Usuario tendrá la
          opción de aceptar de forma expresa la Versión Nueva en el momento en
          el que uTriper le comuniqué sobre la actualización. Sin perjuicio de
          lo anterior, el uso continuo de los Servicios y de la Plataforma por
          parte del Tour Usuario después de la publicación de una Versión Nueva
          constituirá en la aceptación tácita de dicha Versión Nueva.
          <br />
          <br />
          <strong>6. 2. Suspensión y Terminación.</strong> uTriper se reserva el
          derecho de suspender o cancelar su Cuenta Tour Usuario o su acceso a
          cualquier Servicio, en cualquier momento y por cualquier motivo.
          Podemos agregar o eliminar, suspender, borrar, discontinuar o
          establecer condiciones para los Servicios o cualquier característica o
          aspecto de un Servicio. Tomaremos medidas razonables para notificarle
          respecto a la cancelación u otros tipos de cambios por correo
          electrónico o la próxima vez que intente acceder a su Cuenta Tour
          Usuario. Podemos suspender de manera permanente o temporal el acceso a
          los Servicios o el acceso a la Plataforma sin previo aviso ni
          responsabilidad por cualquier motivo, o sin ningún motivo. En casos de
          terminación, no será exigible ninguna cláusula penal ni el
          reconocimiento de suma alguna por cualquier concepto.
          <br />
          <br />
          <strong>6. 3. Consentimiento Electrónico.</strong> Usted acepta que
          uTriper puede enviar declaraciones y avisos según lo exija la ley y
          publicar otra información sobre su Cuenta Tour Usuario de forma
          electrónica mediante la publicación en nuestra Plataforma, el envío de
          notificaciones push a través de los Servicios, o por correo
          electrónico a la dirección registrada en su Cuenta Tour Usuario. Usted
          es responsable de administrar su cuenta de correo electrónico y de
          suministrar a uTriper su cuenta de correo electrónico más actualizada.
          La información y los avisos electrónicos tienen el mismo significado y
          efecto que si se le hubieran entregado en copias impresas en papel.
          Para efectos de notificación, se considera que Usted ha recibido esta
          información y avisos dentro de las 24 (veinticuatro) horas desde la
          hora publicada en nuestra la Plataforma, o dentro de las 24
          (veinticuatro) horas desde la hora en que le fueron enviados por
          correo electrónico. En el caso de que la última dirección de correo
          electrónico que nos haya proporcionado no sea válida, o por cualquier
          motivo no pueda enviarle la notificación descrita anteriormente,
          nuestro envío del correo electrónico que contiene dicha notificación
          constituirá, no obstante, una notificación efectiva de los cambios
          descritos en la misma.
          <br />
          <br />
          <strong>
            7. <u>Responsabilidades del Tour Usuario.</u>
          </strong>{' '}
          Usted, como usuario de la Plataforma asume las siguientes
          responsabilidades:
          <br />
          <br />
          <strong>7. 1.</strong> Usted deberá cumplir con las condiciones
          determinadas por cada Tour Operador para la Tour Experiencia,
          incluyendo entre otras cosas, ceñirse a la política de cancelación
          establecida por el Tour Operador de ser el caso.
          <br />
          <br />
          <strong>7. 2.</strong> Usted es único responsable de que su Cuenta
          Tour Usuario se mantenga confidencial, esto incluye la información de
          ingreso a la Plataforma y de restringir el acceso a los Servicios
          desde sus dispositivos compatibles. Por ende, Usted es exclusivamente
          responsable de todas las actividades que ocurran bajo su Cuenta Tour
          Usuario, incluyendo los pagos realizados. Usted acuerda notificar
          inmediatamente a uTriper acerca de cualquier hecho que permita suponer
          el uso indebido de la información registrada en la Plataforma, como,
          por ejemplo, robo, extravío, o acceso no autorizado a cuentas y/o
          contraseñas, con el fin de proceder a su inmediata cancelación. Usted
          de manera inmediata tomará todas las medidas razonables para mitigar
          los efectos de una vulneración de seguridad y proporcionará toda la
          información solicitada por uTriper para remediar la vulneración. A
          pesar de lo anterior, uTriper no será responsable en ningún caso por
          cualquier pérdida o daño derivado de su falta de cumplimiento con los
          requisitos establecidos anteriormente.
          <br />
          <br />
          <strong>7. 3.</strong> Es responsabilidad del Tour Usuario utilizar la
          Plataforma de acuerdo con la forma en la que fue diseñada; en este
          sentido, queda prohibida la utilización de cualquier tipo de software
          que automatice la interacción o descarga de los contenidos o servicios
          proporcionados a través de la Plataforma.
          <br />
          <br />
          <strong>7. 4.</strong> El Tour Usuario se compromete a utilizar la
          información, contenidos o servicios ofrecidos a través de la
          Plataforma de manera lícita, sin contravenir lo dispuesto en los
          presentes Términos y Condiciones, la moral y/o el orden público, y se
          abstendrá de realizar cualquier acto que pueda suponer una afectación
          a los derechos de terceros, o perjudique de algún modo el
          funcionamiento de la Plataforma.
          <br />
          <br />
          <strong>7. 5.</strong> En los casos en los que el Tour Usuario sea una
          persona natural, deberá contar la mayoría de edad requerida (+18 años)
          para usar la Plataforma, de lo contrario debe abstenerse de utilizar
          la Plataforma.
          <br />
          <br />
          <strong>7. 6. Deberes Generales.</strong> El Tour Usuario se
          compromete a cumplir con los siguientes deberes generales:
          <br />
          <br />
        </dd>
        <dd>
          (a) No utilizará una cuenta que esté sujeta a cualquier derecho de una
          persona que no sea la propia sin la autorización adecuada. Así mismo,
          no perjudicará el funcionamiento adecuado de la red, ni tratará de
          dañar la Plataforma de ningún modo.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          (b) No copiará ni distribuirá la Plataforma ni ningún contenido sin el
          permiso escrito.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>(c) Cumplirá con toda la legislación aplicable en Colombia.</dd>
        <dd>
          <br />
        </dd>
        <dd>
          (d) Cumplirá con todas las obligaciones de estos Términos y
          condiciones.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            8. <u>No Show.</u>
          </strong>{' '}
          Al realizar una Reserva con el Tour Operador, Usted acepta las
          condiciones de cancelación y no show (en caso de no presentación)
          establecidas por el Tour Operador, así como otros términos y
          condiciones adicionales del Tour Operador que pueda afectar su
          Experiencia Turística. Usted tiene el deber de informarse sobre dichos
          términos y condiciones especiales de la Experiencia Turística del Tour
          Operador las cuales se encuentran a su disposición en el perfil del
          Tour Operador dentro de la Plataforma y las cuales serán enviadas a su
          correo electrónico por medio del Correo Confirmatorio. En ningún caso
          el precio pagado por la Reserva ni el precio asociado a la Experiencia
          Turística será reembolsable por el No Show. El no acudir a la
          Experiencia Turística en las horas indicadas por el Tour Operador
          puede conllevar a que se configure un No Show
          <br />
          <br />
          <strong>8. 1. No Show en pagos parciales.</strong> En caso de que la
          forma de pago de la Experiencia Turística sea mediante un Pago Parcial
          y Usted no se presente a esta sin haber realizado una previa y
          adecuada cancelación, entonces Usted autoriza a uTriper para que este
          debite de su cuenta de manera automática el remanente del costo de la
          Experiencia Turística (es decir el 90%) a modo de penalidad por el No
          Show.
          <br />
          <br />
          <strong>
            9. <u>Cancelaciones del Tour Operador.</u>
          </strong>{' '}
          El Tour Operador podrá cancelar su Experiencia Turística, sin
          necesidad de previo aviso.
          <br />
          Así mismo podrá cancelar en caso de que la Experiencia Turística
          requiera el pago de un depósito o el pago total por adelantado, y por
          algún motivo, las cantidades pendientes de pago no puedan ser cobradas
          en su totalidad en la fecha pertinente. Las condiciones de cancelación
          y pago son establecidas por cada Tour Operador y por lo tanto varían
          dependiendo de este. Los retrasos en el pago, los datos bancarios
          erróneos, los datos de las tarjetas de crédito, las tarjetas de
          crédito no válidas o con saldo insuficiente son de su entera
          responsabilidad.
          <br />
          <br />
          <strong>
            10. <u>Overbooking.</u>
          </strong>{' '}
          En el caso en el que el Tour Operador presente una sobreocupación en
          una Experiencia Turística (el “<strong>Overbooking</strong>”) y como
          consecuencia Usted no pueda disfrutar de la Experiencia Turística
          adquirida o reservada, el Tour Operador será el único responsable de
          dicho Overbooking y por tal deberá hacerse responsable por los
          posibles perjuicios generados al Tour Usuarios. Lo anterior puede
          incluir pagar el transporte de regreso de los Tour Usuarios, entre
          otras medidas que sea justas y proporcionales para dicho
          restablecimiento. Usted reconoce y acepta que en ningún caso uTriper
          será responsable de dicho Overbooking.
          <br />
          <br />
          <strong>
            11.{' '}
            <u>Cancelaciones y modificaciones de la Experiencia Turística</u>
          </strong>{' '}
          Cada Tour Operador cuenta con su propia política de cancelación,
          modificación y confirmación, establecida en su perfil. En caso de que
          Usted desee revisar, modificar o cancelar la Experiencia Turística,
          deberá ponerse en contacto con el Tour Operador correspondiente y
          seguir los pasos y tiempos establecidos en sus políticas. En ningún
          caso, será reembolsable el importe pago por Reserva.
          <br />
          Cada Tour Operador ha designado a una persona encargada para resolver
          todas las preguntas, quejas o sugerencias respecto a las Experiencias
          Turísticas promocionadas, o cualquier ayuda que pudiese requerir.
          Usted encontrará dicha información en el perfil del Tour Operador.
          uTriper no se hace responsable en ningún caso de las consecuencias que
          se puedan derivar de la cancelación y/o modificación correspondiente.
          <br />
          <br />
          <strong>
            12. <u>Acceso a la Plataforma</u>
          </strong>{' '}
          El contenido de la Plataforma es para su información y uso general;
          este contenido está sujeto a cambios sin previo aviso. uTriper ha
          hecho esfuerzos razonables para incluir información exacta y al día en
          la Plataforma, sin embargo, uTriper no puede garantizar la
          disponibilidad de la misma. Por lo tanto, uTriper no garantiza el
          acceso a la Plataforma o al contenido, de tal forma no será
          responsable de que esta se encuentre libre de errores, software
          malicioso, o que pueda causar algún daño a nivel de software o
          hardware en el equipo a través del cual el Tour Usuario accede a la
          Plataforma. Asimismo, uTriper se reserva el derecho de realizar
          mantenimientos a la Plataforma con o sin previo aviso cuando debido a
          la materialidad del daño o de la urgencia, así se requiera.
          <br />
          <br />
          <strong>
            13. <u>Peticiones, sugerencias, quejas o reclamos.</u>
          </strong>{' '}
          uTriper sitúa en su Plataforma un botón de PQRS para que, mediante
          este, los Tour Usuarios puedan tramitar sus preguntas, sugerencias,
          quejas y/o reclamos (la “Consulta”). Lo anterior, permite a los Tour
          Usuarios ejercer cualquiera de sus derechos como consumidores a los
          Tour Operadores. El botón de PQRS opera únicamente como un canal de
          mensajería electrónica, lo cual implica que el Tour Usuario realizará
          su Consulta a través del botón, y, uTriper le remitirá dicha Consulta
          al Tour Operador correspondiente.
          <br />
          <br />
          <strong>
            14. <u>Propiedad intelectual y Derechos de Autor.</u>
          </strong>{' '}
          Usted reconoce que todos los derechos derivados de la propiedad
          intelectual que se encuentren contenidos en la Plataforma pertenecen
          de manera exclusiva a uTriper y/o cualquiera de sus aliados y por lo
          tanto no serán usados sin previo consentimiento escrito. El uso de los
          contenidos de la Plataforma por fuera de los presentes Términos y
          Condiciones está totalmente prohibido. El uso de los contenidos sin
          previa autorización de uTriper o de la persona propietaria del
          contenido podría violar los derechos de autor y/o de propiedad
          industrial, al igual que los derechos de habeas data y de privacidad
          de la información que están amparados por las leyes, regulaciones y
          estatutos nacionales e internacionales.
          <br />
          <br />
          <strong>
            15. <u>Enlaces de terceros y cookies.</u>
          </strong>
          <br />
          <br />
          <strong>15. 1. Enlaces de terceros.</strong> La Plataforma podrá
          incluir enlaces que permitan acceder a páginas web de terceros
          distintos de uTriper. La utilización de estos enlaces tiene como
          objetivo el informar al usuario sobre la existencia de otras fuentes
          en las cuales se pueden ampliar los contenidos ofrecidos, o que
          guardan relación con estos, sin que lo anterior se considere como una
          sugerencia, recomendación o invitación para hacer uso de sitios web
          externos, ni tampoco implica necesariamente la existencia de
          relaciones entre uTriper y el propietario del enlace vinculado, ni la
          aceptación o aprobación por parte de uTriper de sus contenidos o
          servicios. Por lo tanto, uTriper no garantiza ni se responsabiliza por
          el funcionamiento o accesibilidad a los enlaces vinculados, así como
          tampoco del contenido en ellos publicado. uTriper en ningún caso
          revisará o controlará el contenido de los sitios web externos. Los
          titulares de dichos enlaces dispondrán de sus propias políticas de
          privacidad y protección de datos, por lo cual uTriper no asume ningún
          tipo de responsabilidad por los datos que sean facilitados por el
          usuario a través de cualquier sitio web distinto a la Plataforma.
          <br />
          <br />
          <strong>15. 2. Cookies.</strong> La Plataforma utiliza cookies para
          mejorar la experiencia, se recolecta información acerca de la manera
          en cómo el usuario interactúa con el sitio web de la Plataforma y
          recordarlo. En caso de que se rechace la política de cookies, no se
          realizará seguimiento a la información cuando visite esta página.
          <br />
          <br />
          <strong>
            16. <u>Indemnidad.</u>
          </strong>
          <br />
          <br />
          El Tour Usuario, exonera de toda responsabilidad a uTriper y se obliga
          a mantener indemne a uTriper y a nuestros respectivos empelados,
          directores, agentes, afiliados y representantes de cualquier reclamo,
          costo, pérdida, daño, juicio, evaluación de impuestos, multa, interés;
          perjuicio, daño, pasivo y/o contingencia (incluidos sin limitación
          honorarios razonables de abogados) que surjan de cualquier reclamo,
          acción, auditoría, investigación, indagación o en relación con los
          mismos, u otro procedimiento iniciado por cualquier persona o entidad
          que surja o se relacione con: (a) cualquier incumplimiento real o
          supuesto de sus declaraciones, garantías u obligaciones reconocidos en
          los presentes Términos y Condiciones; (b) el uso indebido o
          inapropiado de los Servicios; (c) la violación de cualquier derecho de
          terceros, inclusive cualquier derecho de privacidad, de publicidad o
          de propiedad intelectual; (d) la violación de cualquier ley, norma o
          reglamento en cualquier otro país; (e) el acceso y/o uso de los
          Servicios con su nombre único, contraseña u otro código de seguridad
          adecuado por otras partes; (f) cualquier transacción, compra, producto
          o servicio con respecto al cual el Tour Operador haya proporcionado a
          través de la Plataforma, o le haya proporcionado, servicios de
          procesamiento de pagos de conformidad con los Términos y Condiciones;
          y, (h) el incumplimiento de cualquier disposición conforme a la
          protección del consumidor.
          <br />
          <br />
          El Tour Usuario renuncia expresamente a presentar cualquier
          reclamación, demanda o acción judicial y/o administrativa en contra de
          uTriper, sus administradores, representantes yaccionistas, pues
          entiende y acepta que la labor de uTriper es operar como un portal de
          contacto, al poner a disposición la Plataforma para que los Tour
          Operadores puedan ofrecer sus productos y/o servicios para su
          comercialización y a su vez los Tour Usuarios puedan contactarlos por
          medio de la misma Plataforma. Por consiguiente, uTriper no será
          responsable por ningún daño o perjuicio que pueda sufrir por causa o
          con ocasión de la compra y venta de las Experiencias Turísticas a
          través de la Plataforma.
          <br />
          <br />
          <strong>
            17. <u>Limitación de responsabilidad de uTriper.</u>
          </strong>{' '}
          uTriper opera como un portal digital el cual habilita el espacio para
          la comunicación entre el Tour Operador y el Tour Usuario, con el
          objetivo de que estos celebren un contrato de compraventa o cualquier
          otro tipo de contrato lícito, con el fin de adquirir Experiencias
          Turísticas. Por consiguiente, y, bajo este entendimiento, uTriper no
          es el prestador de ningún servicio ofrecido a los Tour Usuarios, ni
          tampoco tiene el dominio, la disposición ni la posesión de estos.
          uTriper no es ni será responsable por el efectivo cumplimiento de
          parte del Tour Operador de las condiciones y características de las
          Experiencias Turísticas ofertadas en la Plataforma. El Tour Usuario
          reconoce y acepta, entonces, que las operaciones que realice con el
          Tour Operador y terceros, será bajo su propio riesgo. El Tour Operador
          no hará responsable a uTriper de ningún cargo (autorizado o no) ni por
          ningún importe en su tarjeta de crédito.
          <br />
          <br />
          <strong>
            18. <u>Cláusula de Arbitramento.</u>
          </strong>{' '}
          Antes de que se inicie un arbitraje, Usted y uTriper se comprometen a
          intentar evitar los costos de la resolución formal de disputas
          concediéndose mutuamente una oportunidad plena y justa para abordar y
          resolver la disputa informalmente y de manera directa. Ambas partes
          reconocen que este es un requisito fundamental e indispensable para
          proceder con el arbitramento, y que la vulneración de este requisito
          implicaría la vulneración material de los Términos y Condiciones.
          <br />
          Las controversias que no puedan ser resueltas de forma directa entre
          las partes, se resolverán por un Tribunal de Arbitramento presentado
          ante el Centro de Arbitraje y Conciliación de la Cámara de Comercio de
          Bogotá, el cual estará sujeto a sus reglamentos y al procedimiento
          allí contemplado, de acuerdo con las siguientes reglas:
          <br />
          <br />
        </dd>
        <dd>
          (a) El Tribunal estará integrado por un (1) arbitro designado por las
          partes de común acuerdo. En caso de que no fuere posible, los árbitros
          serán designados por el Centro de Arbitraje y Conciliación de la
          Cámara de Comercio, a solicitud de cualquiera de las partes.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>(b) El Tribunal decidirá en derecho.</dd>
        <dd>
          <br />
        </dd>
        <dd>
          (c) El Tribunal sesionará en las instalaciones del Centro de Arbitraje
          y Conciliación de la Cámara de Comercio de Bogotá.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
      </dl>
    </div>
  );
};

export const TourOperatorsSection = () => {
  return (
    <div>
      <center>
        <h3>
          <strong>
            Términos y condiciones de uso de uTriper para Tour Operadores
          </strong>
        </h3>
      </center>
      <br />
      Los términos y condiciones de uso pactados en este documento (“
      <strong>Términos y Condiciones</strong>”) constituyen un contrato legal y
      aplicable entre usted (“<strong>Usted</strong>” y “
      <strong>Tour Operador</strong>”) para ofrecer y anunciar planes de
      servicios turísticos y/o actividades de entretenimiento (la (s) “
      <strong>Experiencia(s) Turística(s)</strong>”) y Global Triper Colombia
      S.A.S. ("<strong>uTriper</strong>”, “<strong>Nosotros</strong>” o “
      <strong>nuestro</strong>”). Estos Términos y Condiciones rigen el uso de
      los contenidos, productos y Experiencias Turísticas ofrecidos a través de
      la plataforma de uTriper, alojados en, a discreción de uTriper: (i) el
      sitio web <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a> , otros dominios
      (urls), (ii) aplicativos móviles que se puedan descargar por medio de
      tiendas virtuales de aplicaciones y conjunto de sistemas (de forma
      conjunta, la “<strong>Plataforma</strong>”).
      <br />
      <br />
      Cualquier persona que desee acceder o hacer uso de la Plataforma, podrá
      hacerlo sujetándose a los presentes Términos y Condiciones, así como a
      políticas y principios incorporados al presente documento. Al acceder
      navegar y utilizar nuestra Plataforma, Usted acepta y se obliga a cumplir
      los siguientes Términos y Condiciones. Por favor, revise estos Términos y
      Condiciones de manera detallada y cuidadosa. Los servicios que ponemos a
      su disposición a través de la Plataforma, están sujetos a estos Términos y
      Condiciones. En todo caso, cualquier persona que no acepte los presentes
      Términos y Condiciones, deberá abstenerse de utilizar la Plataforma.
      <br />
      <br />
      En caso de que un usuario esté utilizando la plataforma para adquirir
      planes y servicios turísticos le aplicarán los “
      <strong>
        Términos y Condiciones de uso de uTriper para Tour Usuarios
      </strong>
      , los cuales se encuentran disponibles aquí. En ese mismo sentido, si el
      usuario utiliza la plataforma para ofrecer y anunciar planes y servicios
      turísticos le aplicaran los presentes Términos y Condiciones.
      <br />
      <br />
      Fecha de entrada en vigencia: 1 de febrero de 2022
      <br />
      <br />
      Fecha de publicación: 1 de febrero de 2022
      <br />
      <br />
      <strong>
        1. <u>Servicios de uTriper</u>
      </strong>
      <br />
      <br />
      <strong>1. Servicios.</strong> uTriper es una Plataforma la cual actúa
      como intermediaria a través de la cual el Tour Operador puede anunciar,
      publicitar, vender, promocionar y/u ofrecer (según corresponda) sus
      Experiencias Turísticas para que el visitante de la Plataforma (el “
      <strong>Tour Usuario</strong>”) lo solicite, descubra, compre, reserve,
      alquile o contrate las Experiencias Turísticas del Tour Operador (los “
      <strong>Servicios</strong>”). Una vez el Tour Usuario solicite, compre,
      reserve, o contrate las Experiencias Turísticas ofrecidas por el Tour
      Operador se habrá establecido una relación contractual directa entre
      estos. Desde ese momento, uTriper actuará exclusivamente como
      intermediario entre el Tour Operador y el Tour Usuario. En ese sentido,
      uTriper se limitará a, en representación del Tour Operador, transmitir al
      Tour Usuario mediante correo electrónico los detalles relevantes de la
      Experiencia Turística que haya sido contratada.
      <br />
      Al prestar nuestros Servicios, la información que se muestra en la
      Plataforma respecto de las Experiencias Turísticas está basada
      exclusivamente en la información que proporciona el Tour Operador. Por lo
      tanto, el Tour Operador al usar la Plataforma tiene acceso a editar los
      anuncios y ofertas de sus Experiencias Turísticas para incluir y
      actualizar los servicios, ofertas, cargos, disponibilidad, términos y
      condiciones y cualquier otro dato que considere relevante para el Tour
      Usuario. Por consiguiente, la veracidad y exactitud de la información que
      se presente en la Plataforma respecto de las Experiencias Turísticas será
      responsabilidad exclusiva del Tour Operador.
      <br />
      <br />
      <strong>
        2. <u>Registro en uTriper</u>
      </strong>
      <br />
      <br />
      <strong>2 . 1. Cuenta Tour Operador.</strong> Para hacer uso de los
      Servicios ofrecidos en la Plataforma, el Tour Operador deberá crear una
      cuenta en la Plataforma. Para crear su cuenta, Usted debe ingresar su
      dirección de correo electrónico y elegir su contraseña (la “Cuenta Tour
      Operador”). Estos datos corresponden a sus credenciales para ingresar y
      navegar a la Plataforma. Posterior a la creación de la Cuenta Tour
      Usuario, Usted recibirá un código de verificación de seguridad en el
      correo indicado, con lo cual contará con un tiempo de 30 minutos para que
      Usted pueda validar la creación de su cuenta. Esta verificación es
      necesaria para que uTriper pueda validar los datos ingresados por Usted.
      <br />
      <br />
      <strong>2 . 2.</strong> Si el Tour Operador quiere hacer uso los Servicios
      ofrecidos en la Plataforma, deberá crear un perfil asociado a la Cuenta de
      Tour Operador. Para esto, uTriper le requerirá diligenciar un formulario
      de registro que variará dependiendo si es persona natural o jurídica. De
      acuerdo con su respuesta, la Plataforma le solicitará, diligenciar un
      formulario de ingreso el cual le requerirá ciertos datos personales como:
      nombre completo o razón social, número de documento de identidad (C.C. y/o
      NIT), correo electrónico, número de teléfono celular y nombre del negocio.
      La plataforma le preguntará si los servicios que va a ofrecer corresponden
      a Servicios Turísticos o a actividades de entretenimiento. En caso de que
      Usted sea un Prestador de Servicios Turísticos conforme a la Ley 2068 de
      2020 deberá aportar adicionalmente el número de Registro Nacional de
      Turismo.
      <br />
      <br />
      <strong>2 . 3.</strong> En caso de ser necesario, uTriper se reserva la
      facultad de solicitar documentos adicionales para efectos de corroborar
      los datos suministrados, como: el certificado de existencia y
      representación legal vigente, expedido por la Cámara de Comercio y demás
      documentos que considere necesarios.
      <br />
      <br />
      <strong>2 . 4. Información de cuenta bancaria.</strong> Con el objetivo de
      que uTriper pueda realizar el encargo realizado por Usted de recibir el
      dinero correspondiente a los pagos de los Tour Usuarios de la Experiencia
      Turística, Usted deberá proporcionar la información correspondiente a la
      cuenta en la cual desea recibir los importes correspondientes por medio de
      un certificado bancario. Es su responsabilidad brindar esta información de
      manera completa y certera, así como actualizar la misma. uTriper en ningún
      caso se hará responsable del importe en los casos en los que Usted no
      pueda acceder por algún motivo a su cuenta bancaria.
      <br />
      <br />
      <strong>2 . 5. Veracidad de la información y verificación.</strong> Es
      responsabilidad del Tour Operador brindar información real, exacta, veraz
      y actualizada y por lo tanto uTriper se reserva el derecho de rechazar
      cualquier solicitud de inscripción o de cancelar una inscripción
      previamente aceptada, sin que esté obligado a comunicar o exponer las
      razones de su decisión y sin que ello genere algún derecho a indemnización
      o resarcimiento.
      <br />
      <br />
      <strong>
        3. <u>Set-Up del Tour Operador</u>
      </strong>
      <br />
      <br />
      Una vez Usted tenga la Cuenta Tour Operador activa, podrá iniciar el
      proceso de configurar su perfil. En su perfil, Usted podrá cargar las
      Experiencias Turísticas que va a ofertar y promocionar por medio de la
      Plataforma a los Tour Usuarios y los detalles y características asociados
      a estas (el “<strong>Perfil</strong>")
      <br />
      <br />
      La Plataforma le desplegará un formulario con preguntas respecto a la
      Experiencia Turística que va a ser promocionada. Dentro de la información
      solicitada se encuentra, la fecha, hora y lugar de la Experiencia
      Turística, entre otros datos. Posteriormente, encontrará un espacio
      destinado a las condiciones especiales de la Experiencia Turística. En
      este espacio, el Tour Operador deberá detallar minuciosamente las
      condiciones o requerimientos especiales que deben tener en cuenta los Tour
      Usuarios para la Experiencia Turística.
      <br />
      <br />
      Usted deberá designar a una persona que estará encargada de la gestión y
      seguimiento de las reservas de las Tour Experiencias y de resolver las
      posibles preguntas, quejas, reclamos que tengan los Tour Usuarios respecto
      a las mismas.
      <br />
      <br />
      <strong>3 . 1. Registro Nacional de Turismo.</strong> En el caso de que el
      Tour Operador sea un Prestador de Servicios Turísticos de conformidad con
      la Ley 2068 de 2020, entonces el Tour Operador deberá diligenciar el
      número correspondiente al Registro de Turismo Nacional para que este pueda
      ser visible por los Tour Usuarios en el caso de ser aplicable.
      <br />
      <br />
      <strong>
        4. <u>No-Show, Confirmación y Cancelación.</u>
      </strong>{' '}
      Usted tiene el deber de desarrollar sus propias políticas de No-Show en
      caso de que el Tour Usuario no se presente a la Tour Experiencia y de
      cancelación, en caso de que el Tour Usuario desee cancelar la Tour
      Experiencia. Así como la política de confirmación de la Experiencia
      Turística. Usted se compromete a cumplir con la legislación vigente
      aplicable para el desarrollo de estas políticas, así como hacerlas
      visibles a los Tour Usuarios por medio de su Perfil.
      <br />
      <br />
      <strong>
        5. <u>Overbooking.</u>
      </strong>{' '}
      En el caso en el que el Tour Operador presente una sobreocupación en una
      Experiencia Turística (el “<strong>Overbooking</strong>”) y como
      consecuencia uno o varios Tour Usuarios no puedan disfrutar de la
      Experiencia Turística adquirida o reservada, el Tour Operador reconoce que
      será el único responsable de dicho Overbooking y por tal deberá hacerse
      responsable por los posibles perjuicios generados a los Tour Usuarios. Lo
      anterior puede incluir pagar el transporte de regreso de los Tour
      Usuarios, entre otras medidas que sea justas y proporcionales para dicho
      restablecimiento.
      <br />
      <br />
      <strong>
        6. <u>Comisiones de uTriper.</u>
      </strong>{' '}
      A uTriper le corresponde el 10% (incluido el IVA) del precio de cada una
      de las Experiencias Turísticas que sean promocionadas y adquiridas por el
      Tour Usuario representante de las Reservas. Usted acepta que esto se
      entenderá como la comisión de pago por los Servicios que son prestados por
      parte de uTriper.
      <br />
      <br />
      <strong>
        7. <u>Precio y forma de pago de las Experiencias Turísticas.</u>
      </strong>{' '}
      Todos los precios de las Experiencias Turísticas son establecidos por el
      Tour Operador y ya cuentan con los impuestos correspondientes de ser el
      caso. Los precios asociados a entradas están establecidos por persona o
      grupo de acuerdo con cada Experiencia Turística. Sin perjuicio de lo
      anterior, uTriper podrá lanzar campañas de descuentos y/o campañas de
      fondos de promoción y sugerirlos a los Tour Operadores.
      <br />
      <br />
      <strong>
        7 . 1. <u>Formas de pago.</u>
      </strong>{' '}
      Dependiendo de la configuración establecida por el Tour Operador, las
      Experiencias Turísticas tendrán alguna de las siguientes modalidades de
      pago:
      <br />
      <br />
      <dl>
        <dd>
          <strong>(a) Pago Total:</strong> El Tour Usuario deberá realizar la
          totalidad del pago del precio de la Experiencia Turística (es decir,
          deberá cancelar el 100% del precio) por medio de la Plataforma en el
          momento de realizar la reserva.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(b) Pago Parcial:</strong> El Tour Usuario deberá pagar
          únicamente el valor correspondiente a la Reserva a través de la
          Plataforma, y, el valor correspondiente al excedente (es decir el 90%
          del precio de la Experiencia Turística) deberá ser cancelada
          directamente al Tour Operador en el momento de la Experiencias
          Turística. Cada Tour Operador contará con su propio método de pago,
          por lo que uTriper no se hará responsable por disponibilidad de
          métodos de pago ni tampoco por su funcionamiento.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            7 . 2. <u>Procesamiento de pagos.</u>
          </strong>{' '}
          uTriper facilita, por medio de procesadores de pagos externos, el pago
          correspondiente a la Reserva y/o a las Experiencias Turísticas el cual
          tendrá un costo asociado a cada transacción. uTriper recibe el dinero
          correspondiente al pago total de las Experiencias Turísticas en nombre
          del Tour Operador y por cuenta del Tour Usuario.
          <br />
          <br />
          uTriper utiliza el procesador de pago de un tercero (el «Procesador de
          Pago») para vincular su cuenta de la tarjeta de crédito a la
          Plataforma y a las Experiencias Turísticas. El procesamiento de pagos
          o créditos, según sea de aplicación, con respecto al uso que haga de
          la Plataforma y las Experiencias Turísticas estará sujeto a las
          condiciones y políticas de privacidad del Procesador del Pago y el
          emisor de su tarjeta además de a estos Términos y Condiciones. uTriper
          no será responsable de ningún error del Procesador de Pago. En
          relación con su uso de estos servicios, uTriper obtendrá determinados
          datos de la transacción, que uTriper utilizará únicamente con arreglo
          a su Privacidad y a su Aviso de Cookies.
          <br />
          <br />
          <strong>
            7 . 3. <u>Encargo de Pago Total.</u>
          </strong>{' '}
          En caso de acordar el método de Pago Total, por medio de los presentes
          Términos y Condiciones, el Tour Operador designa a uTriper para que
          este realice el encargo de recibir el importe del dinero producto del
          pago de la Experiencia Turística para el Tour Operador en caso de que
          opere el Pago Total. El Tour Operador reconoce que como requisito del
          Encargo del Pago Total de uTriper, el Tour Operador deberá radicar una
          cuenta de cobro o factura, dependiendo del caso, ante uTriper al corte
          de cada mes sobre las Experiencias Turísticas que hubiese prestado con
          Pago Total.
          <br />
          <br />
          uTriper contará con un término máximo de 30 días contados a partir del
          recibo de la factura para transferir dicho dinero a la cuenta del Tour
          Operador. uTriper transferirá el valor correspondiente al 90% de la
          Tour Experiencia ya que el Tour Operador acepta y reconoce que el 10%
          restante es propiedad de uTriper en contraprestación de los Servicios
          y que dicho valor podrá ser retenido y pagado por uTriper directamente
          de los montos recibidos.
          <br />
          <br />
          <strong>
            8. <u>Modificaciones, Suspensión y Notificaciones.</u>
          </strong>
          <br />
          <br />
          <strong>
            8 . 1. <u>Modificaciones.</u>
          </strong>{' '}
          uTriper se reserva el derecho de modificar y ajustar, sin previo
          aviso, el diseño y contenido de la Plataforma, así como algunos o
          todos los Servicios, y/o añadir servicios nuevos. Asimismo, uTriper se
          reserva el derecho de modificar y revisar los Términos y Condiciones
          en cualquier momento y sin previo aviso. Cuando se lleve a cabo una
          modificación, uTriper se publicará los Términos y Condiciones
          modificados (“Versión Nueva”) en la Plataforma para que los usuarios
          puedan leer los cambios correspondientes. Así mismo, uTriper le
          notificará al Tour Operador a través de la dirección de correo
          suministrada o mediante cualquier otro medio que sea adecuado, la
          Versión Nueva.
          <br />
          <br />
          La Versión Nueva estará vigente desde el momento de su publicación,
          pero no se aplicará en forma retroactiva. El Tour Operador tendrá la
          opción de aceptar de forma expresa la Versión Nueva en el momento en
          el que uTriper le comuniqué sobre la actualización. Sin perjuicio de
          lo anterior, el uso continuo de los Servicios y de la Plataforma por
          parte del Tour Operador después de la publicación de una Versión Nueva
          constituirá en la aceptación tácita de dicha Versión Nueva.
          <br />
          <br />
          <strong>
            8 . 2. <u>Suspensión y Terminación.</u>
          </strong>{' '}
          uTriper se reserva el derecho de suspender o cancelar su Cuenta Tour
          Operador o su acceso a cualquier Servicio, en cualquier momento y por
          cualquier motivo. Podemos agregar o eliminar, suspender, borrar,
          discontinuar o establecer condiciones para los Servicios o cualquier
          característica o aspecto de un Servicio. Tomaremos medidas razonables
          para notificarle respecto a la cancelación u otros tipos de cambios
          por correo electrónico o la próxima vez que intente acceder a su
          Cuenta Tour Operador. Podemos suspender de manera permanente o
          temporal el acceso a los Servicios o el acceso a la Plataforma sin
          previo aviso ni responsabilidad por cualquier motivo, o sin ningún
          motivo. En casos de terminación, no será exigible ninguna cláusula
          penal ni el reconocimiento de suma alguna por cualquier concepto por
          parte de uTriper. El Tour Operador será responsable de las
          obligaciones ya contraídas con los Tour Usuarios sobre los cuales no
          se hayan realizado reembolsos.
          <br />
          <br />
          uTriper podrá hacer uso de diversos mecanismos para salvaguardar los
          derechos de los Tour Usuarios que acceden a la Plataforma. Uno de los
          cuales corresponde a la verificación de la entrega y estado de las
          Experiencias y servicios a los Tour Usuarios. En caso de que uTriper
          encuentre motivos razonables para creer que el Tour Operador no ha
          cumplido con los Tour Usuarios uTriper podrá eliminar de manera
          inmediata la Cuenta del Tour Operador.
          <br />
          <br />
          <strong>
            8 . 3. <u>Consentimiento Electrónico.</u>
          </strong>{' '}
          Usted acepta que uTriper puede enviar declaraciones y avisos según lo
          exija la ley y publicar otra información sobre su Cuenta Tour Usuario
          de forma electrónica mediante la publicación en nuestra Plataforma, el
          envío de notificaciones push a través de los Servicios, o por correo
          electrónico a la dirección registrada en su Cuenta Tour Usuario. Usted
          es responsable de administrar su cuenta de correo electrónico y de
          suministrar a uTriper su cuenta de correo electrónico más actualizada.
          La información y los avisos electrónicos tienen el mismo significado y
          efecto que si se le hubieran entregado en copias impresas en papel.
          Para efectos de notificación, se considera que Usted ha recibido esta
          información y avisos dentro de las 24 (veinticuatro) horas desde la
          hora publicada en nuestra la Plataforma, o dentro de las 24
          (veinticuatro) horas desde la hora en que le fueron enviados por
          correo electrónico. En el caso de que la última dirección de correo
          electrónico que nos haya proporcionado no sea válida, o por cualquier
          motivo no pueda enviarle la notificación descrita anteriormente,
          nuestro envío del correo electrónico que contiene dicha notificación
          constituirá, no obstante, una notificación efectiva de los cambios
          descritos en la misma.
          <br />
          <br />
          <strong>
            9. <u>Responsabilidades del Tour Operador.</u>
          </strong>{' '}
          Usted, como usuario de la Plataforma asume las siguientes
          responsabilidades:
          <br />
          <br />
          <strong>9 . 1.</strong> Usted es único responsable de que su Cuenta
          Tour Operador se mantenga confidencial, esto incluye la información de
          ingreso a la Plataforma y de restringir el acceso a los Servicios
          desde sus dispositivos compatibles. Por ende, Usted es exclusivamente
          responsable de las actividades que ocurran bajo su Cuenta Tour
          Operador. Usted acuerda notificar inmediatamente a uTriper acerca de
          cualquier hecho que permita suponer el uso indebido de la información
          registrada en la Plataforma, como, por ejemplo, robo, extravío, o
          acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder
          a su inmediata cancelación. Usted de manera inmediata tomará todas las
          medidas razonables para mitigar los efectos de una vulneración de
          seguridad y cooperará con uTriper y proporcionará toda la información
          solicitada por uTriper para remediar la vulneración. A pesar de lo
          anterior, uTriper no será responsable en ningún caso por cualquier
          pérdida o daño derivado de su falta de cumplimiento con los requisitos
          establecidos anteriormente.
          <br />
          <br />
          <strong>9 . 2.</strong> Es responsabilidad del Tour Operador utilizar
          la Plataforma de acuerdo con la forma en la que fue diseñada; en este
          sentido, queda prohibida la utilización de cualquier tipo de software
          que automatice la interacción o descarga de los contenidos o servicios
          proporcionados a través de la Plataforma.
          <br />
          <br />
          El Tour Operador se compromete a utilizar la información, contenidos o
          servicios ofrecidos a través de la Plataforma de manera lícita, sin
          contravenir lo dispuesto en los presentes Términos y Condiciones, la
          moral y/o el orden público, y se abstendrá de realizar cualquier acto
          que pueda suponer una afectación a los derechos de terceros, o
          perjudique de algún modo el funcionamiento de la Plataforma.
          <br />
          <br />
          <strong>9 . 3.</strong> En los casos en los en que la Cuenta Tour
          Operador corresponda a una compañía, esta deberá estar debidamente
          constituida y el encargado de la Cuenta Tour Operador deberá y declara
          tener las facultades para representar, contratar y obligar al Tour
          Operador en los términos de este contrato. Así mismo, el Tour Operador
          declara que cuenta con las autorizaciones requeridas para obligarse
          conforme a estos Términos y Condiciones. Ninguna autorización de
          autoridad diferente a un órgano societario es requerida para la
          ejecución y cumplimiento de este documento.
          <br />
          <br />
          <strong>
            9 . 4. <u>Deberes generales.</u>
          </strong>{' '}
          El Tour Operador se compromete a cumplir con los siguientes deberes
          generales:
          <br />
          <br />
        </dd>
        <dd>
          (a) No utilizará una cuenta que esté sujeta a cualquier derecho de una
          persona que no sea la propia sin la autorización adecuada. Así mismo,
          no perjudicará el funcionamiento adecuado de la red, ni tratará de
          dañar la Plataforma de ningún modo.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (b) No copiará ni distribuirá la Plataforma ni ningún contenido sin el
          permiso escrito.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (c) Cumplirá con toda la legislación aplicable de su país de
          residencia y del país, estado o ciudad en el que se encuentre al usar
          la Plataforma o el Servicio.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>(d) Cumplirá con todas las obligaciones de estos Términos.</dd>
        <dd>
          <br />
          <br />
          <strong>9 . 5. Deberes específicos del Tour Operador.</strong>
          <br />
          <br />
        </dd>
        <dd>
          (a) Contar con la inscripción activa y vigente en el Registro Nacional
          de Turismo de ser el caso.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (b) Incluir y actualizar diariamente la información correspondiente
          sobre los servicios, ofertas, cargos, disponibilidad, términos y
          condiciones, así como las políticas de confirmación y cancelación.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (c) Al anunciar una Experiencia Turística, debe informar al Tour
          Usuario: cualquier riesgo inherente o incidental a la Experiencia
          Turística, requisitos para poder participar como edad mínima,
          condiciones de salud, capacidades cognitivas etc., cualquier otro
          elemento que el Tour Usuario deba conocer para participar de forma
          segura en la Experiencia Turística como: vestimenta, equipo,
          suministro, vehículo (el “Equipo”), permisos o certificaciones.
          uTriper se reserva la discrecionalidad de decidir si una Experiencia
          Turística puede publicarse en su Plataforma. De no informar al Tour
          Usuario que debe aportar Equipo para la Tour Experiencia, Usted es
          responsable de aportarle el Equipo necesario. Asimismo, será el
          responsable de garantizar que el Equipo esté en buenas condiciones y
          se ajuste a todas las leyes relativas a seguridad, equipo, inspección
          y capacidad operativa aplicable.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (d) La Experiencia Turística debe ser prestada exclusivamente por el
          Tour Operador que la ofrece, Usted no puede permitir que ningún
          tercero no afiliado su nombre, en caso de ser persona jurídica, la
          preste por Usted.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (e) Suministrar información veraz, clara y suficiente en relación con
          las Experiencias Turísticas.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (f) Garantizar el cumplimiento y ejecución de las Experiencias
          Turísticas.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (g) Usted debe asegurarse de que la Experiencia Turística y cumpla
          todas las leyes, normas y regulaciones que puedan aplicarse.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (h) Usted debe asegurarse de obtener todas las licencias, permisos o
          registros requeridos antes de ofrecer su Experiencia Turística.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (i) Mantener actualizado el listado de precios de las Experiencias
          Turísticas que ofrece a través de la Plataforma, así mismo declara que
          el valor publicado en la Plataforma es el correcto e igual a los
          precios ofertados por otros canales diferentes a la Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (j) Pagar a uTriper el valor acordado por la prestación de los
          Servicios de la Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (k) El Tour Operador se obliga a promocionar y comercializar única y
          exclusivamente Experiencias de carácter lícito y que cumplan con la
          normatividad vigente. El Tour Operador que infrinja esta obligación,
          será expulsados inmediatamente de la Plataforma, su cuenta será
          cancelada, el saldo que tenga uTriper de Experiencias Turísticas será
          devuelto, con las deducciones de cualquier saldo adeudado y
          Experiencias pendientes, y transferido a la cuenta bancaria informada
          y uTriper avisará a las autoridades competentes e iniciará las
          acciones legales pertinentes.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (l) Dado que la Plataforma es un punto de encuentro entre el Tour
          Operador y el Tour Usuario, uTriper no participa de las operaciones
          que se realizan entre ellos, el Tour Operador será responsable por
          todas las obligaciones y cargas impositivas que correspondan por la
          venta de sus Experiencias, sin que pudiera imputársele a uTriper algún
          tipo de responsabilidad por incumplimientos en tal sentido.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            10. <u>No Show.</u>
          </strong>{' '}
          Una vez el Tour Usuario reserve con Usted, este acepta las condiciones
          de cancelación y no show (en caso de no presentación) establecidas por
          Usted, así como los demás términos y condiciones adicionales
          establecidos por Usted que puedan afectar la Experiencia Turística del
          Tour Usuario. Usted tiene el deber de informar sobre dichos términos y
          condiciones especiales de la Experiencia Turística al Tour Usuario.
          Por ende, estos deben encontrarse a disposición del Tour Usuario en el
          perfil de las Experiencias Turísticas dentro de la Plataforma, en el
          proceso de Reserva y en el correo de confirmación enviado por uTriper
          al realizar la Reserva, el cual se basará en los términos y
          condiciones que Usted establezca. En ningún caso el precio pagado por
          la Reserva será reembolsable por el No Show.
          <br />
          <br />
          <strong>
            11. <u>Cancelaciones del Tour Operador.</u>
          </strong>{' '}
          Las condiciones de cancelación y pago son establecidas por cada Tour
          Operador y por lo tanto varían dependiendo de este. Sin perjuicio de
          lo anterior, en el caso de que un Tour Operador cancele una
          Experiencia Turística en la que ya se hubiese generado un Pago Total,
          el Tour Operador reconoce que no podrá solicitar a uTriper el dinero
          asociado a dicha Experiencia Turística.
          <br />
          <br />
          <strong>12.</strong> uTriper podrá cobrar al Tour Operador los gastos
          asociados a los perjuicios razonables que dicha cancelación pudiese
          ocasionar a los Tour Usuarios que hubiesen realizado un Pago Total.
          <br />
          <br />
          <strong>
            13. <u>Restricciones del Tour Operador</u>
          </strong>
          <br />
          <br />
          Los derechos otorgados al Tour Operador en los presentes Términos y
          Condiciones para el uso de la Plataforma están sujetos a ciertas
          restricciones. El derecho de uso que se concede con la contratación de
          los Servicios es intransferible, revocable, no exclusivo y limitado
          para el uso por parte del Tour Operador para los fines definidos por
          la naturaleza de los Servicios y no para su explotación o
          comercialización por parte del Tour Operador. Ningún Tour Operador
          puede ni debe permitirle a un tercero a realizar ninguna de las
          siguientes acciones, ya sea de forma directa o indirecta:
          <br />
          <br />
        </dd>
        <dd>
          (a) acceder o supervisar cualquier material o información en algún
          sistema de uTriper usando cualquier proceso manual o medios como
          robot, spider, scraper, integraciones, APIs disponibles o cualquier
          otro medio automatizado;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (b) violar las restricciones de cualquiera de los encabezados de
          exclusión de robots de los Servicios, utilizar vías alternativas,
          omisiones o evasiones de cualquiera de las limitaciones técnicas de
          los Servicios, usar cualquier herramienta para activar funciones o
          características que se encuentren desactivadas en los Servicios, o
          descompilar, desensamblar o aplicar técnicas de ingeniería inversa a
          los Servicios, descompilar o valerse de cualquier otro medio para
          tratar de descubrir el código fuente, código objeto de la estructura
          de la Plataforma, excepto en tanto esta restricción esté prohibida
          expresamente por la legislación pertinente;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (c) realizar o intentar realizar acciones que interfieran con el
          funcionamiento adecuado de los Servicios, impidan el acceso o el uso
          de los Servicios por parte de otros Tour Operadores o agreguen una
          carga no razonable o desproporcionadamente elevada a nuestra
          infraestructura;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (d) Obtener o intentar obtener acceso a cualquier información
          confidencial de uTriper o de otros Tour Operadores a través de
          cualquier medio. El uso de bots, códigos de automatización y/o malware
          que tenga como fin el robo de información confidencial;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (e) utilizar la Plataforma para ejercer acciones de competencia
          desleal;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (f) copiar, reproducir, alterar, modificar, crear trabajos derivados,
          exhibir públicamente, reeditar, cargar, publicar, transmitir, revender
          o distribuir de alguna manera material, información o Servicios de
          uTriper;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (g) transferir los derechos que a usted se le otorguen en virtud de
          estos Términos y Condiciones;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (h) utilizar los Servicios para cualquier actividad ilegal tanto
          nacionales como Internacionales de cualquier manera que le exponga a
          algún perjuicio a usted, a otros Tour Operadores de uTriper a nuestros
          socios o a uTriper o
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (i) utilizar los Servicios de otro modo excepto como se haya permitido
          expresamente conforme a estos Términos y Condiciones.
        </dd>
        <dd>
          <br />
          <br />
          En el caso en el que uTriper sospeche razonablemente que su Cuenta de
          uTriper se ha utilizado para un fin no autorizado, ilícito o
          delictivo, contamos con su autorización expresa para compartir
          información acerca de usted, de su Cuenta de uTriper y de cualquiera
          de sus transacciones con las autoridades competentes. Adicionalmente,
          en caso de considerarlo necesario, uTriper podrá remover el contenido
          violatorio del Servicio y ejercer las acciones legales
          correspondientes en contra del Tour Operadores.
          <br />
          <br />
          uTriper se reserva el derecho a terminar inmediatamente el servicio y
          el uso de la Plataforma en caso de que incumpla alguna de las reglas y
          obligaciones anteriores.
          <br />
          <br />
          <strong>
            14. <u>Derecho de Retracto</u>
          </strong>
          <br />
          <br />
          <strong>14 . 1. Derecho de Retracto del Tour Operador.</strong> De
          conformidad con las normas vigentes, respecto de los servicios
          prestados por uTriper a los Tour Operadores no aplicará el derecho de
          retracto en el entendido que el Servicio comienza con el acuerdo y
          únicamente se paga posterior a su uso.
          <br />
          <br />
          <strong>14 . 2. Derecho de Retracto del Cliente.</strong> El Tour
          Operador se obliga a cumplir e incluir en su Experiencia Turística los
          términos para el ejercicio del derecho de retracto a favor de los
          Clientes aplicable a sus servicios que ofrezca a través de la
          Plataforma. Asimismo, el Tour Operadores se obliga a que las
          condiciones que regulen el derecho de retracto del cliente cumplan con
          las obligaciones establecidas en la ley.
          <br />
          <br />
          <strong>
            15. <u>Preguntas, Quejas y Reclamos</u>
          </strong>
          <br />
          <br />
          uTriper sitúa en su Plataforma un botón de PQRS para que, mediante
          este, los Tour Usuarios puedan tramitar sus preguntas, sugerencias,
          quejas y/o reclamos (la “Consulta”). Lo anterior, permite a los Tour
          Usuarios ejercer cualquiera de sus derechos como consumidores a los
          Tour Operadores.
          <br />
          <br />
          El botón de PQRS opera únicamente como un canal de mensajería
          electrónica, lo cual implica que el Tour Usuario realizará su Consulta
          a través del botón, y, uTriper le remitirá dicha Consulta al Tour
          Operador correspondiente. El Tour Operador reconoce y acepta que es su
          responsabilidad el dar el trámite adecuado y diligente y dentro de los
          términos adecuados de acuerdo con las normativas de protección al
          consumidor a las Consultas que reciba por este medio.
          <br />
          <br />
          En los casos en los que el Tour Operador no brinde una respuesta
          oportuna a la Consulta, o que, habiendo dado respuesta, esta no sea
          satisfactoria para el Tour Usuario o que uTriper, a su entera
          discreción, considere que se han violado las obligaciones de estos
          Términos y Condiciones o de las normas de consumidor, entonces,
          uTriper se reserva el derecho a intervenir en dicha Consulta. En
          dichos casos, uTriper tendrá la facultad de repetir contra el Tour
          Operador por los gastos incurridos relacionados con la solución de la
          Consulta.
          <br />
          <br />
          <strong>
            16. <u>Acceso a la Plataforma.</u>
          </strong>{' '}
          El contenido de la Plataforma es para su información y uso general;
          este contenido está sujeto a cambios sin previo aviso. uTriper ha
          hecho esfuerzos razonables para incluir información exacta y al día en
          la Plataforma, sin embargo, uTriper no puede garantizar la
          disponibilidad de la misma. Por lo tanto, uTriper no garantiza el
          acceso a la Plataforma o al contenido, de tal forma no será
          responsable de que esta se encuentre libre de errores, software
          malicioso, o que pueda causar algún daño a nivel de software o
          hardware en el equipo a través del cual el Tour Usuario accede a la
          Plataforma. Asimismo, uTriper se reserva el derecho de realizar
          mantenimientos a la Plataforma con o sin previo aviso cuando debido a
          la materialidad del daño o de la urgencia, así se requiera.
          <br />
          <br />
          <strong>
            17. <u>Propiedad intelectual y Derechos de Autor.</u>
          </strong>{' '}
          Usted reconoce que todos los derechos derivados de la propiedad
          intelectual que se encuentren contenidos en la Plataforma pertenecen
          de manera exclusiva a uTriper y/o cualquiera de sus aliados y por lo
          tanto no serán usados sin previo consentimiento escrito. El uso de los
          contenidos de la Plataforma por fuera de los presentes Términos y
          Condiciones está totalmente prohibido. El uso de los contenidos sin
          previa autorización de uTriper o de la persona propietaria del
          contenido podría violar los derechos de autor y/o de propiedad
          industrial, al igual que los derechos de habeas data y de privacidad
          de la información que están amparados por las leyes, regulaciones y
          estatutos nacionales e internacionales
          <br />
          <br />
          El Tour Operador autoriza expresamente a UTriper para publicar en el
          sitio web y aplicativos móviles fotografías, marcas, logos y demás
          signos distintivos con los que pretenda darse a conocer. En todo caso,
          el Tour Operador se obliga a respetar la normatividad vigente
          relacionada con propiedad intelectual y derechos de autor, para ello
          garantiza contar con las licencias y autorizaciones en esta materia,
          que le permiten ofrecer las Experiencias.
          <br />
          <br />
          El Tour Operador autoriza a UTriper a utilizar, publicar, reproducir
          y/o adaptar las imágenes y fotografías incluidas en sus publicaciones,
          su nombre comercial, marcas, frases publicitarias, logos, diseños,
          dibujos, imágenes y todo otro signo distintivo que identifique al Tour
          Operador y sus productos o servicios (la "Marca") e información de sus
          productos o servicios ("Información de las Experiencias"). El Tour
          Operador declara y garantiza que es titular o licenciatario de los
          derechos necesarios sobre las imágenes, fotografías contenidas en sus
          publicaciones, sobre las Marcas, así como sobre la Información de las
          Experiencias, y que cuenta con los derechos y facultades necesarias
          para conceder la autorización detallada en esta cláusula, siendo
          responsable exclusivo por cualquier infracción a derechos de terceros
          o por las inconsistencias o inexactitud en la Información de las
          Experiencias.
          <br />
          <br />
          <strong>
            18. <u>Política de Privacidad y Protección de Datos</u>
          </strong>
          <br />
          <br />
          <strong>18 . 1.</strong> uTriper ha implementado medidas técnicas y
          organizativas que tienen como objetivo proteger su información contra
          destrucciones, pérdidas accidentales, alteraciones y contra el acceso,
          uso, modificación o divulgación no autorizados. Sin embargo, uTriper
          no puede garantizar que terceros no autorizados no puedan sobrepasar
          esas medidas ni usar su información con fines indebidos. El Tour
          Operador reconoce que brinda su información bajo su propio riesgo y
          así mismo se compromete a adoptar las medidas necesarias que estén a
          su alcance para asegurar la privacidad de los datos personales
          recabados de forma que se garantice su seguridad, se evite su
          alteración, pérdida o tratamiento no autorizado.
          <br />
          <br />
          <strong>18 . 2. Privacidad.</strong> Los Tour Operadores deberán
          acogerse a la Política de Tratamiento de Datos Personales. Al utilizar
          cualquiera de nuestros Servicios, Usted acepta que nuestras prácticas
          de datos personales le apliquen a Usted de la manera en la que se
          establece en la Política de Privacidad (“Política de Privacidad”) la
          cual se encuentra disponible aquí. La Política de Privacidad explica
          cómo uTriper recopila, usa y protege la información personal que usted
          nos proporciona cuando uTriper hace uso de sus datos personales para
          proporcionarle los Servicios o para sus propios fines. Se le
          recomienda a los Tour Operadores que se familiaricen con la Política
          de Privacidad antes de usar la Plataforma y adquirir los Servicios.
          <br />
          <br />
          <strong>18 . 3.</strong> UTriper se reserva el derecho a modificar su
          Política de Privacidad, de acuerdo con sus necesidades o de acuerdo
          con cada legislación aplicable. El acceso o utilización de la
          Plataforma por parte del Tour Operador después de dichos cambios,
          implicará la aceptación de estos cambios.
          <br />
          <br />
          <strong>18 . 4.</strong> El Tour Operador reconoce y acepta su rol
          como Encargado de los datos compartidos por uTriper, y por lo tal
          acepta dar el tratamiento a los datos de los Tour Usuarios compartidos
          por uTriper de acuerdo con la normativa, la ley, los principios
          aplicables y lo dispuesto en la Política de Privacidad. Así mismo, el
          Tour Operador deberá acatar las siguientes obligaciones:
          <br />
          <br />
        </dd>
        <dd>
          (a) Cumplir con los principios aplicables al tratamiento de datos
          personales, en especial, seguridad, confidencialidad, circulación
          restringida, finalidad, deber de información, legalidad, entre otros.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (b) Adoptar las políticas y/o normas que consignen las directrices a
          sus empleados sobre el uso de la información personal que tratará como
          encargado. Asimismo, deberá capacitar a sus empleados y colaboradores.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (c) Proteger y mantener la confidencialidad de la información a la que
          acceda a través de la Plataforma.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            19. <u>Enlaces de terceros y cookies</u>
            <br />
            <br />
            19 . 1. Enlaces de terceros.
          </strong>{' '}
          La Plataforma podrá incluir enlaces que permitan acceder a páginas web
          de terceros distintos de uTriper. La utilización de estos enlaces
          tiene como objetivo el informar al usuario sobre la existencia de
          otras fuentes en las cuales se pueden ampliar los contenidos
          ofrecidos, o que guardan relación con estos, sin que lo anterior se
          considere como una sugerencia, recomendación o invitación para hacer
          uso de sitios web externos, ni tampoco implica necesariamente la
          existencia de relaciones entre uTriper y el propietario del enlace
          vinculado, ni la aceptación o aprobación por parte de uTriper de sus
          contenidos o servicios. Por lo tanto, uTriper no garantiza ni se
          responsabiliza por el funcionamiento o accesibilidad a los enlaces
          vinculados, así como tampoco del contenido en ellos publicado. uTriper
          en ningún caso revisará o controlará el contenido de los sitios web
          externos. Los titulares de dichos enlaces dispondrán de sus propias
          políticas de privacidad y protección de datos, por lo cual uTriper no
          asume ningún tipo de responsabilidad por los datos que sean
          facilitados por el usuario a través de cualquier sitio web distinto a
          la Plataforma.
          <br />
          <br />
          <strong>19 . 2. Cookies</strong> La Plataforma utiliza cookies para
          mejorar la experiencia, se recolecta información acerca de la manera
          en cómo el usuario interactúa con el sitio web de la Plataforma y
          recordarlo. En caso de que se rechace la política de cookies, no se
          realizará seguimiento a la información cuando visite esta página.
          <br />
          <br />
          <strong>
            20. <u>Indemnidad.</u>
          </strong>
          <br />
          <br />
          El Tour Operador, exonera de toda responsabilidad a uTriper y se
          obliga a mantener indemne a uTriper y a nuestros respectivos
          empelados, directores, agentes, afiliados y representantes de
          cualquier reclamo, costo, pérdida, daño, juicio, evaluación de
          impuestos, multa, interés; perjuicio, daño, pasivo y/o contingencia
          (incluidos sin limitación honorarios razonables de abogados) que
          surjan de cualquier reclamo, acción, auditoría, investigación,
          indagación o en relación con los mismos, u otro procedimiento iniciado
          por cualquier persona o entidad que surja o se relacione con: (a)
          cualquier incumplimiento real o supuesto de sus declaraciones,
          garantías u obligaciones reconocidos en los presentes Términos y
          Condiciones; (b) el uso indebido o inapropiado de los Servicios; (c)
          la violación de cualquier derecho de terceros, inclusive cualquier
          derecho de privacidad, de publicidad o de propiedad intelectual; (d)
          la violación de cualquier ley, norma o reglamento en cualquier otro
          país; (e) el acceso y/o uso de los Servicios con su nombre único,
          contraseña u otro código de seguridad adecuado por otras partes; (f)
          cualquier transacción, compra, producto o servicio con respecto al
          cual el Tour Operador haya proporcionado a través de la Plataforma, o
          le haya proporcionado, servicios de procesamiento de pagos de
          conformidad con los Términos y Condiciones; (h) el incumplimiento de
          cualquier disposición conforme a la protección del consumidor; y (i)
          cualquier disputa derivada de las complicaciones en la prestación de
          las Experiencias Turísticas.
          <br />
          <br />
          El Tour Operador renuncia expresamente a presentar cualquier
          reclamación, demanda o acción judicial y/o administrativa en contra de
          uTriper, sus administradores, representantes y accionistas, pues
          entiende y acepta que la labor de uTriper es operar como un portal de
          contacto, al poner a disposición la Plataforma para que los Tour
          Operadores puedan ofrecer sus productos y/o servicios para su
          comercialización y a su vez los Tour Usuarios puedan contactarlos por
          medio de la misma Plataforma. Por consiguiente, uTriper no será
          responsable por ningún daño o perjuicio que pueda sufrir por causa o
          con ocasión de la compra y venta de las Experiencias Turísticas a
          través de la Plataforma.
          <br />
          <br />
          <strong>
            21. <u>Limitación de responsabilidad de uTriper.</u>
          </strong>{' '}
          uTriper opera como un portal digital el cual habilita el espacio para
          la comunicación entre el Tour Operador y el Tour Usuario, con el
          objetivo de que estos celebren un contrato de compraventa o cualquier
          otro tipo de contrato lícito, con el fin de adquirir Experiencias
          Turísticas. Por consiguiente, y, bajo este entendimiento, uTriper no
          es el prestador de ningún servicio ofrecido a los Tour Usuarios, ni
          tampoco tiene el dominio, la disposición ni la posesión de estos.
          uTriper no es ni será responsable por el efectivo cumplimiento de
          parte del Tour Operador de las condiciones y características de las
          Experiencias Turísticas ofertadas en la Plataforma. El Tour Usuario
          reconoce y acepta, entonces, que las operaciones que realice con el
          Tour Operador y terceros, será bajo su propio riesgo.
          <br />
          <br />
          <strong>
            22. <u>Cláusula de Arbitramiento.</u>
          </strong>{' '}
          Las diferencias que surjan con relación al objeto de los Términos y
          Condiciones y a los Servicios prestados por parte de uTriper, su
          ejecución, interpretación, vigencia, obligaciones de las partes,
          valor, liquidación o terminación, así como cualquier reclamación,
          controversia o disputa entre usted y uTriper sus procesadores,
          proveedores o licenciantes (o sus respectivos afiliados, agentes,
          directores o empleados), ya sea que surja antes o después del período
          de vigencia de estos Términos y Condiciones, serán sometidas a la
          presente cláusula.
          <br />
          <br />
          Antes de que se inicie un arbitraje, Usted y uTriper se comprometen a
          intentar evitar los costos de la resolución formal de disputas
          concediéndose mutuamente una oportunidad plena y justa para abordar y
          resolver la disputa informalmente y de manera directa. Ambas partes
          reconocen que este es un requisito fundamental e indispensable para
          proceder con el arbitramento, y que la vulneración de este requisito
          implicaría la vulneración material de los Términos y Condiciones.
          <br />
          <br />
          Las controversias que no puedan ser resueltas de forma directa entre
          las partes, se resolverán por un Tribunal de Arbitramento presentado
          ante el Centro de Arbitraje y Conciliación de la Cámara de Comercio de
          Bogotá, el cual estará sujeto a sus reglamentos y al procedimiento
          allí contemplado, de acuerdo con las siguientes reglas:
          <br />
          <br />
        </dd>
        <dd>
          (a) El Tribunal estará integrado por un (1) arbitro designado por las
          partes de común acuerdo. En caso de que no fuere posible, los árbitros
          serán designados por el Centro de Arbitraje y Conciliación de la
          Cámara de Comercio, a solicitud de cualquiera de las partes.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>(b) El tribunal decidirá en derecho.</dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          (c) El Tribunal sesionará en las instalaciones del Centro de Arbitraje
          y Conciliación de la Cámara de Comercio de Bogotá.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
      </dl>
    </div>
  );
};

export const PersonalDataAuthorizationSection = () => {
  return (
    <div>
      <center>
        <h3>
          <strong>AUTORIZACIÓN DE DATOS PERSONALES</strong>
        </h3>
      </center>
      <br />
      Al hacer click en [ACEPTO] y registrar sus datos personales en este
      portal, usted manifiesta de manera expresa e inequívoca que es el legítimo
      Titular de la información proporcionada y que la misma es veraz, completa,
      exacta, actualizada y verificable.
      <br />
      <br />
      Del mismo modo, declara de manera libre, expresa, inequívoca e informada,
      que <strong>AUTORIZA</strong> a Global Triper Colombia S.A.S. (en adelante
      “<strong>uTriper</strong>”), sociedad legalmente constituida bajo las
      leyes de la República de Colombia, con domicilio en la Cl 86 A # 22 A 12,
      en la ciudad de Bogotá D.C., Colombia, identificada con el NIT 901.471.044
      - 2 ; para que, en los términos de la Ley 1581 de 2012 y su Decreto
      Reglamentario 1377 de 2013, realice la recolección, almacenamiento,
      depuración, uso, circulación, actualización, supresión, cruce con
      información propia y/o de terceros autorizados y en general, el
      tratamiento de sus datos personales y de contacto, para que dicho
      tratamiento se realice con el propósito de lograr las siguientes
      finalidades:
      <br />
      <br />
      <em>Finalidades Generales:</em>
      <br />
      <br />
      <dl>
        <dd>
          <strong>(a)</strong> Para el envío de información a sus empleados,
          proveedores, terceros y usuarios de su Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b)</strong> Ejecutar la relación contractual existente con
          sus empleados, proveedores, terceros y usuarios de su Plataforma,
          incluida el pago de obligaciones contractuales;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c)</strong> Registrar la información de sus empleados,
          proveedores, terceros y usuarios de su Plataforma y el registro de
          base de datos de cada uno.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(d)</strong> Los indicados en la autorización otorgada por el
          Titular del dato o descritos en el aviso de privacidad respectivo,
          según sea el caso;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(e)</strong> Envío de información a entidades gubernamentales
          o judiciales por solicitud expresa de la misma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(f)</strong> Soporte en procesos de auditoría externa/interna.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(g)</strong> Con propósitos de seguridad o prevención de
          fraude.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(h)</strong> Gestionar trámites (solicitudes, quejas,
          reclamos).
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(i)</strong> Incluir en sus Bases de Datos y darle Tratamiento
          a la información adquirida en virtud de la relación existente entre
          los Titulares y uTriper, cualquiera que sea su naturaleza jurídica
          (laboral, civil, comercial), así como a la información suministrada
          por sus accionistas, vinculadas y/o relacionadas.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(j)</strong> Transferir o transmitir los datos de los
          Titulares en caso de fusión, consolidación, cambio en el control
          societario, transferencia de activos, reorganización o liquidación de{' '}
          <strong>uTriper</strong> y/o sus entidades Vinculadas y/o
          Relacionadas, <strong>uTriper</strong> podrá transferir la Información
          Personal de los Titulares a las partes involucradas
        </dd>
        <dd>
          <br />
          <br />
          <em>Finalidades para Usuarios:</em>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(a)</strong> Facilitar el contacto entre el Tour Operador y el
          Tour Usuario que desee adquirir servicios. uTriper podrá suministrar a
          los interesados sus datos personales (nombre, teléfonos, correo
          electrónico, certificado de existencia y representación legal,
          documento de identidad, dirección y RUT), a través de correo
          electrónico y/o aplicativos móviles y/o página web. Dicha información
          sólo podrá ser utilizadas para fines de concluir con éxito la
          prestación de los servicios adquiridos, para efectos tributarios y de
          facturación.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b)</strong> Mejorar los servicios de uTriper a través del
          desarrollo de estudios de mercado sobre el comportamiento,
          necesidades, gustos e intereses de los Usuarios sobre la plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c)</strong> Realizar estudios poblacionales, geográficos,
          demográficos, de comportamiento, hábitos de compra, gustos y
          preferencias de los Usuarios, que permitan desarrollar campañas
          publicitarias, de mercado y análisis estadísticos. Dicha información
          podrá ser comercializada a terceros sin que con ello se incluyan Datos
          Personales ni Datos Sensibles.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(d)</strong> Analizar las páginas visitadas y las búsquedas
          realizadas por los Usuarios, con el objetivo de mejorar la oferta de
          contenidos y artículos y para personalizar los contenidos, su
          presentación y servicios.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(e)</strong> Mejorar de las iniciativas comerciales y
          promocionales de uTriper.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(f)</strong> Realizar gestión de estadísticas internas, con el
          objetivo de hacer seguimiento a los servicios ofrecidos.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(g)</strong> Enviar información y/o comunicaciones por
          cualquier medio que hubiese sido brindado por el Titular a uTriper
          (mensajes de texto al teléfono celular, redes sociales, correo
          electrónico entre otros) sobre nuevos servicios, cambio en el servicio
          y tarifas, recordatorios de pago, promociones, eventos e información
          de interés para los Usuarios.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(h)</strong> Compartir los Datos Personales con proveedores de
          medios de pago, servicios turísticos, seguros o intermediarios en la
          gestión de pagos, call-centers o programas de fidelidad, entre otros,
          y en general terceros para el cumplimiento de las obligaciones de
          uTriper o de los Usuarios y de los servicios ofrecidos por o a través
          de la Plataforma. uTriper no se hace responsable por el uso indebido
          que estas personas o entidades puedan darle a la información.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(i)</strong> Transferencia o Transmisión de Datos Personales
          nacional o internacionalmente a terceros, como por ejemplo prestadores
          de servicios turísticos, con los que uTriper desarrolle actividades en
          cumplimiento de su objeto social. Asimismo, se podrá hacer Transmisión
          o Transferencia a los aliados estratégicos de la empresa para que
          ejecuten actividades de marketing, publicidad y promociones asociadas
          con el objeto social; todo de acuerdo con las disposiciones de la
          normativa colombiana
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(j)</strong> Suministrar los Datos Personales de los Usuarios
          a las entidades que intervengan en la resolución de disputas entre los
          mismos, tales como: Compañías de Seguros, Amigables Componedores o
          Tribunales de Arbitraje o tribunales competentes para solucionar tales
          disputas, entidades del Estado.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(k)</strong> Mejorar la experiencia de Usuario al momento de
          efectuar transacciones a través de la Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(l)</strong> Prestar los Servicios ofrecidos por uTriper de
          una manera adecuada.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(m)</strong> Darle Tratamiento a la información en medios
          físicos y digitales, asegurando que estén correctamente utilizados por
          parte de uTriper.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(n)</strong> Contactar y/o enviar información, por cualquier
          medio, sobre servicios que uTriper considere pueden ser de interés por
          parte del Titular. Así como envío de cualquier otro tipo de
          comunicaciones y actividades relacionadas con el objeto social de
          uTriper.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(o)</strong> Facilitar el contacto entre el Tour Operador y el
          Tour Usuario que desee adquirir los servicios ofertados por el Tour
          Operador. uTriper, como portal de contacto, tiene la obligación de
          recopilar la información que permita la plena identificación del Tour
          Operador (nombre, razón social, documento de identificación, dirección
          física de notificación, teléfonos, entre otros).
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(p)</strong> Entregar información del Tour Usuario al Tour
          Operador, para el cumplimiento de la relación contractual que entre
          estos se convenga. En cualquier caso, el Tour Operador será el
          Encargado de la información que le entregue uTriper y por consiguiente
          y responderá por las finalidades de uso que el Tour Usuario les
          otorgue.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(q)</strong> La información suministrada por el Usuario será
          utilizada por uTriper para efectos de facturación (en caso de aplicar)
          y demás efectos tributarios, en este caso será compartida con las
          entidades del Estado encargadas de desarrollar dicha labor.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(r)</strong> Contactar al Titular en el evento que se genere
          algún tipo de problema o inconveniente con los Datos Personales.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(s)</strong> Enviar comunicaciones, por cualquier medio, en
          relación con el estado de los servicios adquiridos a través de la
          Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(t)</strong> Manejo de la facturación y recaudo de cartera.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(u)</strong> Envío, recepción de mensajes de texto, correos
          electrónicos y/o otros con fines comunicativos, publicitarios y/o de
          atención al cliente, con el fin de mantener un contacto personalizado
          con los Usuarios.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(v)</strong> Acceder a los Datos Personales contenidos en la
          base de datos de terceros operadores de información para: (a) llevar a
          cabo la elaboración de puntajes (scores) crediticios, herramientas de
          validación de ingresos, herramientas predictivas de ingresos,
          herramientas para evitar el fraude, la suplantación y en general,
          adelantar una adecuada gestión del riesgo crediticio y (b) compararla,
          contrastarla y complementarla con la información financiera,
          comercial, crediticio, de servicios y obrante en centrales de
          información crediticia y/o operadores de bases de datos de información
          financiera, comercial, crediticia, entre otros.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(w)</strong> Consultar los Datos Personales en las centrales
          de información para conocer el desempeño como deudor, capacidad de
          pago o para valorar el riesgo futuro de conceder un crédito.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(x)</strong> Reportar a las centrales de información, sobre el
          cumplimiento o incumplimiento de las obligaciones por concepto de la
          prestación del servicio o por cualquier otra obligación que haya
          adquirido con uTriper, o quien este designe
        </dd>
        <dd>
          <br />
          <br />
          Como Titular de la información, yo declaro que se me ha informado de
          manera clara y comprensible que tengo derecho a conocer, actualizar y
          rectificar los datos personales proporcionados, a solicitar prueba de
          esta autorización, a solicitar información sobre el uso que se le ha
          dado a mis datos personales, a revocar esta autorización o solicitar
          la supresión de los datos personales suministrados y a acceder de
          forma gratuita a los mismos.
          <br />
          <br />
          Manifiesto que me informaron que, en caso de recolección de mi
          información sensible, tengo derecho a contestar o no las preguntas que
          me formulen y a entregar o no los datos solicitados.
          <br />
          <br />
          Entiendo que son datos sensibles aquellos que afectan la intimidad del
          Titular o cuyo uso indebido puede generar discriminación (Por ejemplo,
          orientación política, convicciones religiosas o filosóficas, datos
          relativos a la salud, a la vida sexual y los datos biométricos, tales
          como mi firma y fotos).
          <br />
          <br />
          Al hacer click en [ACEPTO] y autorizar sus datos, manifiesta así mismo
          que reconoce y acepta que cualquier consulta o reclamación relacionada
          con el Tratamiento de sus datos personales podrá ser elevada
          verbalmente o por escrito ante uTriper para lo cual este ha habilitado
          los siguientes medios:
          <br />
          <br />
          <ul>
            <li>Correo electrónico a hello@utriper.com</li>
            <br />
            <li>
              Enviando la solicitud por medio físico a la dirección Carrera Cl
              86 A # 22 A 12 de la ciudad de Bogotá.
            </li>
          </ul>
          <br />
          El Área Administrativa será la encargada de atender y dar respuesta a
          las peticiones, consultas y reclamos respecto de los datos personales
          de los Titulares. Declaro conocer que la Política la puedo consultar
          en <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a> .<br />
          <br />
        </dd>
      </dl>
    </div>
  );
};

export const DataProtectionAndManipulationSection = () => {
  return (
    <div>
      <center>
        <h3>
          <strong>
            MANUAL PARA LA PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES
          </strong>
        </h3>
        <h3>
          <strong>GLOBAL TRIPER COLOMBIA S.A.S</strong>
        </h3>
        <h5>
          <strong>hello@utriper.com</strong>
        </h5>
      </center>
      <br />
      <strong>
        1. <u>Introducción.</u>
      </strong>
      <br />
      <br />
      De conformidad con la Constitución Política de Colombia (Artículo 15) se
      consagra el derecho fundamental de todos los ciudadanos a su intimidad
      personal y familiar y a su buen nombre, y el deber de que el Estado los
      haga respetar. Asimismo, se establece que los ciudadanos tienen derecho a
      conocer, actualizar y rectificar la información que se hayan recogido
      sobre ellas en bases de datos y en archivos de entidades públicas y
      privadas.
      <br />
      <br />
      En el marco del desarrollo de este derecho fundamental, se han expedido
      una serie de normativas que establecen los deberes y las obligaciones en
      torno a la protección de la intimidad y privacidad de las personas, como
      lo es: Ley 1266 de 2008 “Por la cual se dictan disposiciones generales del
      habeas data y se regula el manejo de la información contenida en bases de
      datos personales, en especial la financiera, crediticia, comercial, de
      servicios y la provenientes de terceros países, y se dictan otras
      disposiciones”; Ley 1581 del 17 de Octubre de 2012 “Por la cual se dictan
      disposiciones generales para la Protección de Datos Personales"; y
      Circular Externa 005 de 2017 de la Superintendencia de Industria y
      Comercio “Por la cual se fijan estándares de un nivel adecuado de
      protección en el país receptor de la información personal”.
      <br />
      <br />
      Con el fin de dar cumplimiento a lo dispuesto por la normativa citada
      anteriormente, <strong>GLOBAL TRIPER COLOMBIA S.A.S</strong> (en adelante
      “<u>uTriper</u>”), en su calidad de Responsable del Tratamiento (como se
      define más adelante), tiene el deber de adoptar los instrumentos
      necesarios para que al interior de uTriper se garantice el adecuado
      cumplimiento de la protección de datos personales. Para tal fin, elabora
      el presente Manual de Tratamiento de Datos Personales (el “<u>Manual</u>
      ”).
      <br />
      <br />
      <strong>
        2. <u>Objeto.</u>
      </strong>
      <br />
      <br />
      El presente Manual tiene el propósito de definir los lineamientos para un
      adecuado tratamiento de los datos personales de parte de uTriper por medio
      de la política de tratamiento de la información personal, lo cual incluye:
      (i) procedimientos implementados por la organización para la atención de
      consultas y reclamos así como el permitir a los titulares de los datos
      personales ejercer su derecho de habeas data, estableciendo el
      procedimiento necesario que estos deben seguir si desean conocer,
      actualizar, rectificar y oponerse a los datos que se encuentren en
      nuestras bases de datos y/o archivos y (ii) procedimientos usados para la
      recolección, almacenamiento, uso, circulación y supresión de la
      información. Asimismo, este Manual está orientado a proteger y garantizar
      todo lo concerniente al tratamiento de datos personales, las libertades y
      los derechos de tipo fundamental que tienen todas las personas naturales.
      <br />
      <br />
      <strong>
        3. <u>Ámbito y Alcance.</u>
      </strong>
      <br />
      <br />
      <strong>GLOBAL TRIPER COLOMBIA S.A.</strong>, identificada con Nit.
      901.471.044 – 2, con domicilio principal en la ciudad de Bogotá en la
      dirección Cl 86 A # 22 A 12, en el rol de responsable o encargado del
      tratamiento de los datos personales, está comprometida con el adecuado
      tratamiento de los datos registrados en cualquier base de datos de uTriper
      y cuyo titular sea una persona natural o jurídica (empleados, usuarios y
      proveedores), bien sea que se encuentre en territorio colombiano o
      extranjero. Por lo tanto, en el presente Manual se articulan los
      procedimientos y actividades que involucran el tratamiento de los datos
      personales, los cuales están alineados con las normas y directrices que lo
      regulan.
      <br />
      <br />
      <strong>
        4. <u>Definiciones.</u>
      </strong>
      <br />
      <br />
      <ul>
        <li>
          <strong>Autorización-.</strong> Se refiere al consentimiento previo,
          expreso e informado del Titular para llevar a cabo el Tratamiento de
          Datos Personales.
        </li>
        <br />
        <li>
          <strong>Aviso de privacidad-.</strong> Se refiere a la comunicación
          verbal o escrita dirigida a los Titulares de los datos personales que
          están siendo tratados por uTriper, en la cual se le informa acerca de
          la existencia de las políticas de tratamiento de Datos Personales que
          le serán aplicadas, la forma de acceder a la mismas, y las finalidades
          para las cuales serán usados sus Datos Personales.
        </li>
        <br />
        <li>
          <strong>Base de Datos-.</strong> Se refiere al conjunto organizado de
          Datos Personales que sea objeto de Tratamiento.
        </li>
        <br />
        <li>
          <strong>Dato Personal-.</strong> Se refiere cualquier información
          vinculada o que pueda asociarse a una o varias personas naturales
          determinadas o determinables; a información suministrada por cualquier
          persona natural o jurídica.
        </li>
        <br />
        <li>
          <strong>Datos Sensibles-.</strong> Se entiende como datos sensibles
          aquellos que afecten la intimidad del Titular o cuyo uso indebido
          pueda afectar la intimidad del Titular o la potencialidad de generar
          su discriminación, tales como aquellos que revelen el origen racial o
          étnico, la orientación política, las convicciones religiosas o
          filosóficas, la pertenencia a sindicatos, organizaciones sociales, de
          derechos humanos o que promueva intereses de cualquier partido
          político o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la salud, a la
          vida sexual y los datos biométrico.
        </li>
        <br />
        <li>
          <strong>Datos Públicos-.</strong> Se refiere al dato calificado como
          tal en virtud de la ley y aquel que no sea semiprivados, privados o
          sensibles. Son considerados datos públicos, entre otros, los datos
          relativos al estado civil de las personas, a su profesión u oficio, a
          su calidad de comerciante o servidor público y aquellos que puedan
          obtenerse sin reserva alguna. Por su naturaleza, los datos públicos
          pueden estar contenidos, entre otros, en registros públicos,
          documentos públicos, gacetas y boletines oficiales.
        </li>
        <br />
        <li>
          <strong>Encargado del Tratamiento-.</strong> Persona natural o
          jurídica, pública o privada, que por sí misma o en asocio con otros,
          realice el Tratamiento de Datos Personales por cuenta del Responsable
          del Tratamiento. Dentro los cuales se encuentran, pero sin limitarse
          a: abogados, consultores, proveedores o Tour Operadores. Todo
          Encargado deberá ceñirse a los deberes legales e institucionales sobre
          el Tratamiento de Datos Personales.
        </li>
        <br />
        <li>
          <strong>Experiencias Turísticas-.</strong> Significan los planes de
          servicios turísticos y/o actividades de entretenimiento ofrecidas en
          la Plataforma de uTriper.
        </li>
        <br />
        <li>
          <strong>Plataforma-.</strong> Significan (i) el sitio web:{' '}
          <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a> , otros dominios
          (urls), (ii) aplicativos móviles que se puedan descargar por medio de
          tiendas virtuales de aplicaciones y conjunto de sistemas por medio de
          los cuales uTriper ofrece sus servicios y desarrolla sus actividades.
        </li>
        <br />
        <li>
          <strong>Responsable del Tratamiento-.</strong> Persona natural o
          jurídica, pública o privada, que por sí misma o en asocio con otros,
          decida sobre la Base de Datos y/o el Tratamiento de los datos.
        </li>
        <br />
        <li>
          <strong>Titular-.</strong> Persona natural o jurídica cuyos Datos
          Personales sean objeto de Tratamiento.
        </li>
        <br />
        <li>
          <strong>Tour Operador-.</strong> significa el usuario, persona natural
          o jurídica, que ingresa a la Plataforma de uTriper para ofrecer y
          anunciar planes de Experiencias Turísticas y quien será a la vez el
          Encargado del Tratamiento de los Datos Personales de los Tour
          Usuarios.
        </li>
        <br />
        <li>
          <strong>Tour Usuario-.</strong> significa el usuario, persona natural
          o jurídica, que ingresa a la Plataforma de uTriper para visitar la
          información y comprar las Experiencias Turísticas.
        </li>
        <br />
        <li>
          <strong>Transferencia de Datos-.</strong> La transferencia de datos
          tiene lugar cuando el Responsable y/o Encargado del Tratamiento de
          datos personales, ubicado en Colombia, envía la información o los
          datos personales a un receptor, que a su vez es Responsable del
          Tratamiento y se encuentra dentro o fuera del país.
        </li>
        <br />
        <li>
          <strong>Tratamiento-.</strong> Cualquier operación o conjunto de
          operaciones sobre Datos Personales, tales como la recolección,
          almacenamiento, uso, circulación o supresión de los mismos.
        </li>
        <br />
        <li>
          <strong>Usuario-.</strong> Significa cualquier persona que acceda a la
          Plataforma uTriper, bien sea Tour Usuario o Tour Operador, conforme a
          lo establecido en los Términos y Condiciones de cada uno.
          <br />
          Para el entendimiento de los términos que no se encuentran incluidos
          dentro del listado anterior, se deberá remitir a la legislación
          vigente (Ley 1266 de 2008, Ley 1581 de 2012 y al Decreto 1377 de 2013)
          dando el sentido utilizado en dicha norma a los términos de cuya
          definición exista duda alguna.
        </li>
      </ul>
      <br />
      <br />
      <strong>
        5. <u>Principios aplicables al Tratamiento de Datos.</u>
      </strong>
      <br />
      <br />
      uTriper aplicará los siguientes principios que se establecen a
      continuación, los cuales constituyen las reglas a seguir en lo referente a
      la recolección, manejo, uso, tratamiento, almacenamiento e intercambio, de
      Datos Personales:
      <br />
      <br />
      <dl>
        <dd>
          <strong>(a) Principio de legalidad:</strong> En el uso, captura,
          recolección y Tratamiento de Datos Personales, se dará aplicación a
          las disposiciones vigentes y aplicables que rigen el tratamiento de
          datos personales y demás derechos fundamentales conexos.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b) Principio de finalidad:</strong> Todos los Datos
          Personales que sean capturados en el desarrollo del ejercicio de las
          funciones que tiene uTriper, deben atender a finalidades específicas
          de acuerdo con el Tratamiento que se le dará al dato. Las finalidades
          del Tratamiento deben ser informadas a los titulares con el propósito
          que éstos conozcan las actividades que desarrollará uTriper con los
          Datos Personales que está entregando.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c) Principio de libertad:</strong> La recolección,
          almacenamiento y Tratamiento de los Datos Personales sólo puede
          realizarse con la Autorización previa y expresa del Titular, quien
          debe ser informado sobre el Tratamiento que se les dará a sus Datos
          Personales. La divulgación o socialización de los Datos Personales sin
          la previa Autorización, o sin una disposición legal que lo habilite,
          está prohibido.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(d) Principio de veracidad o calidad:</strong> uTriper debe
          promover que los Datos Personales que estarán sujetos a Tratamiento
          deben ser veraces, exactos, completos y actualizados, pues de lo
          contrario pueden llevar a inducir a errores en la ejecución de
          tratamiento para el cual fueron capturados.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(e) Principio de Transparencia:</strong> Cualquier Titular de
          información podrá tener acceso, en cualquier momento, a la información
          sobre sus Datos Personales tratados por uTriper.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(f) Principio de Acceso y circulación restringida:</strong> El
          Tratamiento de los Datos Personales sólo podrá ser realizado por
          aquellos que el Titular haya efectivamente autorizado, o por las
          personas habilitadas por las disposiciones legales vigentes.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(g) Principio de Seguridad:</strong> Toda la información
          asociada a los Datos Personales objeto de Tratamiento por parte de
          uTriper, deberán protegerse bajo estándares de seguridad adecuados,
          implementando medidas operativas, técnicas y humanas que eviten su
          pérdida, adulteración o acceso no autorizado.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(h) Confidencialidad:</strong> uTriper deberá garantizar la
          reserva de la información y Datos Personales que no estén bajo la
          categoría de Datos Públicos, por lo cual, todas las personas que
          tengan acceso al Tratamiento de los Datos Personales deberán promover
          prácticas de manejo de datos que eviten su exposición o suministro a
          terceros no autorizados.
        </dd>
        <dd>
          <br />
          <br />
          <center>
            <strong>
              I. Procedimientos usados para la recolección, almacenamiento, uso,
              circulación y supresión de la información
            </strong>
          </center>
          <br />
          <br />
          <strong>
            5. <u>Autorización.</u>
          </strong>
          <br />
          <br />
          Toda captura, recolección, uso y almacenamiento de Datos Personales
          que realice uTriper en el desarrollo de sus actividades, y de aquellas
          finalidades dispuestas en la Política de Protección de Datos
          Personales, requiere por parte de los Titulares un consentimiento
          libre, previo, expreso, inequívoco e informado.
          <br />
          <br />
          Por tal motivo, uTriper ha puesto a disposición de los Titulares la
          Autorización para el Tratamiento de sus Datos Personales al momento de
          la creación de un perfil en la Plataforma, o por medio de Avisos de
          Privacidad en donde se informa al Titular sobre la recolección de sus
          Datos Personales, el Tratamiento al cual serán sometidos incluyendo
          las finalidades, sus derechos, los canales de ejercicio de sus
          derechos y la información relacionada sobre la Política de Protección
          de Datos Personales.
          <br />
          <br />
          <strong>6 . 1. Información previa Autorización</strong>
          <br />
          <br />
          uTriper brindará a los Titulares la siguiente información al momento
          de realizar la Autorización:
          <br />
          <br />
        </dd>
        <dd>
          <strong>a)</strong> Los Datos Personales que serán recolectados.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>b)</strong> La identificación y datos de contacto del
          Responsable del Tratamiento.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>c)</strong> Las finalidades específicas del Tratamiento que se
          pretende realizar, es decir: cómo y para qué se va a hacer la
          recolección, el uso, la circulación de los Datos Personales.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>d)</strong> Los derechos que tiene como Titular de los Datos
          Personales.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>e)</strong> El carácter facultativo de la respuesta a las
          preguntas que le sean hechas, cuando estas versen sobre Datos
          Sensibles o sobre los datos de niñas, niños y adolescentes.
        </dd>
        <dd>
          <br />
          <br />
          <strong>6 . 2. Recolección Autorización</strong>
          <br />
          <br />
          En lo correspondiente a Datos Personales a través de diferentes
          medios, tales como:
          <br />
          <br />
        </dd>
        <dd>
          <strong>a)</strong> Por escrito, mediante documentos físicos,
          electrónicos, mensaje de datos, correo electrónico, internet o la
          Plataforma de uTriper.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>b)</strong> De forma oral, por ejemplo, en una conversación
          telefónica o en video conferencia; y / o
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>c)</strong> Mediante conductas inequívocas que permitan
          concluir que otorgó su autorización, por ejemplo, a través de su
          aceptación expresa a los Términos y Condiciones de una actividad
          dentro de los cuales se requiera la autorización de los participantes
          para el Tratamiento de sus Datos Personales.
        </dd>
        <dd>
          <br />
          <br />
          Sin perjuicio de lo anterior, en ningún caso el silencio del Titular
          se asimilará a una conducta inequívoca.
          <br />
          <br />
          <strong>
            6 . 3. Casos en los que no se requiere de Autorización
          </strong>
          <br />
          <br />
          Excepcionalmente, existen algunos casos en que no se requerirá de la
          autorización expresa del titular de los datos, tales como:
          <br />
          <br />
        </dd>
        <dd>
          <strong>a)</strong> Información requerida por una entidad pública o
          administrativa en ejercicio de sus funciones legales o por orden
          judicial.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>b)</strong> Datos de naturaleza pública (Datos Públicos).
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>c)</strong> Casos de urgencia médica o sanitaria.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>d)</strong> Tratamiento de información autorizado por la ley
          para fines históricos, estadísticos o científicos.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>e)</strong> Datos relacionados con el Registro Civil de las
          personas.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>f)</strong> En el evento de una venta, fusión, consolidación,
          cambio en el control societario, transferencia de activos,
          reorganización o liquidación de uTriper y/o sus entidades Vinculadas
          y/o Relacionadas, uTriper podrá transferir la Información Personal de
          los Titulares a las partes involucradas, para lo cual por medio de la
          aceptación de la Política de Tratamiento de Datos, uTriper se entiende
          queda facultada para realizarlo.
        </dd>
        <dd>
          <br />
          <br />
          <strong>6 . 4. Prueba de la Autorización</strong>
          <br />
          <br />
          uTriper utilizará los mecanismos y herramientas que tenga disponibles
          para mantener los registros de la Autorización otorgada por parte de
          los Titulares, así como la fecha y la manera en la que dicha
          Autorización fue otorgada. Para lo cual, uTriper podrá contar con
          archivos físicos o repositorios electrónicos realizados de manera
          directa o a través de terceros contratados para tal fin.
          <br />
          <br />
          <strong>6 . 5. Revocatoria de la Autorización</strong>
          <br />
          <br />
          Los Titulares de los Datos Personales pueden revocar el consentimiento
          al Tratamiento de sus Datos personales en cualquier momento, siempre y
          cuando no lo impida una disposición legal o contractual. Para lo cual,
          uTriper deberá establecer mecanismos sencillos y gratuitos que
          permitan al Titular revocar su Autorización.
          <br />
          <br />
          Se debe tener en cuenta que existen dos (2) modalidades en la que la
          revocación de la Autorización puede darse:
          <br />
        </dd>
        <dd>
          <strong>a) Revocatoria total:</strong> puede ser sobre la totalidad de
          las finalidades consentidas, lo cual implica que uTriper deba dejar de
          realizar por completo el Tratamiento de los Datos del Titular.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>b) Revocatoria parcial:</strong> puede ocurrir sobre tipos de
          Tratamiento determinados, como por ejemplo para fines publicitarios o
          de estudios de mercado. Con la revocatoria parcial de la Autorización,
          se mantienen a salvo otros fines del Tratamiento que el Responsable,
          de conformidad con la Autorización otorgada, puede llevar a cabo y con
          los que el Titular está de acuerdo. El Titular deberá especificar los
          datos a los cuales Autoriza a uTriper para que continúe con el
          Tratamiento.
        </dd>
        <dd>
          <br />
          Para llevar a cabo la revocatoria o la supresión de su información, el
          Titular deberá manifestarlo por escrito a uTriper, conforme se
          establece en el Artículo No.9.2 del presente Manual. uTriper no podrá
          en ningún caso obstaculizar esta pretensión, exceptuando los casos que
          determine la ley.
          <br />
          <br />
          <strong>7. Almacenamiento</strong>
          <br />
          <br />
          Los datos de los usuarios serán almacenados y/o procesados en los
          servidores ubicados en centros de cómputo, ya sean propios o
          contratados con terceros proveedores.
          <br />
          <br />
          <strong>8. Uso y Circulación</strong>
          <br />
          <br />
          uTriper salvaguarda los datos de los cuales este sea responsable con
          extremo cuidado y precaución y por consiguiente no entregará los datos
          a terceros sin la debida autorización previa por parte de los
          Titulares.
          <br />
          <br />
          <center>
            <strong>
              II. Procedimientos implementados por la organización para la
              atención de consultas y reclamos
            </strong>
          </center>
          <br />
          <br />
          <strong>
            9. <u>Procedimiento de atención a consultas, quejas y reclamos.</u>
          </strong>
          <br />
          <br />
          El procedimiento de consultas y reclamos se ejecutará de acuerdo con
          los términos incluidos en la ley y acogidos por la Política de
          Protección de Datos Personales.
          <br />
          <br />
          uTriper ha designado como responsable de velar por el cumplimiento de
          este procedimiento al interior de la empresa a las personas del equipo
          de Atención del Cliente de uTriper, quienes estarán disponibles para
          la atención de peticiones, consultas y reclamos por parte de los
          Titulares y para realizar cualquier actualización, rectificación y
          supresión de Datos Personales (los “<u>Oficiales de Cumplimiento</u>
          ”).
          <br />
          <br />
          Asimismo, los Oficiales de Cumplimiento serán quienes absolverán
          cualquier duda o inquietud que cualquiera de los titulares tenga, a
          través del correo electrónico que se relaciona en el encabezado de
          este documento.
          <br />
          <ul>
            Se podrá contactar con los Oficiales de Cumplimiento por los
            siguientes medios:
            <br />
            <br />
            Dirección: Cl 86 A # 22 A 12
            <br />
            Correo Electrónico: hello@utriper.com
            <br />
            Teléfono: +57 3182436409
          </ul>
          <br />
          <br />
          <strong>9 . 1. Procedimiento para la atención de consultas</strong>
          <br />
          <br />
          Las consultas y solicitudes de los Titulares serán atendidas y
          tramitadas ante los Oficiales de Cumplimiento de uTriper en un término
          máximo de diez (10) días hábiles, contados a partir de la fecha de
          recibo de las mismas. En caso de que no sea posible resolver la
          consulta dentro de este término, el Titular será informado de dicha
          situación en la dirección de notificación que haya incluido en la
          respectiva consulta, y el término de respuesta se podrá extender hasta
          por cinco (5) días hábiles adicionales. La respuesta a las consultas o
          reclamos que los Titulares presenten podrán ser entregadas por
          cualquier medio físico o electrónico.
          <br />
          <br />
          <strong>
            9 . 2. Procedimiento para rectificación o reclamos de la información
          </strong>
          <br />
          <br />
          Los Titulares podrán en todo momento solicitar a uTriper la supresión,
          corrección o actualización de sus Datos Personales y/o revocar la
          Autorización otorgada para el tratamiento de los mismos, mediante la
          presentación de un reclamo de conformidad con el presente artículo.
          Cuando el Titular de la Información advierta un presunto
          incumplimiento por parte de uTriper de sus deberes en materia de
          Protección de Datos Personales y desee ejercer sus derechos contenidos
          en la legislación aplicable y en la presente Manual.
          <br />
          <br />
          La radicación de solicitud se formulará mediante comunicación dirigida
          al área de Oficiales de Cumplimiento con la identificación del
          Titular, la descripción de los hechos que dan lugar a la solicitud, la
          dirección del Titular acompañado de los documentos que soporten la
          solicitud de ser aplicable, ateniéndose a los siguientes requisitos y
          procedimiento:
          <br />
          <br />
          <ul>
            <strong>a)</strong>Para la radicación y atención de su Rectificación
            o Reclamo, se deberá presentar por solicitud por escrito la cual
            debe contener la siguiente información:
            <br />
            <br />
            <ul>
              <ul>
                i. Nombre completo y apellidos
                <br />
                ii. Datos de contacto (Dirección física y/o electrónica y
                teléfonos de contacto)
                <br />
                iii. Medios para recibir respuesta a su solicitud,
                <br />
                iv. Motivo(s)/hecho(s) que dan lugar al reclamo con una breve
                descripción del derecho que desea ejercer (conocer, actualizar,
                rectificar, solicitar prueba de la autorización otorgada,
                revocarla, suprimir, acceder a la información).
                <br />
                v. Firma (si aplica).
                <br />
                vi. Número de identificación.
                <br />
                vii. Copia de los documentos que se requiera hacer valer, en
                caso de aplicar.
              </ul>
            </ul>
            <br />
            <br />
            <strong>b)</strong>En caso en que la solicitud resulte incompleta,
            uTriper requerirá al interesado dentro de los cinco (5) días
            siguientes a la recepción de la solicitud para que complete y
            subsanase su petición. Transcurridos dos (2) meses desde la fecha
            del requerimiento, sin que el solicitantepresente la información
            requerida, se entenderá que ha desistido de la Rectificación y/o
            Reclamo.
            <br />
            <br />
            <strong>c)</strong>Una vez recibido la solicitud completa, se
            incluirá en la Base de Datos una leyenda que diga “reclamo en
            trámite” y el motivo del mismo, actividad que deberá realizarse en
            un término no superior a dos (2) días hábiles. Dicha leyenda deberá
            mantenerse hasta que la solicitud sea decidida.
            <br />
            <br />
            <strong>d)</strong>En el caso de quien reciba la solicitud no sea
            competente para resolverlo, se procederá a un traslado a quien sí lo
            sea para que resuelva en un término máximo de dos (2) días hábiles
            contados a partir de la fecha en la que confirma recibida la
            solicitud, y le deberá notificar al solicitado de tal hecho.
            <br />
            <br />
            <strong>e)</strong>uTriper resolverá la solicitud en un término
            máximo de quince (15) días hábiles contados a partir del día
            siguientes de recibo del mismo. En caso de que no sea posible
            resolver la consulta dentro de este término, el solicitante será
            informado de la demora, los motivos y la fecha de respuesta en la
            dirección de notificación que haya incluido en el respectivo
            reclamo. En todo caso, el término de respuesta no podrá superar de
            ocho (8) días hábiles siguientes al vencimiento del primer término.
            La respuesta a los reclamos que los Titulares presenten podrá ser
            efectuadas por cualquier medio físico o electrónico.
            <br />
            <br />
          </ul>
          <strong>
            10. <u>Identificación del Responsable del Tratamiento.</u>
          </strong>
          <br />
          <br />
          <strong>GLOBAL TRIPER COLOMBIA S.A.S</strong> identificada con el NIT
          901.471.044 – 2 será el Responsable del Tratamiento de los Datos.
          <br />
          <ul>
            <strong>Domicilio y contacto:</strong>
            <br />
            <br />
            Nombre o Razón Social: Global Triper Colombia S.A.S
            <br />
            Dirección: Cl 86 A # 22 A 12 de la ciudad de Bogotá D.C.
            <br />
            Correo Electrónico: hello@utriper.com
            <br />
            Página Web: <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a>
            <br />
            Teléfono: + 57 3182436409
          </ul>
          <br />
          <br />
          <strong>
            12. <u>Derechos de los Titulares de Datos Personales</u>
          </strong>
          <br />
          <br />
          De conformidad con el artículo 6 de la Ley 1266 de 2008 y, el artículo
          8 de la Ley 1581 de 2012, los derechos que como Titular le asisten en
          relación con sus Datos Personales son:
          <br />
          <br />
        </dd>
        <dd>
          <strong>a) Derecho de acceso-.</strong> Solicitar, conocer, actualizar
          y rectificar sus Datos Personales que hayan sido objeto de
          Tratamiento, de manera gratuita.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>b) Derecho de Actualización-.</strong> Conocer, actualizar y
          rectificar sus Datos Personales frente a la Firma o los Encargados del
          Tratamiento de los mismos. Este derecho se podrá ejercer frente a
          Datos Personales parciales, inexactos, incompletos, fraccionados, que
          induzcan a error, o aquellos cuyo Tratamiento esté expresamente
          prohibido o no haya sido autorizado.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>c) Derecho de Prueba-.</strong> Solicitar prueba de la
          existencia de la autorización otorgada a uTriper, salvo cuando
          expresamente se exceptúe como requisito para el Tratamiento, de
          conformidad con lo establecido en el artículo 10 de la Ley 1581 de
          2012 (o en las normas que la reglamenten, adicionen, complementen,
          modifiquen o deroguen).
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>d) Derecho de Información-.</strong> Presentar solicitud
          correspondiente a uTriper o al Encargado del Tratamiento sobre la
          información respecto al uso que se le ha dado a su Datos Personales.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>e) Derecho de quejas y reclamos-.</strong> El derecho a
          presentar ante la Superintendencia de Industria y Comercio quejas por
          infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás normas
          que la modifiquen, después de haber agotado el trámite de consulta o
          reclamo establecido en la cláusula 9 de la presente Política.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>f) Derecho de revocación-.</strong> Revocar la autorización
          y/o solicitar la supresión de sus Datos Personales de las Bases de
          Datos, cuando la Superintendencia de Industria y Comercio haya
          determinado que uTriper no le dio a los Datos Personales un uso
          conforme a los usos y finalidades autorizados y siempre que no exista
          un deber legal o contractual que impida eliminarlos.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>g) Derecho de conocimiento-.</strong> Conocer las
          modificaciones a los términos de esta Política de manera previa a la
          implementación de las nuevas modificaciones o, en su defecto, de la
          nueva política de tratamiento de la información.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>h) Derecho de supresión-.</strong> Solicitar la supresión de
          sus Datos Personales de las Bases de Datos siempre y cuando no exista
          un deber.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            13.{' '}
            <u>
              Deberes del Responsable y Encargado del Tratamiento de Datos
              Personales.
            </u>
          </strong>
          <br />
          <br />
          <strong>
            13 . 1. <u>Deberes del Responsable.</u>
          </strong>
          <br />
          <br />
          Al ser uTriper el Responsable de Tratamiento de Datos Personales tanto
          de sus empleados, como de sus Usuarios y proveedores se compromete a
          cumplir con los siguientes deberes:
          <br />
          <br />
          <ul>
            1) Solicitar y conservar, en las condiciones previstas en la
            Política de Tratamiento de Datos Personales de uTriper, copia de la
            respectiva autorización otorgada por el Titular.
            <br />
            2) Informar de manera clara y suficiente al Titular sobre la
            finalidad de la recolección y los derechos que le asisten por virtud
            de la Autorización otorgada.
            <br />
            3) Informar a solicitud del Titular sobre el uso dado a sus Datos
            Personales.
            <br />
            4) Tramitar las consultas y reclamos formulados en los términos
            señalados en el Manual.
            <br />
            5) Procurar que los principios de finalidad, libertad, veracidad,
            seguridad, confidencialidad, transparencia, acceso y circulación y
            confidencialidad estén presentes durante todo el Tratamiento de los
            Datos.
            <br />
            6) Conservar la información bajo las condiciones de seguridad
            necesarias para impedir su adulteración, pérdida, consulta, uso o
            acceso no autorizado o fraudulento.
            <br />
            7) Actualizar la información cuando sea necesario.
            <br />
            8) Rectificar los datos personales cuando ello sea procedente.
            <br />
            <br />
            Los Oficiales de Cumplimiento, deberán desarrollar un plan de
            supervisión y revisión anual, para lo cual tendrán que establecer
            las medidas de desempeño e incluir un calendario de cuándo deben ser
            revisadas las políticas y los controles del programa.
          </ul>
          <br />
          <br />
          <strong>
            13 . 2. <u>Deberes del Encargado.</u>
          </strong>
          <br />
          <br />
        </dd>
        <dd>
          <strong>a)</strong> Garantizar al Titular, en todo tiempo, el pleno y
          efectivo ejercicio del derecho de hábeas data.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>b)</strong> Conservar la información bajo las condiciones de
          seguridad necesarias para impedir su adulteración, pérdida, consulta,
          uso o acceso no autorizado o fraudulento.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>c)</strong> Realizar oportunamente la actualización,
          rectificación o supresión de los Datos Personales.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>d)</strong> Actualizar la información reportada por los
          Responsables del tratamiento dentro de los cinco (5) días hábiles
          contados a partir de su recibo.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>e)</strong> Tramitar las consultas y los reclamos formulados
          por los Titulares en los términos señalados en el presente Manual.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>f)</strong> Registrar en la Base de Datos la leyenda “reclamo
          en trámite” en la forma en que se establece en el presente Manual.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>g)</strong> Insertar en la Base de Datos la leyenda
          “información en discusión judicial” una vez notificado por parte de la
          autoridad competente sobre procesos judiciales relacionados con la
          calidad del dato personal.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>h)</strong> Abstenerse de circular información que esté siendo
          controvertida por el Titular y cuyo bloqueo haya sido ordenado por la
          Superintendencia de Industria y Comercio.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>i)</strong> Permitir el acceso a la información únicamente a
          las personas autorizadas por el Titular o facultadas por la ley para
          dicho efecto.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>j)</strong> Informar a la Superintendencia de Industria y
          Comercio cuando se presenten violaciones a los códigos de seguridad y
          existan riesgos en la administración de la información de los
          titulares.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>k)</strong> Cumplir las instrucciones y requerimientos que
          imparta la Superintendencia de Industria y Comercio.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            14. <u>Manejo de datos entre personal interno de uTriper</u>
          </strong>
          <br />
          <br />
          Por las diferentes actividades que se llevan a cabo dentro de uTriper,
          es necesario que algunos empleados tengan acceso a los Datos
          Personales, por lo tanto, estos firmarán un acuerdo de
          confidencialidad en el que tendrán la obligación de dar un uso
          adecuado a la información que llegue a su conocimiento y la
          prohibición que les asiste de manipular, transferir o transmitir estos
          datos sin la debida Autorización.
          <br />
          <br />
          <strong>
            15. <u>Disposición final de los Datos Personales.</u>
          </strong>
          <br />
          <br />
          Cuando por solicitud escrita de un usuario o en caso de que uTriper ya
          no requiera almacenar y tratar los Datos Personales de algún Titular,
          se procederá a eliminarlos de la Base de Datos implementada. Si se
          requiere obtener nuevamente los datos, se tendrá que firmar una nueva
          Autorización con el Titular correspondiente. Se exceptúa de esta
          supresión los datos que por disposición legal deban ser conservados.
          <br />
          <br />
          <strong>
            16. <u>Modificación del Manual.</u>
          </strong>
          <br />
          <br />
          El presente Manual podrá ser modificado en cualquier momento, lo cual
          se comunicará a los Titulares, mediante cualquier medio que uTriper
          considere, para que los Titulares conozcan los procedimientos internos
          que se han adecuado dentro de uTriper. Sin perjuicio de lo anterior,
          los cambios que se realicen en este documento no alterarán ni
          afectarán de forma alguna los Datos Personales suministrados por los
          Titulares.
          <br />
          <br />
          <strong>
            17.{' '}
            <u>
              Fecha de entrada en vigencia del presente manual para el
              tratamiento de datos personales.
            </u>
          </strong>
          <br />
          <br />
          La fecha de entrada en vigencia del presente Manual es 1 de febrero de
          2022, y se encontrará vigente hasta tanto no se realice modificación
          alguna del mismo.
        </dd>
      </dl>
    </div>
  );
};

export const PrivacyPolicyAndDataProtectionSection = () => {
  return (
    <div>
      <center>
        <h3>
          <strong>
            POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES DE GLOBAL
            TRIPER COLOMBIA S.A.S
          </strong>
        </h3>
      </center>
      <br />
      <br />
      <br />A continuación, se presenta la Política de Privacidad y Protección
      de Datos Personales (en adelante la “
      <strong>Política de Privacidad</strong>”) aplicable a todas las personas
      naturales y jurídicas que suministren sus datos personales (en adelante el
      “<strong>Titular</strong>” o el “
      <strong>Titular de la Información</strong>”) a la sociedad GLOBAL TRIPER
      COLOMBIA S.A.S. (en adelante “<strong>uTriper</strong>”), la cual podrá
      ser recolectada, entre otros, través de la página web{' '}
      <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a> , otros URLs y/o
      aplicativos móviles de propiedad de uTriper. Dicha información será
      incluida en las bases de datos de uTriper y será tratada de conformidad
      con la política que a continuación se presenta.
      <br />
      <br />
      En el marco de sus actividades, <strong>uTriper</strong> tiene el deber de
      dar cumplimiento a las normas de protección de datos, en especial a: (i)
      la Ley Estatutaria 1581 de 2012, por medio de la cual se dictan
      disposiciones generales con respecto a la protección de datos personales;
      (ii) los principios y disposiciones de la Ley 1581 de 2012 en relación con
      los datos personales registrados en cualquier base de datos que los haga
      susceptibles de tratamiento por parte de <strong>uTriper</strong>; (iii)
      la Ley Estatutaria 1266 de 2008 por medio de la cual se establecen
      disposiciones generales del hábeas data y se regula el manejo de la
      información contenida en bases de datos personales, en especial la
      financiera, crediticia, comercial, de servicios y la proveniente de
      terceros países; Y, (iv) los decretos reglamentarios siguientes como el
      Decreto 1377 de 2013 y el Decreto 886 de 2014.
      <br />
      <br />
      <strong>
        1. <u>Objetivo</u>
      </strong>
      <br />
      <br />
      El presente documento de política de privacidad y protección de datos
      personales (en adelante la “<strong>Política</strong>”) tiene el objetivo
      de establecer los criterios para la recolección, almacenamiento, uso,
      transferencia, circulación y supresión de los datos personales de
      usuarios, contratistas proveedores y en general terceros, que sean
      tratados por <strong>uTriper</strong>. En este sentido,{' '}
      <strong>uTriper</strong> ha dispuesto la presente Política, con el
      propósito de regular de manera clara y dar a conocer los aspectos
      relevantes con relación a los datos personales de la comunidad,
      incluyendo, pero sin limitarse a los empleados, proveedores, terceros y
      usuarios de su plataforma, quienes hayan otorgado autorización para el
      manejo de sus datos a uTriper. Esta Política aplica para toda la
      información personal registrada en las bases de datos de{' '}
      <strong>uTriper</strong> quien podrá actuar en calidad de responsable o
      encargado del tratamiento de los datos personales.
      <br />
      <br />
      <strong>
        2. <u>Definiciones</u>
      </strong>
      <br />
      <br />
      <strong>Autorización-.</strong> Se refiere al consentimiento previo,
      expreso e informado del Titular para llevar a cabo el Tratamiento de datos
      personales.
      <br />
      <br />
      <strong>Aviso de privacidad-.</strong> Se refiere a la comunicación verbal
      o escrita dirigida a los Titulares de los datos personales que están
      siendo tratados por <strong>uTriper</strong>, en la cual se le informa
      acerca de la existencia de las políticas de tratamiento de datos
      personales que le serán aplicadas, la forma de acceder a la mismas, y las
      finalidades para las cuales serán usados sus datos personales.
      <br />
      <br />
      <strong>Base de Datos-.</strong> Se refiere al conjunto organizado de
      Datos Personales que sea objeto de Tratamiento.
      <br />
      <br />
      <strong>Cuenta Tour Usuario-.</strong> significa la cuenta de los Tour
      Usuarios de <strong>uTriper</strong> para uso de la Plataforma.
      <br />
      <br />
      <strong>Cuenta Tour Operador-.</strong> significa la cuenta de los Tour
      Operadores de <strong>uTriper</strong> para uso de la Plataforma.
      <br />
      <br />
      <strong>Dato Personal-.</strong> Se refiere cualquier información
      vinculada o que pueda asociarse a una o varias personas naturales
      determinadas o determinables; a información suministrada por cualquier
      persona natural o jurídica que se registre en nuestra página web y/o
      aplicativos móviles.
      <br />
      <br />
      <strong>Datos Sensibles-.</strong> Se entiende como datos sensibles
      aquellos que afecten la intimidad del Titular o cuyo uso indebido pueda
      afectar la intimidad del Titular o la potencialidad de generar su
      discriminación, tales como aquellos que revelen el origen racial o étnico,
      la orientación política, las convicciones religiosas o filosóficas, la
      pertenencia a sindicatos, organizaciones sociales, de derechos humanos o
      que promueva intereses de cualquier partido político o que garanticen los
      derechos y garantías de partidos políticos de oposición, así como los
      datos relativos a la salud, a la vida sexual y los datos biométrico.
      <br />
      <br />
      <strong>Datos Eliminados-.</strong> Se refiere a los datos de los cuales
      no se pudo obtener la autorización expresa del Titular para llevar a cabo
      su tratamiento o que, por solicitud del Titular, se requiera su
      eliminación o aquellos que <strong>uTriper</strong> decida eliminar de sus
      Bases de Datos.
      <br />
      <br />
      <strong>Datos Públicos-.</strong> Se refiere al dato calificado como tal
      en virtud de la ley y aquel que no sea semiprivados, privados o sensibles.
      Son considerados datos públicos, entre otros, los datos relativos al
      estado civil de las personas, a su profesión u oficio, a su calidad de
      comerciante o servidor público y aquellos que puedan obtenerse sin reserva
      alguna. Por su naturaleza, los datos públicos pueden estar contenidos,
      entre otros, en registros públicos, documentos públicos, gacetas y
      boletines oficiales.
      <br />
      <br />
      <strong>Dato Privado-.</strong> Es el dato que por su naturaleza íntima o
      reservada sólo es relevante para el titular.
      <br />
      <br />
      <strong>Encargado del Tratamiento-.</strong> Persona natural o jurídica,
      pública o privada, que por sí misma o en asocio con otros, realice el
      Tratamiento de Datos Personales por cuenta del Responsable del
      Tratamiento.
      <br />
      <br />
      <strong>Experiencias Turísticas-.</strong> significan los planes de
      servicios turísticos y/o actividades de entretenimiento ofrecidas en la
      Plataforma de uTriper.
      <br />
      <br />
      <strong>Plataforma-.</strong> significan (i) el sitio web:{' '}
      <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a> , otros dominios (urls),
      (ii) aplicativos móviles que se puedan descargar por medio de tiendas
      virtuales de aplicaciones y conjunto de sistemas por medio de los cuales
      uTriper ofrece sus servicios y desarrolla sus actividades.
      <br />
      <br />
      <strong>Responsable del Tratamiento-.</strong> Persona natural o jurídica,
      pública o privada, que por sí misma o en asocio con otros, decida sobre la
      Base de Datos y/o el Tratamiento de los datos.
      <br />
      <br />
      Nombre o Razón Social: Global Triper Colombia S.A.S
      <br />
      Dirección: Cl 86 A # 22 A 12 de la ciudad de Bogotá D.C.
      <br />
      Correo Electrónico: hello@utriper.com
      <br />
      Página Web: <a href="https://www.utriper.com/" target="_blank">www.utriper.com</a>
      <br />
      Teléfono: + 57 3182436409
      <br />
      <br />
      <strong>Tratamiento-.</strong> Cualquier operación o conjunto de
      operaciones sobre Datos Personales, tales como la recolección,
      almacenamiento, uso, circulación o supresión de los mismos.
      <br />
      <br />
      <strong>Titular-.</strong> Persona natural o jurídica cuyos Datos
      Personales sean objeto de Tratamiento.
      <br />
      <br />
      <strong>Transferencia de Datos-.</strong> La transferencia de datos tiene
      lugar cuando el Responsable y/o Encargado del Tratamiento de datos
      personales, ubicado en Colombia, envía la información o los datos
      personales a un receptor, que a su vez es Responsable del Tratamiento y se
      encuentra dentro o fuera del país.
      <br />
      <br />
      <strong>Tour Operador-.</strong> significa el usuario, persona natural o
      jurídica, que ingresa a la Plataforma de uTriper para ofrecer y anunciar
      planes de Experiencias Turísticas y quien será a la vez el Encargado del
      Tratamiento de los Datos Personales de los Tour Usuarios.
      <br />
      <br />
      <strong>Tour Usuario-.</strong> significa el usuario, persona natural o
      jurídica, que ingresa a la Plataforma de uTriper para visitar la
      información y comprar las Experiencias Turísticas.
      <br />
      <br />
      <strong>Usuario-.</strong> Significa cualquier persona que acceda a la
      Plataforma uTriper, bien sea Tour Usuario o Tour Operador, conforme a lo
      establecido en los Términos y Condiciones de cada uno.
      <br />
      <br />
      <strong>Vinculadas y/o Relacionadas-.</strong> Corresponde a nuestra casa
      matriz, filiales, vinculadas, subsidiarias, aliadas y subordinadas.
      <br />
      <br />
      <strong>
        3. <u>Principios rectores</u>
      </strong>
      <br />
      <br />
      Nuestro compromiso será siempre velar por que cualquier Tratamiento de
      Datos Personales que realizamos, respete los derechos consagrados en
      nuestra Constitución Política y las leyes. Bajo este entendimiento, la
      Política se regirá por la aplicación de los siguientes principios de
      manera armónica e integral:
      <br />
      <br />
      <strong>Principio de finalidad-.</strong> El Tratamiento de Datos
      Personales obedecerá a una finalidad legítima que se informará al Titular.
      <br />
      <br />
      <strong>Principio de libertad-.</strong> El Tratamiento de Datos
      Personales solo puede ejercerse con el consentimiento previo, expreso e
      informado del Titular. Los Datos Personales no serán obtenidos o
      divulgados sin previa autorización, o en ausencia de mandato legal o
      judicial que releve el consentimiento.
      <br />
      <br />
      <strong>Principio de veracidad-.</strong> La información sujeta a
      tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y
      comprensible. Se prohíbe el Tratamiento de Datos Personales parciales,
      incompletos, fraccionados o que induzcan a error.
      <br />
      <br />
      <strong>Principio de seguridad-.</strong> La información sujeta a
      Tratamiento se manejará con las medidas técnicas, humanas y
      administrativas comercialmente razonables, que sean necesarias para
      otorgar seguridad a los registros evitando su adulteración, pérdida,
      consulta, uso o acceso no autorizado o fraudulento.
      <br />
      <br />
      <strong>Principio de Confidencialidad-.</strong> Todas las personas que
      intervengan en el Tratamiento de Datos Personales están obligadas a
      garantizar la reserva de la información, inclusive después de finalizada
      su relación con alguna de las labores que comprende dicho tratamiento.
      <br />
      <br />
      <strong>Principio de Transparencia-.</strong> En el Tratamiento de Datos
      Personales se garantizará el derecho del Titular a obtener del Responsable
      del Tratamiento o del Encargado del Tratamiento, en cualquier momento y
      sin restricciones, información acerca de la existencia de datos que le
      conciernan.
      <br />
      <br />
      <strong>Principio de Acceso y Circulación Restringida-.</strong> El
      tratamiento de datos personales está sujeto a los límites que se derivan
      de la naturaleza de los mismos, de las disposiciones de ley y la
      Constitución. En este sentido, su tratamiento solo podrá hacerse por
      personas autorizadas por el Titular y/o por las personas previstas en ley.
      <br />
      <br />
      <strong>Principio de Confidencialidad-.</strong> Todas las personas que
      intervengan en el Tratamiento de Datos Personales que no tengan la
      naturaleza de Datos Públicos están obligadas a garantizar la reserva de la
      información, inclusive después de finalizada su relación con alguna de las
      labores que comprende el tratamiento, pudiendo solo realizar suministro o
      comunicación de Datos Personales cuando ello corresponda al desarrollo de
      las actividades autorizadas en la ley y en los términos de la misma.
      <br />
      <br />
      <strong>
        4. <u>Tratamiento y Finalidad</u>
      </strong>
      <br />
      <br />
      <strong>uTriper</strong> realizará el Tratamiento de los Datos Personales
      para las finalidades informadas al momento en que el Dato Personal sea
      recolectado y que sea previa y expresamente autorizada por parte del
      Titular. Así mismo, los Encargados o terceros que tengan acceso a los
      Datos Personales por virtud de Ley o contrato, mantendrán el Tratamiento
      dentro de las siguientes finalidades:
      <br />
      <br />
      <strong>4 . 1. Finalidad General</strong>
      <br />
      <br />
      <dl>
        <dd>
          <strong>(a)</strong> Para el envío de información a sus empleados,
          proveedores, terceros y Usuarios de su Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b)</strong> Ejecutar la relación contractual existente con
          sus empleados, proveedores, terceros y Usuarios de su Plataforma,
          incluida el pago de obligaciones contractuales;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c)</strong> Registrar la información de sus empleados,
          proveedores, terceros y Usuarios de su Plataforma y el registro de
          base de datos de cada uno.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(d)</strong> Los indicados en la autorización otorgada por el
          Titular del dato o descritos en el aviso de privacidad respectivo,
          según sea el caso;
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(e)</strong> Envío de información a entidades gubernamentales
          o judiciales por solicitud expresa de la misma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(f)</strong> Soporte en procesos de auditoría externa/interna.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(g)</strong> Con propósitos de seguridad o prevención de
          fraude.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(h)</strong> Gestionar trámites (solicitudes, quejas,
          reclamos).
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(i)</strong> Incluir en sus Bases de Datos y darle Tratamiento
          a la información adquirida en virtud de la relación existente entre
          los Titulares y <strong>uTriper</strong>, cualquiera que sea su
          naturaleza jurídica (laboral, civil, comercial), así como a la
          información suministrada por sus accionistas, vinculadas y/o
          relacionadas.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(j)</strong> Transferir o transmitir los datos de los
          Titulares en caso de fusión, consolidación, cambio en el control
          societario, transferencia de activos, reorganización o liquidación de{' '}
          <strong>uTriper</strong> y/o sus entidades Vinculadas y/o
          Relacionadas, <strong>uTriper</strong> podrá transferir la Información
          Personal de los Titulares a las partes involucradas.
        </dd>
        <dd>
          <br />
          <br />
          <strong>4 . 2. Finalidad Usuarios</strong>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(a)</strong> Facilitar el contacto entre el Tour Operador y el
          Tour Usuario que desee adquirir servicios. <strong>uTriper</strong>{' '}
          podrá suministrar a los interesados sus datos personales (nombre,
          teléfonos, correo electrónico, certificado de existencia y
          representación legal, documento de identidad, dirección y RUT), a
          través de correo electrónico y/o aplicativos móviles y/o página web.
          Dicha información sólo podrá ser utilizadas para fines de concluir con
          éxito la prestación de los servicios adquiridos, para efectos
          tributarios y de facturación.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b)</strong> Mejorar los servicios de <strong>uTriper</strong>{' '}
          a través del desarrollo de estudios de mercado sobre el
          comportamiento, necesidades, gustos e intereses de los Usuarios sobre
          la plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c)</strong> Realizar estudios poblacionales, geográficos,
          demográficos, de comportamiento, hábitos de compra, gustos y
          preferencias de los Usuarios, que permitan desarrollar campañas
          publicitarias, de mercado y análisis estadísticos. Dicha información
          podrá ser comercializada a terceros sin que con ello se incluyan Datos
          Personales ni Datos Sensibles.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(d)</strong> Analizar las páginas visitadas y las búsquedas
          realizadas por los Usuarios, con el objetivo de mejorar la oferta de
          contenidos y artículos y para personalizar los contenidos, su
          presentación y servicios.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(e)</strong> Mejorar de las iniciativas comerciales y
          promocionales de <strong>uTriper</strong>.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(f)</strong> Realizar gestión de estadísticas internas, con el
          objetivo de hacer seguimiento a los servicios ofrecidos.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(g)</strong> Enviar información y/o comunicaciones por
          cualquier medio que hubiese sido brindado por el Titular a{' '}
          <strong>uTriper</strong> (mensajes de texto al teléfono celular, redes
          sociales, correo electrónico entre otros) sobre nuevos servicios,
          cambio en el servicio y tarifas, recordatorios de pago, promociones,
          eventos e información de interés para los Usuarios.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(h)</strong> Compartir los Datos Personales con proveedores de
          medios de pago, servicios turísticos, seguros o intermediarios en la
          gestión de pagos, call-centers o programas de fidelidad, entre otros,
          y en general terceros para el cumplimiento de las obligaciones de
          uTriper o de los Usuarios y de los servicios ofrecidos por o a través
          de la Plataforma. <strong>uTriper</strong> no se hace responsable por
          el uso indebido que estas personas o entidades puedan darle a la
          información.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(i)</strong> Transferencia o Transmisión de Datos Personales
          nacional o internacionalmente a terceros, como por ejemplo prestadores
          de servicios turísticos, con los que <strong>uTriper</strong>{' '}
          desarrolle actividades en cumplimiento de su objeto social. Asimismo,
          se podrá hacer Transmisión o Transferencia a los aliados estratégicos
          de la empresa para que ejecuten actividades de marketing, publicidad y
          promociones asociadas con el objeto social; todo de acuerdo con las
          disposiciones de la normativa colombiana
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(j)</strong> Suministrar los Datos Personales de los Usuarios
          a las entidades que intervengan en la resolución de disputas entre los
          mismos, tales como: Compañías de Seguros, Amigables Componedores o
          Tribunales de Arbitraje o tribunales competentes para solucionar tales
          disputas, entidades del Estado.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(k)</strong> Mejorar la experiencia de Usuario al momento de
          efectuar transacciones a través de la Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(l)</strong> Prestar los Servicios ofrecidos por{' '}
          <strong>uTriper</strong> de una manera adecuada.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(m)</strong> Darle Tratamiento a la información en medios
          físicos y digitales, asegurando que estén correctamente utilizados por
          parte de <strong>uTriper</strong>.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(n)</strong> Contactar y/o enviar información, por cualquier
          medio, sobre servicios que <strong>uTriper</strong> considere pueden
          ser de interés por parte del Titular. Así como envío de cualquier otro
          tipo de comunicaciones y actividades relacionadas con el objeto social
          de <strong>uTriper</strong>.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(o)</strong> Facilitar el contacto entre el Tour Operador y el
          Tour Usuario que desee adquirir los servicios ofertados por el Tour
          Operador. <strong>uTriper</strong>, como portal de contacto, tiene la
          obligación de recopilar la información que permita la plena
          identificación del Tour Operador (nombre, razón social, documento de
          identificación, dirección física de notificación, teléfonos, entre
          otros).
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(p)</strong> Entregar información del Tour Usuario al Tour
          Operador, para el cumplimiento de la relación contractual que entre
          estos se convenga. En cualquier caso, el Tour Operador será el
          Encargado de la información que le entregue <strong>uTriper</strong> y
          por consiguiente responderá por las finalidades de uso que el Tour
          Usuario les otorgue.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(q)</strong> La información suministrada por el Usuario será
          utilizada por <strong>uTriper</strong> para efectos de facturación (en
          caso de aplicar) y demás efectos tributarios, en este caso será
          compartida con las entidades del Estado encargadas de desarrollar
          dicha labor.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(r)</strong> Contactar al Titular en el evento que se genere
          algún tipo de problema o inconveniente con los Datos Personales.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(s)</strong> Enviar comunicaciones, por cualquier medio, en
          relación con el estado de los servicios adquiridos a través de la
          Plataforma.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(t)</strong> Manejo de la facturación y recaudo de cartera.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(u)</strong> Envío, recepción de mensajes de texto, correos
          electrónicos y/o otros con fines comunicativos, publicitarios y/o de
          atención al cliente, con el fin de mantener un contacto personalizado
          con los Usuarios.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(v)</strong> Acceder a los Datos Personales contenidos en la
          base de datos de terceros operadores de información para: (a) llevar a
          cabo la elaboración de puntajes (scores) crediticios, herramientas de
          validación de ingresos, herramientas predictivas de ingresos,
          herramientas para evitar el fraude, la suplantación y en general,
          adelantar una adecuada gestión del riesgo crediticio y (b) compararla,
          contrastarla y complementarla con la información financiera,
          comercial, crediticio, de servicios y obrante en centrales de
          información crediticia y/o operadores de bases de datos de información
          financiera, comercial, crediticia, entre otros.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(w)</strong> Consultar los Datos Personales en las centrales
          de información para conocer el desempeño como deudor, capacidad de
          pago o para valorar el riesgo futuro de conceder un crédito.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(x)</strong> Reportar a las centrales de información, sobre el
          cumplimiento o incumplimiento de las obligaciones por concepto de la
          prestación del servicio o por cualquier otra obligación que haya
          adquirido con <strong>uTriper</strong>, o quien este designe.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            4 . 3. Finalidad del Tratamiento de Datos Personales del Equipo de
            uTriper
          </strong>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(a)</strong> Analizar los Datos Personales del equipo de{' '}
          <strong>uTriper</strong>, sus accionistas, Vinculadas y/o Relacionadas
          y terceros contratados para el desarrollo y promoción de la venta de
          sus servicios y para cumplir con su relación contractual.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b)</strong> Para fines pertinentes a la relación laboral
          (EPS, ARL, fondos de pensiones y cesantías, cajas de compensación
          familiar, etc.)
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c)</strong> Contabilización y pago de nómina y honorarios.{' '}
          <strong>uTriper</strong> podrá tercerizar el pago de nómina y/o de los
          honorarios correspondientes a sus contratistas, para el efecto podrá
          transferir la información de los Titulares a terceros.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(d)</strong> Reclutar y seleccionar personal que ocuparán las
          vacantes.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(e)</strong> Procesar, confirmar y cumplir con las
          obligaciones laborales legales y extralegales derivadas del contrato
          laboral.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(f)</strong> Realizar transacciones.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(g)</strong> Pago de beneficios extralegales.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(h)</strong> Análisis estadísticos
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(i)</strong> Mantener base de datos de candidatos
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(j)</strong> Capacitación y formación de los empleados y de
          los contratistas independientes.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(k)</strong> Compartir los datos personales con entidades
          bancarias, empresas que ofrezcan beneficios a nuestros trabajadores
          activos, contratistas, entre otros.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(l)</strong> Desarrollar el proceso de selección, evaluación,
          y vinculación laboral y de prestación de servicios.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(m)</strong> Ofrecer programas de bienestar corporativo y
          planificar actividades empresariales, para el Titular y sus
          beneficiarios (hijos, cónyuge, compañero permanente).
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            4 . 4. Finalidad del Tratamiento de Datos Personales de Proveedores
          </strong>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(a)</strong> Para fines comerciales y de prestación de
          servicios
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b)</strong> Contabilización e informes
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c)</strong> Disposiciones legales y fiscales
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(d)</strong> Transferencias de fondos
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(e)</strong> Facturación
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(f)</strong> Convenios
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(g)</strong> Contrataciones
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            5.{' '}
            <u>Finalidad del Tratamiento de Datos Personales de Proveedores</u>
          </strong>
          <br />
          <br />
          Los Titulares de los Datos personales tendrán derecho a:
        </dd>
        <dd>
          <strong>(a) Derecho de acceso-.</strong> Solicitar, conocer,
          actualizar y rectificar sus Datos Personales que hayan sido objeto de
          Tratamiento, de manera gratuita.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(b) Derecho de Actualización-.</strong> Conocer, actualizar y
          rectificar sus Datos Personales frente a la Firma o los Encargados del
          Tratamiento de los mismos. Este derecho se podrá ejercer frente a
          Datos Personales parciales, inexactos, incompletos, fraccionados, que
          induzcan a error, o aquellos cuyo Tratamiento esté expresamente
          prohibido o no haya sido autorizado.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(c) Derecho de Prueba-.</strong> Solicitar prueba de la
          existencia de la autorización otorgada a uTriper, salvo cuando
          expresamente se exceptúe como requisito para el Tratamiento, de
          conformidad con lo establecido en el artículo 10 de la Ley 1581 de
          2012 (o en las normas que la reglamenten, adicionen, complementen,
          modifiquen o deroguen).
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(d) Derecho de Información-.</strong> Presentar solicitud
          correspondiente a <strong>uTriper</strong> o al Encargado del
          Tratamiento sobre la información respecto al uso que se le ha dado a
          su Datos Personales.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(e) Derecho de quejas y reclamos-.</strong> El derecho a
          presentar ante la Superintendencia de Industria y Comercio quejas por
          infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás normas
          que la modifiquen, después de haber agotado el trámite de consulta o
          reclamo establecido en la cláusula 9 de la presente Política.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(f) Derecho de revocación-.</strong> Revocar la autorización
          y/o solicitar la supresión de sus Datos Personales de las Bases de
          Datos, cuando la Superintendencia de Industria y Comercio haya
          determinado que uTriper no le dio a los Datos Personales un uso
          conforme a los usos y finalidades autorizados y siempre que no exista
          un deber legal o contractual que impida eliminarlos.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(g) Derecho de conocimiento-.</strong> Conocer las
          modificaciones a los términos de esta Política de manera previa a la
          implementación de las nuevas modificaciones o, en su defecto, de la
          nueva política de tratamiento de la información.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(h) Derecho de supresión-.</strong> Solicitar la supresión de
          sus Datos Personales de las Bases de Datos siempre y cuando no exista
          un deber legal o una obligación de carácter contractual en virtud de
          la cual no sea posible dicha supresión.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            6. <u>Deberes Responsables y Encargados</u>
          </strong>
          <br />
          <br />
          <strong>6 . 1. Deberes del Responsable del Tratamiento-.</strong>{' '}
          Serán deberes del Responsable del Tratamiento de Datos Personales, los
          siguientes:
          <br />
          <br />
          <ul>
            <strong>(a)</strong>Solicitar y conservar, en las condiciones
            previstas en la Política de Tratamiento de Datos Personales de
            uTriper, copia de la respectiva autorización otorgada por el
            Titular.
            <br />
            <br />
            <strong>(b)</strong>Informar de manera clara y suficiente al Titular
            sobre la finalidad de la recolección y los derechos que le asisten
            por virtud de la Autorización otorgada.
            <br />
            <br />
            <strong>(c)</strong>Informar a solicitud del Titular sobre el uso
            dado a sus Datos Personales.
            <br />
            <br />
            <strong>(d)</strong>Tramitar las consultas y reclamos formulados en
            los términos señalados en la presente política
            <br />
            <br />
            <strong>(e)</strong>Procurar que los principios de finalidad,
            libertad, veracidad, seguridad, confidencialidad, transparencia,
            acceso y circulación y confidencialidad estén presentes durante todo
            el Tratamiento de los Datos.
            <br />
            <br />
            <strong>(f)</strong>Conservar la información bajo las condiciones de
            seguridad necesarias para impedir su adulteración, pérdida,
            consulta, uso o acceso no autorizado o fraudulento.
            <br />
            <br />
            <strong>(g)</strong>Actualizar la información cuando sea necesario.
            <br />
            <br />
            <strong>(h)</strong>Rectificar los datos personales cuando ello sea
            procedente.
            <br />
            <br />
          </ul>
          <strong>
            6 . 2. Deberes de los Encargados del Tratamiento-.
          </strong>{' '}
          Serán deberes del Encargado del Tratamiento de Datos personales, los
          siguientes:
          <br />
          <br />
        </dd>
        <dd>
          <strong>(a)</strong> Establecer que el Responsable del Tratamiento
          esté autorizado para suministrar los datos personales que tratará como
          Encargado.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(b)</strong> Garantizar al Titular, en todo tiempo, el pleno y
          efectivo ejercicio del derecho de hábeas data.
        </dd>
        <dd>
          <br />
          <br />
        </dd>
        <dd>
          <strong>(c)</strong> Conservar la información bajo las condiciones de
          seguridad necesarias para impedir su adulteración, pérdida, consulta,
          uso o acceso no autorizado o fraudulento.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(d)</strong> Realizar oportunamente la actualización,
          rectificación o supresión de los Datos Personales.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(e)</strong> Actualizar la información reportada por los
          Responsables del tratamiento dentro de los cinco (5) días hábiles
          contados a partir de su recibo.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(f)</strong> Tramitar las consultas y los reclamos formulados
          por los Titulares en los términos señalados en la presente política.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(g)</strong> Registrar en la Base de Datos la leyenda “
          <strong>reclamo en trámite</strong>” en la forma en que se establece
          en el presente Manual.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(h)</strong> Insertar en la Base de Datos la leyenda
          “información en discusión judicial” una vez notificado por parte de la
          autoridad competente sobre procesos judiciales relacionados con la
          calidad del dato personal.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(i)</strong> Abstenerse de circular información que esté
          siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado
          por la Superintendencia de Industria y Comercio.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(j)</strong> Permitir el acceso a la información únicamente a
          las personas autorizadas por el Titular o facultadas por la ley para
          dicho efecto.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(k)</strong> Informar a la Superintendencia de Industria y
          Comercio cuando se presenten violaciones a los códigos de seguridad y
          existan riesgos en la administración de la información de los
          titulares.
        </dd>
        <dd>
          <br />
        </dd>
        <dd>
          <strong>(l)</strong> Cumplir las instrucciones y requerimientos que
          imparta la Superintendencia de Industria y Comercio.
        </dd>
        <dd>
          <br />
          <br />
          <strong>
            7. <u>Formas de recolectar los datos</u>
          </strong>
          <br />
          <br />
          <strong>7 . 1. uTriper</strong> recolecta los Datos Personales de sus
          Usuarios a través del diligenciamiento de parte de estos de los
          formularios dispuestos en la Plataforma y del uso de la misma.
          Respecto a los Tour Operadores, en el momento en que estos realicen el
          proceso de creación de la cuenta en <strong>uTriper</strong>, deberán
          diligenciar el formulario correspondiente a sus Datos Personales para
          poder acceder a su cuenta y crear su perfil. Respecto a los Tour
          Usuarios, <strong>uTriper</strong> recoleta los Datos Personales en el
          momento en el que estos realicen una reserva o compra de algún
          servicio ofertado en la Plataforma.
          <br />
          <br />
          <strong>7 . 2.</strong> En los casos en los que se trate de
          diligenciamiento de formularios, los Datos Personales que{' '}
          <strong>uTriper</strong> podrá solicitar del Titular, incluye, pero no
          está limitado a los siguientes:
          <br />
          <ul>
            <strong>(a)</strong>Nombre completo del Titular de la información
            <br />
            <br />
            <strong>(b)</strong>Identificación o documento de identidad.
            <br />
            <br />
            <strong>(c)</strong>Fecha de nacimiento.
            <br />
            <br />
            <strong>(d)</strong>Nacionalidad.
            <br />
            <br />
            <strong>(e)</strong>Domicilio.
            <br />
            <br />
            <strong>(f)</strong>Dirección de contacto.
            <br />
            <br />
            <strong>(g)</strong>Teléfonos de contacto.
            <br />
            <br />
            <strong>(h)</strong>Correo electrónico.
            <br />
            <br />
            <strong>(i)</strong>Cuentas bancarias.
            <br />
            <br />
            <strong>(j)</strong>Información financiera.
            <br />
            <br />
            <strong>(k)</strong>Información Profesional.
            <br />
            <br />
            <strong>(l)</strong>Información Tributaria.
            <br />
            <br />
            <strong>(m)</strong>Información Laboral
            <br />
            <br />
            <strong>(n)</strong>Información de referencias bancarias y/o
            comerciales.
            <br />
            <br />
            <strong>(o)</strong>Certificado de Existencia y Representación
            Legal.
            <br />
            <br />
            <strong>(p)</strong>Registro de Turismo Nacional.
            <br />
            <br />
          </ul>
          <strong>
            8. <u>Autorización y consentimiento del titular</u>
          </strong>
          <br />
          <br />
          <strong>uTriper</strong> debe solicitar autorización previa, expresa e
          informada a los Titulares de los Datos Personales sobre los que
          requiera realizar el Tratamiento.
          <br />
          <br />
          <strong>8 . 1. Autorización previa-.</strong> significa, que el
          consentimiento debe ser otorgado por el Titular, a más tardar en el
          momento de la recolección de los Datos Personales.
          <br />
          <br />
          <strong>8 . 2. Autorización expresa-.</strong> quiere decir que el
          consentimiento del Titular debe ser explícito y concreto, no son
          válidas las autorizaciones abiertas y no específicas. Se requiere que
          el Titular manifieste su voluntad de autorizar que{' '}
          <strong>uTriper</strong> realice el Tratamiento de sus Datos
          Personales.
          <br />
          <br />
          Esta manifestación de voluntad del Titular puede darse a través de
          diferentes mecanismos puestos a disposición por{' '}
          <strong>uTriper</strong>, tales como:
          <br />
          <br />
          <strong>(a)</strong> Por escrito
          <br />
          <br />
          <strong>(b)</strong> De forma oral, por ejemplo, en una conversación
          telefónica o en videoconferencia.
          <br />
          <br />
          <strong>(c)</strong> Mediante conductas inequívocas que permitan
          concluir que otorgó su autorización, por ejemplo, a través de su
          aceptación expresa a los Términos y Condiciones de una actividad
          dentro de los cuales se requiera la autorización de los participantes
          para el Tratamiento de sus Datos Personales. Sin perjuicio de lo
          anterior, en ningún caso el silencio del Titular se asimilará a una
          conducta inequívoca.
          <br />
          <br />
          <strong>8 . 3. Autorización Informada-.</strong> Significa que, al
          momento de solicitar el consentimiento al Titular, debe informársele
          claramente:
          <br />
          <br />
          <strong>(a)</strong> Los Datos Personales que serán recolectados.
          <br />
          <br />
          <strong>(b)</strong> La identificación y datos de contacto del
          Responsable y del Encargado del Tratamiento.
          <br />
          <br />
          <strong>(c)</strong> Las finalidades específicas del Tratamiento que
          se pretende realizar, es decir: cómo y para qué se va a hacer la
          recolección, el uso, la circulación de los Datos Personales.
          <br />
          <br />
          <strong>(d)</strong> Cuáles son los derechos que tiene como Titular de
          los Datos Personales.
          <br />
          <br />
          <strong>(e)</strong> CEl carácter facultativo de la respuesta a las
          preguntas que le sean hechas, cuando éstas versen sobre datos
          sensibles o sobre los datos de niñas, niños y adolescentes.
          <br />
          <br />
          <strong>
            8 . 4. Eventos en los cuales no es necesaria la autorización del
            titular de los datos personales-.
          </strong>{' '}
          La autorización del titular de la información no será necesaria cuando
          se trate de:
          <br />
          <br />
          <strong>(a)</strong> Información requerida por una entidad pública o
          administrativa en ejercicio de sus funciones legales o por orden
          judicial.
          <br />
          <br />
          <strong>(b)</strong> Datos de naturaleza pública (Datos Públicos).
          <br />
          <br />
          <strong>(c)</strong> Casos de urgencia médica o sanitaria.
          <br />
          <br />
          <strong>(d)</strong> Tratamiento de información autorizado por la ley
          para fines históricos, estadísticos o científicos.
          <br />
          <br />
          <strong>(e)</strong> Datos relacionados con el Registro Civil de las
          personas.
          <br />
          <br />
          <strong>8 . 5.</strong> En el evento de una venta, fusión,
          consolidación, cambio en el control societario, transferencia de
          activos, reorganización o liquidación de <strong>uTriper</strong> y/o
          sus entidades Vinculadas y/o Relacionadas, <strong>uTriper</strong>{' '}
          podrá transferir la Información Personal de los Titulares a las partes
          involucradas, para lo cual por medio de la aceptación del presente
          documento <strong>uTriper</strong> se entiende queda facultada para
          realizarlo.
          <br />
          <br />
          <strong>
            9. <u>Procedimiento de Consulta, Ratificación y Reclamos</u> 9 . 1.
            Consulta-.
          </strong>{' '}
          Las consultas y solicitudes de los Titulares serán atendidas en un
          término máximo de diez (10) días hábiles, contados a partir de la
          fecha de recibo de las mismas. En caso de que no sea posible resolver
          la consulta dentro de este término, el Titular será informado de dicha
          situación en la dirección de notificación que haya incluido en la
          respectiva consulta, y el término de respuesta se podrá extender hasta
          por cinco (5) días hábiles adicionales. La respuesta a las consultas o
          reclamos que los Titulares presenten podrán ser entregadas por
          cualquier medio físico o electrónico.
          <br />
          <br />
          <strong>9 . 2. Rectificaciones y Reclamos-.</strong> Cuando el Titular
          de la Información advierta un presunto incumplimiento por parte de{' '}
          <strong>uTriper</strong> de sus deberes en materia de Protección de
          Datos Personales y deseen ejercer sus derechos contenidos en la
          legislación aplicable y en la presente Política, podrán presentar un
          reclamo ateniéndose a los siguientes requisitos y procedimiento:{' '}
          <strong>(a)</strong> . Para la radicación y atención de su
          Rectificación o Reclamo, se deberá presentar por solicitud por escrito
          la cual debe contener la siguiente información:
          <ul>
            <ul>
              i. Nombre completo y apellido
              <br />
              <br />
              ii. Datos de contacto (Dirección física y/o electrónica y
              teléfonos de contacto).
              <br />
              <br />
              iii. Medios para recibir respuesta a su solicitud,
              <br />
              <br />
              iv. Motivo(s)/hecho(s) que dan lugar al reclamo con una breve
              descripción del derecho que desea ejercer (conocer, actualizar,
              rectificar, solicitar prueba de la autorización otorgada,
              revocarla, suprimir, acceder a la información).
              <br />
              <br />
              v. Firma (si aplica).
              <br />
              <br />
              vi. Número de identificación.
              <br />
              <br />
              vii. Copia de los documentos que se requiera hacer valer, en caso
              de aplicar.
              <br />
              <br />
            </ul>
          </ul>
          La radicación de solicitud se formulará mediante comunicación dirigida
          al área Administrativa con la identificación del titular, la
          descripción de los hechos que dan lugar a la solicitud, la dirección y
          acompañado de los documentos que soporten la solicitud de ser
          aplicable.
          <br />
          <br />
          <strong>(b)</strong> Si la solicitud resulta incompleta,{' '}
          <strong>uTriper</strong> requerirá al interesado dentro de los cinco
          (5) días siguientes a la recepción de la solicitud para que complete y
          subsanase su petición. Transcurridos dos (2) meses desde la fecha del
          requerimiento, sin que el solicitante presente la información
          requerida, se entenderá que ha desistido de la Rectificación y/o
          Reclamo.
          <br />
          <br />
          <strong>(c)</strong> Una vez recibido la solicitud completa, se
          incluirá en la Base de Datos una leyenda que diga “
          <strong>reclamo en trámite</strong>” y el motivo del mismo, actividad
          que deberá realizarse en un término no superior a dos (2) días
          hábiles. Dicha leyenda deberá mantenerse hasta que la solicitud sea
          decidida.
          <br />
          <br />
          <strong>(d)</strong> En el caso de quien reciba la solicitud no sea
          competente para resolverlo, se procederá a un traslado a quien sí lo
          sea para que resuelva en un término máximo de dos (2) días hábiles
          contados a partir de la fecha en la que confirma recibida la
          solicitud, y le deberá notificar al solicitado de tal hecho.
          <br />
          <br />
          <strong>(e) uTriper</strong> resolverá la solicitud en un término
          máximo de quince (15) días hábiles contados a partir del día
          siguientes de recibo del mismo. En caso de que no sea posible resolver
          la consulta dentro de este término, el solicitante será informado de
          la demora, los motivos y la fecha de respuesta en la dirección de
          notificación que haya incluido en el respectivo reclamo. En todo caso,
          el término de respuesta no podrá superar de ocho (8) días hábiles
          siguientes al vencimiento del primer término. La respuesta a los
          reclamos que los Titulares presenten podrá ser efectuadas por
          cualquier medio físico o electrónico.
          <br />
          <br />
          <strong>9 . 3. uTriper</strong> ha designado a [_]. para la recepción
          y atención de quejas y reclamos. Así mismo, lo ha designado como
          responsable de la protección de sus datos, en el evento de alguna duda
          o inquietud sobre la presente Política de Privacidad o el Tratamiento
          y uso de los Datos Personales favor dirigir sus consultas, peticiones
          quejas o reclamos a:
          <ul>
            Nombre: Salvador Dell Acqua Dirección: Cra 7 #77-07 Correo
            Electrónico: salvador@utriper.com Página Web: www.utriper.com
            Teléfono: + 57 3182436409
          </ul>
          <br />
          <br />
          <strong>
            10. <u>Datos Sensibles</u>
          </strong>
          <br />
          <br />
          De acuerdo con las normas aplicables y en concordancia con la
          definición brindada en la sección de Definiciones, se consideran como
          datos de naturaleza sensible aquellos que afectan la intimidad o cuyo
          uso indebido puede generar discriminación.
          <br />
          <br />
          El Tratamiento de los Datos Personales de naturaleza sensible está
          prohibido por la ley, salvo que se cuente con autorización expresa,
          previa e informada del Titular. En este caso, además de cumplir con
          los requisitos establecidos para la autorización{' '}
          <strong>uTriper</strong> deberá:
          <br />
          <br />
          <strong>(a)</strong> Informar al Titular que por tratarse de datos
          sensibles no está obligado a autorizar su Tratamiento.
          <br />
          <br />
          <strong>(b)</strong> Informar al Titular cuáles de los datos que serán
          objeto de Tratamiento son sensibles y la finalidad del Tratamiento.
          <br />
          <br />
          <strong>(c)</strong> Informar que ninguna actividad está condicionada
          a que el Titular suministre Datos Personales sensibles.
          <br />
          <br />
          <strong>
            11. <u>Transmisión nacional e internacional</u>
            <br />
            <br />
            uTriper
          </strong>{' '}
          podrá trabajar con terceros proveedores para facilitar los servicios
          que ofrece a sus Usuarios así como para desarrollar actividades en
          cumplimiento de su objeto social. <strong>uTriper</strong>
          <br />
          <strong>
            12. <u>Confidencialidad de los Datos Personales</u>
          </strong>
          <br />
          <br />
          La información correspondiente a los Datos Personal suministrada por
          los Titulares será utilizada únicamente por <strong>uTriper</strong>,
          sus accionistas, Vinculadas y/o Relacionadas y los terceros
          autorizados para tales fines, conforme a lo establecido en la presente
          Política de Privacidad. Los Datos Personales no serán destinados, a
          propósitos distintos de aquellos para los cuales fueron suministrados.{' '}
          <strong>uTriper</strong> procurará proteger la privacidad de los Datos
          Personales y conservarla bajo las condiciones de seguridad necesarias
          para impedir su adulteración, pérdida, consulta, uso o acceso no
          autorizado o fraudulento, así como el respeto de los derechos de los
          Titulares de la misma.
          <br />
          <br />
          Si por cualquier circunstancia una autoridad competente solicita sea
          revelada la información correspondiente a los Datos Personales, que se
          encuentre en poder de <strong>uTriper</strong>, y en consecuencia, sea
          su obligación legal suministrar la misma, <strong>uTriper</strong>{' '}
          procederá a entregar dichos Datos, situación que los Titulares aceptan
          y autorizan a <strong>uTriper</strong> para este efecto, en todo caso
          el titular de dicha información será informado.
          <br />
          <br />
          <strong>
            13. <u>Seguridad de los Datos</u>
          </strong>
          <br />
          <br />
          <strong>uTriper</strong> dispone de medidas de seguridad para proteger
          la información personal recibida, dichas medidas se encuentran
          ajustadas para cumplir con la normatividad aplicable.{' '}
          <strong>uTriper</strong> hace uso de los estándares de la industria
          para proteger la información personal, dentro de los cuales ha
          implementado el uso de cortafuegos ("firewalls") y Secure Socket
          Layers ("SSL"), entre otros.
          <br />
          <br />
          Teniendo en cuenta que los servicios prestados por{' '}
          <strong>uTriper</strong> se desarrollan a través de internet y que del
          mismo modo la información personal es recolectada, pueden existir
          interceptaciones ilegales o violación a los sistemas y bases de datos
          por parte de personas inescrupulosas o no autorizadas. En este evento{' '}
          <strong>uTriper</strong> no se responsabiliza por la indebida
          utilización de la información obtenida por esos medios.
          <br />
          <br />
          <strong>
            14. <u>Tratamiento de Datos Personales de Menores de Edad</u>
          </strong>
          <br />
          <br />
          En aplicación de lo establecido por el Artículo 7o de la Ley 1581 de
          2012 y el artículo 12 del Decreto 1377 de 2013, en el evento en el
          cual <strong>uTriper</strong> proceda a efectuar el Tratamiento de
          Datos Personales de niños, niñas y adolescentes, lo hará respetando el
          interés superior de los mismos y asegurando, en todos los casos, el
          respeto de sus derechos fundamentales y garantías mínimas.
          <br />
          <br />
          Dicho tratamiento únicamente tendrá lugar en la medida en que{' '}
          <strong>uTriper</strong> así lo requiera. En todos los eventos en los
          que se requiera darle Tratamiento a los Datos Personales de menores de
          edad, <strong>uTriper</strong> obtendrá la autorización
          correspondiente por parte del representante legal del niño, niña o
          adolescente; previo ejercicio del menor de su derecho a ser escuchado,
          opinión que será valorada teniendo en cuenta la madurez, autonomía y
          capacidad del menor para entender el contenido de la autorización y
          del Tratamiento de sus Datos Personales.
          <br />
          <br />
          <strong>
            15. <u>Tratamiento de Datos Personales de Menores de Edad</u>
          </strong>
          <br />
          <br />
          En <strong>uTriper</strong> usamos cookies y tecnologías similares
          para personalizar y mejorar la experiencia de nuestros clientes, así
          como para mostrarle publicidad online relevante. Las cookies son
          pequeños archivos de texto que contienen un identificador único que se
          almacena en el computador o aparato móvil a través del cual usted
          accede a la página web y/o aplicativos móviles, de manera que aquellos
          pueden ser reconocidos cada vez que usted utilice la página web y/o
          aplicativos móviles. El Titular de los Datos Personales puede elegir
          deshabilitar en cualquier momento algunas o todas las cookies que
          utilizamos. Sin embargo, esto podría restringir su uso de los Sitios y
          limitar su experiencia en el mismo. El uso de cookies no contiene ni
          afecta Datos Personales y no representa peligro de virus.{' '}
          <strong>
            16. <u>Modificación y/o Actualización de la Política</u>
          </strong>
          <br />
          <br />
          Cualquier cambio sustancial en la Política de Tratamiento, se
          comunicará de forma oportuna a los Titulares de los datos a través del
          correo electrónico que hubiesen registrado en la Plataforma y/o a
          través de la Plataforma.
          <br />
          <br />
          <strong>
            17. <u>Vigencia .-</u>
          </strong>{' '}
          La presente Política para el Tratamiento de Datos Personales rige a
          partir del 1 de febrero de 2022.
          <br />
          <br />
          Las bases de datos en las que se registrarán los datos personales
          tendrán una vigencia igual al tiempo en que se mantenga y utilice la
          información para las finalidades descritas en esta política. Una vez
          se cumplan esas finalidades y siempre que no exista un deber legal o
          contractual de conservar su información, sus datos serán eliminados de
          nuestras bases de datos.{' '}
        </dd>
      </dl>
    </div>
  );
};
