import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import ExperienceCard from "./components/ExperienceCard";

const ExperiencesList = ({
  code,
  contractExperiences,
  useTranslation,
  experienceService,
  ...props
}) => {
  // const mdSize = contractExperiences?.length === 1 ? 6 : 3;

  return (
    <Grid container justifyContent="center" spacing={2}>
      {contractExperiences?.map((experience) => (
        <Grid item key={experience.id} xs={12} sm={6} md={4} lg={3}>
          <ExperienceCard
            experience={experience}
            experienceContract={code}
            useTranslation={useTranslation}
            experienceService={experienceService}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ExperiencesList;
