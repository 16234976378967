import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    custom: {
        border: 'none',
        boxShadow: 'none',
        padding: '0px',
        paddingBottom: '0px',
        borderRadius: '25px',
        height: 220,
        width: '100%',
        objectFit: 'cover',
        '&:hover': {
            transform: 'scale(1.03)',
            transition: 'transform 0.3s',
        },
    },
    cardAction: {
        display: 'block',
    },
    // by default applies padding to last child in group so we forcefully remove it to not have a white border
    cardContent: {
        padding: '0px',
        paddingBottom: '0px',
        height: 220,
        width: '100%',
    },
    cardMedia: {
        borderRadius: '25px',
        height: 220,
        width: '100%',
    },
    cardText: {
        textAlign: 'center',
        textShadow: '0px 0px 10px rgba(0, 0, 0, 0.8)',
        color: '#FFFFFF',
        fontSize: 42,
        letterSpacing: '-1px',
        fontWeight: 600,
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        margin: 'auto',
        transform: 'translateY(-50%)',
    },
}));
