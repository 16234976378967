import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    text: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            width: "85%",
        },
        [theme.breakpoints.up('md')]: {
            width: "55%",
        },
        [theme.breakpoints.up('lg')]: {
            width: "45%",
        },
    }
}));
