import i18n from 'i18next';
import esLocale from 'react-phone-input-2/lang/es.json';

const get = () => {
  switch (i18n.language) {
    default:
    case 'es':
      return esLocale;
    case 'en':
      return undefined; // default, english locale
  }
};

export default get;
