import Link from "./Links";
import { useTranslation } from 'core/contexts/localization';

const UsersSection = () => {

    const { t: translate } = useTranslation();

    return (
        <div style={{ textAlign: 'left', marginInline: "auto", width: "50%" }}>
            <p style={{ fontSize: "1rem" }}>
                <strong>
                    {translate("footer.users.title")}
                </strong>
            </p>
            <Link onClick={() => window.open("https://www.utriper.com", "_blank")}>
                {translate("footer.users.triper")}
            </Link>
            <br />
            <Link onClick={() => window.open("https://www.utriper.com", "_blank")}>
                {translate("footer.users.tourOperator")}
            </Link>
            <br />
            <Link onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSeUvaIZvjASf_Vbhp9vHfEFFDoMyKU58CPEMyh2b2nzlIBgrA/viewform", "_blank")}>
                {translate("footer.users.lodgment")}
            </Link>
        </div>
    );
}

export default UsersSection;