import React, { useContext, useState, useEffect } from 'react';

import { AuthService } from 'core/api/services/auth';
import { setSession, getSession } from 'core/api/session';
import { removeSession } from '../api/session';

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const session = getSession();
    if (!session || session == null) {
      setToken();
    }
  }, []);

  const getToken = () => {
    return getSession();
  };

  const setToken = async () => {
    try {
      setLoading(true);
      const token = await AuthService.getToken();
      console.log("token ===> ", token);
      if (!token) {
        throw new Error();
      }

      setSession(token);
      setLoading(false);

      return true;
    } catch (err) {
      setLoading(false);
      alert(
        'Ha ocurrido un error al intentar generar el token.\nPor favor, intente nuevamente.'
      );
      console.error(err);
      return false;
    }
  };



  return (
    <AuthContext.Provider
      value={{
        loading,
        setToken,
        getToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.displayName = 'AuthProvider';
