import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DialogCore = ({
  isOpen,
  onClose,
  title,
  content,
  actions
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions ? actions : (
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogCore;