import React, { useState, useEffect } from 'react';
import AutocompleteMUI from '@material-ui/lab/Autocomplete';
import { FormControl, TextField } from '@material-ui/core';

export const Autocomplete = (props) => {
  const {
    label,
    options,
    className,
    getOptionLabels,
    onFieldChange,
    errorMessage,
    meta: { touched },
    initialError,
  } = props;

  const [hasError, setHasError] = useState(initialError);
  const [value, setValue] = useState(undefined);

  useEffect(() => setHasError(initialError), [initialError]);

  // TODO: eliminar el style
  return (
    <FormControl style={{width: '100%'}}>
      <AutocompleteMUI
        limitTags={2}
        options={options}
        id="autocomplete"
        className={className}
        getOptionLabel={getOptionLabels}
        onChange={(event) => {
          setHasError((prevValue) => {
            const retValue = !props.validate(event.target.textContent);
            props.setFormError(retValue);
            return retValue;
          });
          setValue(event.target.textContent);
          onFieldChange(event);
        }}
        onClose={(event) => {
          if (event.target.textContent.length === 0) {
            setHasError(true);
          }
        }}
        renderInput={(params) => (
          <TextField
            error={hasError || (!value && touched)}
            helperText={(hasError || (!value && touched)) && errorMessage}
            variant="outlined"
            {...params}
            label={label}
          />
        )}
      />
    </FormControl>
  );
};
