import { isEmpty } from 'lodash';
import { fetch, Method } from 'core/api/fetch';
import { parseTripToModel } from 'core/api/parser/trip';
import { parseExperienceMediaModel, parseExperienceToModel, parseQrDataToModel } from '../parser/experience';
import { parsePaggingExperiencesToModel } from "../parser/pagging";
import { Order } from "core/contexts/sort";
import experiences from 'components/ExperienceModal/data';

const experienceRoutes = {
  getById: (id) => `/api/v1.1.0/experience/${id}`,
  getAll: (filtersQueryParams) => `/api/v1.1.1/experience${(filtersQueryParams || filtersQueryParams.length > 0) ? `?${filtersQueryParams}` : ""}`,
  getByHostel: (hostelCode, priceOrder, modusesFilter, tourOperatorsId) => `/api/v1.1.1/experience?code=${hostelCode}&price-order=${priceOrder == Order.ASCENDANT ? "ASC" : "DESC"}${(modusesFilter || modusesFilter.length > 0) ? `&${modusesFilter}` : ""}${(tourOperatorsId || tourOperatorsId.length > 0) ? `&tos=[${tourOperatorsId}]` : ""}`,
  updateStatus: '/api/v1.1.0/experience',
  getMediaById: (id) => `/api/v1.1.0/experience/${id}`,
};

const buildFiltersQueryParams = ({ locationData = null, radius = null, moduses = null }) => {
  let queryParams = "";
  let addedFirstQueryParam = false;
  if (locationData && radius && locationData.lng && locationData.lat && locationData.name) {
    queryParams += `lng=${locationData.lng}&lat=${locationData.lat}&radius=${radius}`;
    addedFirstQueryParam = true;
  }
  if (moduses && moduses.length > 0) {
    queryParams += `${addedFirstQueryParam ? "&" : ""}moduses=[${moduses.join(",")}]`;
    addedFirstQueryParam = true;
  }
  return queryParams;
};

const ITEMS_PER_ROW = 3;
const ROWS_PER_PAGE = 4;
const DEFAULT_PAGE_SIZE = ITEMS_PER_ROW * ROWS_PER_PAGE;
const buildPaggingQueryParams = (page = 1, pageSize = DEFAULT_PAGE_SIZE) => `&page=${page}&pageSize=${pageSize}`;

export const ExperienceService = {
  get: async (filters, page) => {
    try {
      const filtersQueryParams = buildFiltersQueryParams(filters);
      console.log("filters", filters, filtersQueryParams)
      const { data } = await fetch(experienceRoutes.getAll(filtersQueryParams) + buildPaggingQueryParams(page), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log("RESPONSE ==== > ", data)

      // return parsePaggingExperiencesToModel(data);

      return isEmpty(data) ? {hasMorePages: false, experiences: []} : { hasMorePages: data.hasMorePages, experiences: data.experiences.map(parseExperienceToModel) };
    } catch (err) {
      throw new Error(err);
    }
  },
  getByHostel: async (hostelCode, priceOrder, modusesFilter, tourOperatorsId) => {
    try {
      const filtersQueryParams = buildFiltersQueryParams({ moduses: modusesFilter });
      const { data } = await fetch(experienceRoutes.getByHostel(hostelCode, priceOrder, filtersQueryParams, tourOperatorsId), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log((!data))
      return (!data || isEmpty(data)) ? [] : parseQrDataToModel(data);
    } catch (err) {
      throw new Error(err);
    }
  },
  updateStatus: async (expId, newData) => {
    try {
      console.log(
        expId,
        '<-- exp id',
        newData,
        "<--- ON 'updateStatus' POST SERVICE"
      );
      const { data } = await fetch(experienceRoutes.updateStatus, {
        method: Method.PATCH,
        authenticated: true,
        data: newData,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return isEmpty(data) ? {} : data;
    } catch (err) {
      throw new Error(err);
    }
  },
  getById: async (id) => {
    try {
      const { data } = await fetch(experienceRoutes.getById(id), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return isEmpty(data) ? [] : parseExperienceToModel(data[0]);
    } catch (err) {
      throw new Error(err);
    }

  },
  getMediaById: async (id) => {
    try {
      const { data } = await fetch(experienceRoutes.getMediaById(id), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const media = [];
      if(isEmpty(data)) {
        return media;
      }
      data[0].media.forEach(item => {
        media.push(parseExperienceMediaModel(item));
      });
      return media;
    } catch (err) {
      throw new Error(err);
    }
  }
};
