const palette = {
  primary: '#5567DD',
  secondary: '#778899',
  success: '#28a745',
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  light: '#f8f9fa',
  lightblue: '#2a81e8',
  dark: '#343a40',
  utriper: {
    orange: '#E39348',
    green: '#A6ba62',
    lightgreen: '#C7DF75',
    darkgreen: '#84944E',
    yellow: '#F2c35c',
    lightblue: '#9bc2d1',
    darkblue: "#5d747d",
    blue: "#a5c8d6"
  },
};

module.exports = palette;
