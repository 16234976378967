import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ROUTES } from './routes';
import { QueryParamProvider } from 'use-query-params';
import LandingViajero from 'modules/Landing';
import LandingHostel from 'modules/Landing';
import ViajeroBooking from 'modules/Calendar';
import ViajeroCheckout from 'modules/Checkout';
import ViajeroCheckin from 'modules/Checkin';
import CheckoutValidation from "modules/Checkout/Validate";
import ViajeroSavedExperiences from 'modules/SavedExperiences';
import HostelTemplate from "modules/Landing/Hostel";
import PurchaseTemplate from "modules/Purchase";
import ErrorTemplate from 'modules/Errors';
import PendingTemplate from 'modules/Pending';

const Router = () => (
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Route path={ROUTES.LANDING} exact>
          <LandingViajero />
        </Route>
        {/* <Route path={ROUTES.LANDINGHOSTEL} exact>
        <LandingHostel />
      </Route> */}

        <Route path={ROUTES.BOOKING_CODE}>
          <ViajeroBooking />
        </Route>

        <Route path={ROUTES.BOOKING}>
          <ViajeroBooking />
        </Route>

        <Route path={ROUTES.CHECKOUT_VALIDATION} exact>
          <CheckoutValidation />
        </Route>

        <Route path={ROUTES.CHECKOUT}>
          <ViajeroCheckout />
        </Route>

        <Route path={ROUTES.CHECKIN}>
          <ViajeroCheckin />
        </Route>

        <Route path={ROUTES.SAVEDEXPERIENCES}>
          <ViajeroSavedExperiences />
        </Route>

        <Route path={ROUTES.QR}>
          <HostelTemplate />
        </Route>

        <Route path={ROUTES.PURCHASE}>
          <PurchaseTemplate goBackUrl="" />
        </Route>

        <Route path={ROUTES.ERROR}>
          <ErrorTemplate goBackUrl="" />
        </Route>

        <Route path={ROUTES.PENDING}>
          <PendingTemplate goBackUrl="" />
        </Route>

      </Switch>
    </QueryParamProvider>
  </BrowserRouter>
);

Router.displayName = 'Router';

export default Router;
