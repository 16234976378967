import { IconButton } from "@material-ui/core";
import { Facebook, LinkedIn, Twitter, YouTube, Instagram } from "@material-ui/icons";

export const Icons = () => {
    return (
        <div>
            <IconButton style={{ color: "black" }} onClick={() => window.open("https://www.facebook.com/people/UTriper/100080151082050/?mibextid=ZbWKwL", "blank")}>
                <Facebook />
            </IconButton>
            <IconButton style={{ color: "black" }} onClick={() => window.open("https://twitter.com/utriperok", "blank")}>
                <Twitter />
            </IconButton>
            <IconButton style={{ color: "black" }} onClick={() => window.open("https://instagram.com/utriperok", "blank")}>
                <Instagram />
            </IconButton>
            <IconButton style={{ color: "black" }} onClick={() => window.open("https://www.linkedin.com/company/utriper/", "blank")}>
                <LinkedIn />
            </IconButton>
            <IconButton style={{ color: "black" }} onClick={() => window.open("https://www.youtube.com/@uTriper/videos", "blank")}>
                <YouTube />
            </IconButton>
        </div>
    );
}