import React, { useContext, useState, useEffect, useCallback } from 'react';

const PaymentContext = React.createContext(null);

export const PaymentProvider = ({ children }) => {
  const [mercadoPago, setMercadoPago] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [checkoutExperiences, setCheckoutExperiences] = useState([]);
  const [paymentType, setPaymentType] = useState(null);

  const initialFormErrors = {
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
    phoneNumberCountryCode: true,
    country: true,
  };
  const initialTriperFormErrors = {
    firstName: true,
    lastName: true,
    email: true,
    dob: true,
    personalId: true,
    country: true,
  };
  

  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [triperFormErrors, setTriperFormErrors] = useState([initialTriperFormErrors]);
  const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = useState(false);
  useEffect(() => { console.log("inside context === ", agreeWithTermsAndConditions) }, [agreeWithTermsAndConditions])

  const canSubmitForm = (checkingFromPayment = false) => {
    const formHasErrors = Object.values(formErrors).some(
      (errorValue) => errorValue
    );
    let triperFormHasErrors = false;
    // triperFormErrors.forEach( triperFormErr => {
    //   let err = Object.values(triperFormErr).some(
    //     (errorValue) => errorValue
    //     )
    //   if(err) { triperFormHasErrors = true; }
    // });
    
    if (!checkingFromPayment) {
      setSubmitted(true);
    }

    if (formHasErrors || triperFormHasErrors || !agreeWithTermsAndConditions) {
      console.log('FORM HAS ERRORS', formHasErrors, agreeWithTermsAndConditions, triperFormHasErrors);
      return false;
    }
    console.log('SUBMIT===', userData);
    return true;
  };

  const userDataInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    phoneNumberCountryCode: '',
    country: '',
    tripers:[],
  };

  const [userData, setUserData] = useState(userDataInitialValues);

  const resetForm = () => {
    setUserData(userDataInitialValues);
    setFormErrors(initialFormErrors);
    // setTriperFormErrors([initialTriperFormErrors]);
    setSubmitted(false);
    setAgreeWithTermsAndConditions(false);
  }

  const setUserEmail = (email) => {
    setUserData({ ...userData, email });
  }

  const getUserEmail = () => {
    console.log("user data === ", userData);
    if (!userData.email) {
      throw new Error('User email is not defined');
    }
    return userData.email;
  }

  return (
    <PaymentContext.Provider
      value={{
        mercadoPago,
        setMercadoPago,
        userData,
        setUserData,
        resetForm,
        canSubmitForm,
        submitted,
        setSubmitted,
        checkoutExperiences,
        setCheckoutExperiences,
        formErrors,
        setFormErrors,
        agreeWithTermsAndConditions,
        setAgreeWithTermsAndConditions,
        getUserEmail,
        setUserEmail,
        paymentType,
        setPaymentType,
        triperFormErrors,
        setTriperFormErrors,
      }}>
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.displayName = 'PaymentProvider';

export const usePayment = () => useContext(PaymentContext);
