import React from 'react';
import {
  UsersSection,
  PersonalDataAuthorizationSection
} from '../ContractSections';

const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <UsersSection style={{ textAlign: 'justify' }} />
      <PersonalDataAuthorizationSection style={{ textAlign: 'justify' }} />
    </React.Fragment>
  );
};

export default TermsAndConditions;
