import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';

import { useTranslation } from 'core/contexts/localization';
import { useExperiences } from 'core/contexts/experience';

import ExperienceAvailabilityPageComponent from 'core/libs/core-ui/components/ExperienceAvailabilityPage';
import { formatDateToYYYYMMDD } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { ExperienceService } from 'core/api/services/experiences';
import { ScheduleService } from 'core/api/services/schedule';

import DialogCore from './components/dialog';
import ExperienceDetails from './components/experienceDetails';

const useStyles = makeStyles((theme) => ({
    gridPadre: {
        backgroundColor: '#eff6ff',
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
        height: '100vh',
    },
    gridTitle: {
        paddingTop: '0.5rem',
        maxWidth: '19rem',
        wordWrap: 'break-word',
    },
    gridQuantity: {
        marginBottom: '1rem',
    },
    gridExperience: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(2),
        },
    },
}));

const BookExperience = () => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const {
        addBookedExperienceWithQuantityPrice,
        timeOfArrival,
        setTimeOfArrival,
    } = useExperiences();

    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [datePicker, setDatePicker] = useState(new Date());
    const [experience, setExperience] = useState('');
    const [visibleRange, setVisibleRange] = useState({ start: null, end: null });

    const [slots, setSlots] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { id, code } = useParams();

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        ExperienceService.getById(id).then(setExperience);
    }, [id]);

    useEffect(() => {
        if (!experience) {
            return;
        }
        if(Date.parse(visibleRange.start) && Date.parse(visibleRange.end)) {
            setLoading(true);
            // ScheduleService.getByDateMonth(
            //     id,
            //     experience.expDetailId,
            //     datePicker,
            //     'monthly',
            //     quantity,
            //     code
            // ).then((res) => {
            //     setSlots(res);
            //     setLoading(false);
            // });
            ScheduleService.getAvailability({
                expId: id,
                fromDateTime: new Date(visibleRange.start),
                toDateTime: new Date(visibleRange.end),
                groupSize: quantity,
                code,
            }).then((res) => {
                setSlots([...res]);
                setLoading(false);
            });
        }
    }, [experience, quantity, visibleRange]);

    // useEffect(() => {
    //   if(timeOfArrival && datePicker) {
    //     setDialogOpen(true);
    //   } else {
    //     setDialogOpen(false);
    //   }
    // }, [timeOfArrival]);

    return (
        <>
            <Grid container>
                <ExperienceAvailabilityPageComponent
                    showLoading={false}
                    showPrice={false}
                    showAvailability={true}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    setDatePicker={setDatePicker} // new
                    // onChange={(date) => {
                    //   handleDatePickerChange(date);
                    // }}
                    // onMonthChange={(date) => {
                    //   handleMonthChange(date);
                    // }}
                    // value={datePicker} //deprecated, se cambio por datepicker
                    datePicker={datePicker}
                    // data={schedule} //deprecated
                    slots={slots}
                    experience={experience}
                    // setTimeOfArrival={setTimeOfArrival}
                    // addBookedExperienceWithQuantityPrice={addBookedExperienceWithQuantityPrice}
                    setSelectedSlot={(item) => {
                        setSelectedSlot(item);
                        addBookedExperienceWithQuantityPrice(
                            { ...experience, ...item },
                            item.price * quantity,
                            quantity
                        );
                        // const daySelected = formatDateToYYYYMMDD(datePicker);
                        // setTimeOfArrival(`${daySelected}T${item.time}.000Z`);
                        setTimeOfArrival(item.startUTC);
                        setDialogOpen(item)
                    }}
                    setVisibleRange={setVisibleRange}
                    // setDialogOpen={setDialogOpen}
                    // onSlotSelect={(slot) => {
                    //     // setSelectedSlotId(slot.id);
                    //     setSelectedSlotPrice(parseFloat(slot.price));
                    // }}
                />
                <DialogCore
                    isOpen={dialogOpen}
                    onClose={handleCloseDialog}
                    title=""
                    content={
                        <ExperienceDetails
                            info={selectedSlot}
                            experienceName={experience?.name}
                            dateSlot={datePicker}
                            quantity={quantity}
                            experience={experience}
                        />
                    }
                />
            </Grid>
        </>
        // <Grid className={classes.gridPadre} container>
        //   <Grid className={classes.gridExperience} item xs={12} sm={8} md={8}>
        //     <Grid className={classes.gridTitle}>
        //       <Typography variant="h1" >{experience.name}</Typography>
        //     </Grid>
        //     <Grid className={classes.gridExperience}>
        //       <Typography variant="h5">{translate('slot.quantity')}</Typography>
        //       <QuantityPicker
        //         value={quantity}
        //         handleQuantityChange={handleQuantityChange}
        //       />
        //     </Grid>
        //     <Typography variant="h5">{translate('slot.selectedDate')}</Typography>
        //     <ExperienceDatePicker
        //       onChange={(date) => {
        //         handleDatePickerChange(date);
        //       }}
        //       onMonthChange={(date) => {
        //         handleMonthChange(date);
        //       }}
        //       quantity={quantity}
        //       value={dateDatePikcer}
        //       data={schedule}
        //       showPrice={false}
        //       showAvailability={true}
        //       experience={experience}
        //     />
        //   </Grid>
        // </Grid>
    );
};
export default BookExperience;
