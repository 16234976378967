import React, { useContext, useState } from 'react';

const FiltersContext = React.createContext(null);

export const FiltersProvider = ({ children }) => {
  const [city, setCity] = useState(null);
  const [moduses, setModuses] = useState([]);
  const [locationData, setLocationData] = useState({
    name: null,
    lng: null,
    lat: null,
  });
  const [radius, setRadius] = useState(10);
  const [tourOperatorsId, setTourOperatorsId] = useState([]);

  const addModus = (id) => {
    if (!moduses.find((modus) => modus.id === id)) {
      setModuses([...moduses, id]);
    }
  };

  const removeModus = (id) => {
    const index = moduses.indexOf(id);
    if (index >= 0) {
      moduses.splice(index, 1);
      setModuses([...moduses]);
    }
  };

  const isModusSelected = (id) => {
    return moduses.indexOf(id) >= 0;
  };

  const addTourOperatorId = (id) => {
    if (!tourOperatorsId.find((modus) => modus.id === id)) {
      setTourOperatorsId([...tourOperatorsId, id]);
    }
  };

  const removeTourOperatorId = (id) => {
    const filteredTourOperatorsId = tourOperatorsId.filter(
      (operator) => operator !== id
    );
    setTourOperatorsId(filteredTourOperatorsId);
  };

  const isTourOperatorIdSelected = (id) => {
    return tourOperatorsId?.indexOf(id) >= 0;
  };

  return (
    <FiltersContext.Provider
      value={{
        city,
        moduses,
        locationData,
        radius,
        tourOperatorsId,
        setCity,
        addModus,
        removeModus,
        isModusSelected,
        addTourOperatorId,
        removeTourOperatorId,
        isTourOperatorIdSelected,
        setLocationData,
        setRadius,
        setModuses,
        setTourOperatorsId,
        setRadius,
        setLocationData,
      }}>
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.displayName = 'FiltersProvider';

export const useFilters = () => useContext(FiltersContext);
