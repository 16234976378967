import { fetch, Method } from 'core/api/fetch';
import axios from 'axios';
import { toast } from 'react-toastify';

export const PaymentPlatform = {
  MERCADOPAGO: { id: 1 },
  PAYPAL: { id: 2 },
};

const checkoutRoutes = {
  postPreference: () => `/checkout/preferences`,
  budget: (id, payerEmail) =>
    `http://localhost:3000/budget/${id}?email=${payerEmail}`, //`${process.env.REACT_APP_API_URL}budget/${id}`,
  paypalId: () => `/api/v1.1.0/payment/paypal-id?origin=landing`,
  preference: () => `/api/v1.1.0/payment/create-preference?origin=landing`,
  booking: () => `/api/v1.1.0/payment/create-booking?origin=landing`,
  mercadoPagoValidation: (
    preferenceId,
    paymentPlatform,
    paymentId,
    email,
    code,
    payment_type,
    external_reference
  ) =>
    `/api/v1.1.0/payment?origin=landing&preference_id=${preferenceId}&payment_platform=${paymentPlatform}&payment_id=${paymentId}&status=approved&email=${email}&code=${code}&payment_type=${payment_type}&external_reference=${external_reference}`,
  onApprovedPaypal: (orderId) =>
    `/api/v1.1.0/payment?order_id=${orderId}&payment_platform=PayPal&status=approved`,
};

export const CheckoutService = {
  // FIXME: change to a more generic method that receives the payment platform id
  createPreference: async (
    experiences,
    payerData,
    timeOfArrival,
    experienceDetailId,
    groupSize,
    code
  ) => {
    try {
      // console.log('----------------------------------');
      // console.log('createPreference called with values...');
      // console.log('experiences: ', experiences);
      // console.log('payerData: ', payerData);
      // console.log('timeOfArrival: ', timeOfArrival);
      // console.log('experienceDetailId: ', experienceDetailId);
      // console.log('groupSize: ', groupSize);
      // console.log('code: ', code);
      // console.log('----------------------------------');
      // FIXME: change to mapper model - object
      const items = experiences.map((experience) => ({
        unitPrice: Number.parseFloat(experience.price),
        title: experience.name,
        currency: experience.currency, // can be ignored as default is "COP"
        // experienceDetailId: experience.experienceDetailId,
        experienceDetailId,
        // tripExperienceId: experience.tripExperienceId,
      }));

      const {
        phoneNumber,
        phoneNumberCountryCode,
        firstName,
        lastName,
        email,
        country,
        tripers,
      } = payerData;

      let payload = {
        items,
        phoneNumber,
        phoneNumberAreaCode: phoneNumberCountryCode,
        firstName,
        lastName,
        email,
        nationality: country,
        timeOfArrival,
        redirectOrigin: window.location.origin,
        groupSize,
        tripers,
      };

      if (code && code != null) {
        payload.code = code;
      }

      const { data } = await fetch(checkoutRoutes.preference(), {
        method: Method.POST,
        authenticated: true,
        data: payload,
      });
      // debugger; // eslint-disable-line no-debugger
      if (!data[0].id) {
        toast.error('No id provided', {
          position: 'top-right',
          theme: 'colored',
        });
        throw new Error('No id provided');
      }
      return data[0].id;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      throw new Error(err);
    }
  },
  onValidationMercadoPago: async (
    preferenceId,
    paymentId,
    email,
    code,
    payment_type,
    external_reference
  ) => {
    try {
      // console.log('---------------------------------')
      // console.log('onValidationMercadoPago called with values...')
      // console.log('preferenceId: ', preferenceId);
      // console.log('paymentId: ', paymentId)
      // console.log('---------------------------------')
      const res = await fetch(
        checkoutRoutes.mercadoPagoValidation(
          preferenceId,
          'MercadoPago',
          paymentId,
          email,
          code,
          payment_type,
          external_reference
        ),
        {
          method: Method.GET,
          authenticated: true,
        }
      );
      // console.log('gotten res from onValidationMercadoPago: ', res);
      return res;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      // temporary fix to avoid infinite validating payment view
      window.location.href = '/pending';
      throw new Error(err);
    }
  },
  createPaypalOrder: async (
    experiences,
    payerData,
    timeOfArrival,
    experienceDetailId,
    groupSize,
    code
  ) => {
    console.log('payer data', payerData, experiences);

    try {
      // FIXME: change to mapper model - object
      const items = experiences.map((experience) => ({
        unitPrice: Number.parseFloat(experience.price),
        title: experience.name,
        currency: experience.currency, // can be ignored as default is "COP"
        experienceDetailId,
        // tripExperienceId: experience.tripExperienceId,
      }));
      const {
        phoneNumber,
        phoneNumberCountryCode,
        firstName,
        lastName,
        email,
        country,
        tripers,
      } = payerData;

      let payload = {
        items,
        phoneNumber,
        phoneNumberAreaCode: phoneNumberCountryCode,
        firstName,
        lastName,
        email,
        nationality: country,
        timeOfArrival,
        groupSize,
        tripers,
      };

      if (code && code != null) {
        payload.code = code;
      }

      const { data } = await fetch(checkoutRoutes.paypalId(), {
        method: Method.POST,
        authenticated: true,
        data: payload,
      });
      console.log('data logged', data, data[0].id);
      if (!data[0].id) {
        toast.error('No id provided', {
          position: 'top-right',
          theme: 'colored',
        });
        throw new Error('No id provided');
      }
      return data[0].id;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      throw new Error(err);
    }
  },
  onApprovedPaypal: async (orderId) => {
    try {
      const { data } = await fetch(checkoutRoutes.onApprovedPaypal(orderId), {
        method: Method.GET,
        authenticated: true,
      });
      return data;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      throw new Error(err);
    }
  },
  convertExperiencesPriceToUsd: async (experiences) => {
    try {
      const convertedExperiences = await Promise.all(
        experiences.map(async (experience) => {
          // default converts to COP, so we omit second parameter
          const currencyExchange = await CheckoutService.getCurrencyExchange(
            'COP',
            'USD'
          );
          // here we transform the price to COP and round it to the next integer
          let convertedPrice =
            parseFloat(experience.payNowPrice) * parseFloat(currencyExchange);
          convertedPrice = parseFloat(convertedPrice).toFixed(2);
          convertedPrice = Number.parseInt(parseFloat(convertedPrice) + 1.0);
          return {
            ...experience,
            currency: 'USD',
            payNowPrice: convertedPrice,
          };
        })
      );
      return convertedExperiences;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      throw new Error(err);
    }
  },

  convertExperiencesPriceToCop: async (experiences) => {
    try {
      const convertedExperiences = await Promise.all(
        experiences.map(async (experience) => {
          // if user currency is COP, then do not convert it
          if (experience.userCurrency == 'COP') {
            return {
              ...experience,
              currency: 'COP',
            };
          }
          // default converts to COP, so we omit second parameter
          const currencyExchange = await CheckoutService.getCurrencyExchange(
            experience.userCurrency
          );
          // here we transform the price to COP and round it to the next integer
          let convertedPrice =
            parseFloat(experience.payNowPrice) * parseFloat(currencyExchange);
          convertedPrice = parseFloat(convertedPrice).toFixed(2);
          convertedPrice = Number.parseInt(parseFloat(convertedPrice) + 1.0);
          return {
            ...experience,
            currency: 'COP',
            payNowPrice: convertedPrice,
          };
        })
      );
      return convertedExperiences;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      throw new Error(err);
    }
  },
  getCurrencyExchange: async (from, to) => {
    try {
      to = to || 'COP';
      from = from || 'USD';
      const options = {
        method: 'GET',
        url: 'https://currency-exchange.p.rapidapi.com/exchange',
        params: {
          to: to.toUpperCase(),
          from: from.toUpperCase(),
          q: '1.0',
        },
        headers: {
          'x-rapidapi-key':
            '6dd4a3edc8msh9f07d0593500b13p1af642jsnadd7939a04c9',
          'x-rapidapi-host': 'currency-exchange.p.rapidapi.com',
        },
      };
      const response = await axios.request(options);
      return response.data;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      throw new Error(err);
    }
  },

  createBookOrder: async (
    experiences,
    payerData,
    timeOfArrival,
    experienceDetailId,
    groupSize,
    code
  ) => {
    try {
      // console.log('----------------------------------');
      // console.log('createBooking called with values...');
      // console.log('experiences: ', experiences);
      // console.log('payerData: ', payerData);
      // console.log('timeOfArrival: ', timeOfArrival);
      // console.log('experienceDetailId: ', experienceDetailId);
      // console.log('groupSize: ', groupSize);
      // console.log('code: ', code);
      // console.log('----------------------------------');
      // FIXME: change to mapper model - object
      const items = experiences.map((experience) => ({
        unitPrice: 0,
        title: experience.name,
        // currency: experience.currency, // can be ignored as default is "COP"
        // experienceDetailId: experience.experienceDetailId,
        experienceDetailId,
        // tripExperienceId: experience.tripExperienceId,
      }));

      const {
        phoneNumber,
        phoneNumberCountryCode,
        firstName,
        lastName,
        email,
        country,
        tripers,
      } = payerData;

      let payload = {
        items,
        phoneNumber,
        phoneNumberAreaCode: phoneNumberCountryCode,
        firstName,
        lastName,
        email,
        nationality: country,
        timeOfArrival,
        redirectOrigin: window.location.origin,
        groupSize,
        tripers,
      };

      if (code && code != null) {
        payload.code = code;
      }

      const { data } = await fetch(checkoutRoutes.booking(), {
        method: Method.POST,
        authenticated: true,
        data: payload,
      });
      // debugger; // eslint-disable-line no-debugger
      if (!data[0].id) {
        toast.error('No id provided', {
          position: 'top-right',
          theme: 'colored',
        });
        throw new Error('No id provided');
      }
      return data[0].id;
    } catch (err) {
      toast.error('Oops, something went wrong. Please try again later.', {
        position: 'top-right',
        theme: 'colored',
      });
      throw new Error(err);
    }
  },
};
