import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  // drawer: {
  //   width: (props) => props.drawerWidth,
  //   flexShrink: 0,
  // },
  // drawerOpen: {
  //   width: (props) => props.drawerWidth,
  //   transition: theme.transitions.create('width', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  // drawerClose: {
  //   transition: theme.transitions.create('width', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   overflowX: 'hidden',
  //   width: 60,
  // },
  // header: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   padding: theme.spacing(0, 1),
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  //   ...theme.mixins.toolbar,
  // },
  logo: {
    height: 56,
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  toolbarStyle: {
    backgroundColor: 'white'
  }
  // closedMenuLogo: {
  //   height: 32,
  // },
  // list: {
  //   height: '100%',
  //   color: 'white',
  // },
  // signOut: {
  //   position: 'absolute',
  //   bottom: 90,
  // },
  // openMenu: {
  //   // position: 'absolute',
  //   // bottom: 30,
  //   margin: 'auto',
  //   background: '#4b4d58',
  //   textAlign: 'right',
  // },

  // copyright: {
  //   position: 'absolute',
  //   bottom: 4,
  //   padding: '4px 16px',
  //   fontSize: '0.75rem',
  //   color: theme.palette.colors.alto,
  // },
}));
