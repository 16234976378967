import overrides from './overrides';
import palette from './palette';
import typography from './typography';
import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette,
  typography,
  overrides,
  inputs: {
    minHeight: 104,
  },
});
