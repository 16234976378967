import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'core/contexts/localization';

import { useStyles } from './styles';
import logo from "assets/images/utriper-black-logo.png";
import { Button,Box,Typography } from '@material-ui/core';

const ErrorTemplate = ({ email, goBackUrl }) => {

    const { t: translate } = useTranslation();

    const classes = useStyles();

    const history = useHistory();

    return (
        <React.Fragment>
            <Button
            variant='contained'
            className={classes.buttonStyle}
            onClick={() => history.replace(`/${goBackUrl}`)}
            >
                {translate("utils.backToSite")}
            </Button>
            <Box>
                <Box>
                    <Box class="inner" style={{ overflowWrap: "anywhere" }}>
                        <Box className={classes.container}
                            style={{ marginInline: "auto", borderColor: "#E39348", borderStyle: "solid", borderWidth: "0.5rem", paddingInline: "1rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}
                        >
                            <Box sx={{ width: "13rem", height: "4.5rem", marginInline: "auto", marginBottom: "4rem", marginTop: "1rem" }}>
                                <img src={logo} style={{ width: "inherit", height: "inherit" }} />
                            </Box>
                            <Typography variant='h1' className={classes.text} style={{ marginBottom: "4rem" }}>
                                <strong>
                                    {translate("templates.error.title")}
                                </strong>
                            </Typography>
                            <Typography variant='h1' className={classes.text} style={{ marginBottom: "4rem" }}>
                                <strong>
                                    {translate("templates.error.body")}
                                </strong>
                            </Typography>
                            <Typography  variant="h1" className={classes.text} style={{ marginBottom: "1rem" }}>
                                <strong>
                                    {translate("templates.error.beforeEmail")}
                                </strong>
                            </Typography>
                            <a href="mailto:hello@utriper.com" className={classes.text} style={{ marginBottom: "1rem" }}>
                                <strong>
                                    hello@utriper.com
                                </strong>
                            </a>
                            <Typography variant="h1" className={classes.text} style={{marginBottom: '1rem'}}>
                                <strong>
                                    {translate("templates.error.afterEmail")}
                                </strong>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default ErrorTemplate;