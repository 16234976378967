import React, { useState, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginBottom: theme.spacing(1),
    },
}));

export const DateInput = ({
    addToDate,
    values_,
    input,
    disabled,
    disableFuture,
    disablePast,
    meta,
    label,
    name,
    onChange,
    ...props
}) => {
    const [hasError, setHasError] = useState(props.initialError);
    const [selectedDate, handleDateChange] = useState(null);
    const classes = useStyles();

    return (
        <FormControl className={classes.root}>
            <div className={classes.root}>
                <DatePicker
                style={{width: '100%', marginTop: '1rem'}}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    openTo="year"
                    views={["year", "month", "date"]}
                    inputVariant="outlined"
                    id={name}
                    label={label}
                    disabled={disabled || false}
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={(value) => {
                        setHasError((prevValue) => {
                            const retValue = !props.setValidate(value);
                            props.setFormError(retValue);
                            return retValue;
                        });
                        handleDateChange(value);
                        if(onChange) {onChange(value);}
                        input.onChange(value);
                    }}
                    error={meta.touched ? !!meta.error : false}
                    helperText={meta.touched ? meta.error : ''}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </div>
        </FormControl>
    );
};

DateInput.displayName = 'DateInput';
