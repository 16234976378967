import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Typography,
  Checkbox,
  ListItemText,
  OutlinedInput
} from '@material-ui/core';
import { Popover, Button } from 'antd';


import { useTranslation } from 'core/contexts/localization';
import { DelimitedNumericArrayParam, useQueryParam, withDefault } from 'use-query-params';

import { useStyles } from './styles';
import { TrainRounded } from '@material-ui/icons';
import { modusesArray } from "core/constants";

import { MOODS, moods, getTranslatedName as getModusTranslatedName} from 'core/libs/import/Moods';
import { useFilters } from 'core/contexts/filter';

const IntegerArrayDefaultParam = withDefault(DelimitedNumericArrayParam, []);

export const ModusSelect = ({ initialModuses, ...props }) => {
  const { t: translate, i18n } = useTranslation();
  const i18nLang = i18n.language;

  const classes = useStyles();

  const { addModus, moduses, removeModus, isModusSelected } = useFilters(initialModuses);
  const [selectedValues, setSelectedValues] = useState([]);
  const [URLModus, setURLModus] = useQueryParam('moods', IntegerArrayDefaultParam);

  const handleChange = (event, value) => {
    if (value.props.isSelected) {
      removeModus(value.props.value);
      const moodIndex = URLModus.indexOf(value.props.value);
      if (moodIndex > -1) {
        URLModus.splice(moodIndex, 1);
        setURLModus(URLModus);
      }
    } else {
      addModus(value.props.value);
      setURLModus([...URLModus, value.props.value]);
    }
    setSelectedValues([...selectedValues, value.props.modusName]);
  };

  return (
    <>
      <FormControl variant="outlined" style={{width: '100%'}}>
        <InputLabel style={{ fontSize: '0.9rem', marginTop: '-4px' }} id="select-trip">Moods</InputLabel>
        <Select
          input={<OutlinedInput label="Modus" />}
          multiple
          labelId="select-trip"
          className={classes.filterButton}
          value={moduses}
          label="Moods"
          onChange={handleChange}
          renderValue={(moduses) => moduses.map(id => getModusTranslatedName(id, i18nLang, moods)).join(", ")}
          MenuProps={{
            getContentAnchorEl: () => null,
            PaperProps: {
              style: {
                maxHeight: "20rem",
              },
            },
          }}
        >
          {
            Object.values(MOODS).map(modus => {
              const isSelected = isModusSelected(modus.id);
              const modusName = getModusTranslatedName(modus.id, i18nLang, moods);
              return (
                <MenuItem value={modus.id} key={modus.id} modusName={modusName} isSelected={isSelected}>
                  <Checkbox checked={isSelected} style={{ color: "#2a81e8" }} />
                  <ListItemText  primary={modusName} />
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
};

ModusSelect.displayName = 'ModusSelect';
