import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0px`,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 325,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    maxWidth: 180,
  },
  menuItem: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  filterButton: {
    order: "1px solid #c5c6c7",
    borderRadius: "4px",
    height: "42px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    background: "#f9f9f9",
    padding: "4px 15px",
  }
}));
