import React from 'react';
import { Form, Field } from 'react-final-form';
import { Autocomplete } from './Autocomplete';

const AutocompleteField = (props) => {
    const className = props.className ?? '';

    return (
        <Field
            className={className}
            name={props.name}
            label={props.label}
            render={(componentProps) => (
                <Autocomplete
                    {...componentProps}
                    {...props}
                    onTextFieldChange={props.onTextFieldChange}
                    errorMessage={props.errorMessage}
                />
            )}
        />
    );
};

export default AutocompleteField;
