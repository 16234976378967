import HomeIcon from 'assets/icons/menu/home.svg';
import ProfileIcon from 'assets/icons/menu/profile.svg';
import MyTripsIcon from 'assets/icons/menu/myTrips.svg';
import CalendarIcon from 'assets/icons/menu/calendar.svg';
import BookingsIcon from 'assets/icons/menu/bookings.svg';
import BudgetIcon from 'assets/icons/menu/budget.svg';
import ArrowForwardIos from '@material-ui/icons';
import { ROUTES } from 'core/routing/routes';

export const triperMenu = [
  {
    name: 'layout.menu.home',
    link: ROUTES.LANDING,
    icon: HomeIcon,
  },
  {
    name: 'layout.menu.calendar',
    link: ROUTES.BOOKING,
    icon: CalendarIcon,
  },
  // {
  //   name: 'layout.menu.budget',
  //   link: ROUTES.CHECKOUT,
  //   icon: ShoppingCartIcon,
  // },
  // {
  //   name: 'layout.menu.savedexperiences',
  //   link: ROUTES.SAVEDEXPERIENCES,
  //   icon: BudgetIcon,
  // },
];
