import TubingBuritaca from 'assets/images/experiences/tubingburitaca.svg';
import Experience2 from 'assets/images/experiences/experience2.jpg';

const experiences = [
  {
    name: 'Tubing Buritaca',
    price: 129.99,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    duration: 4,
    availability: '15 ',
    image: TubingBuritaca,
  },
  {
    name: 'Experience 2',
    price: 149.99,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    duration: 4,
    availability: '15 ',
    image: Experience2,
  },
];

export default experiences;
