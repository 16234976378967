import React from "react";
import { Grid } from "@material-ui/core";
import DestinationCard from "./components/DestinationCard";

import destinations from "./data";

const DestinationsList = ({
    className
}) => {
    return (
        <Grid container spacing={3} className={className}>
            {destinations.map((destination) => (
                <Grid item key={destination.id} xs={12} sm={12} md={4} lg={4} xl={4}>
                    <DestinationCard
                        name={destination.name}
                        cardText={destination.cardText}
                        lat={destination.lat}
                        lng={destination.lng}
                        imageUrl={destination.imageUrl}
                        url={destination.url}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default DestinationsList;
