import React from 'react';
import {
  UsersSection,
} from './ContractSections';

const TermsAndConditionsLanding = () => {
  return (
    <React.Fragment>
      <UsersSection style={{ textAlign: 'justify' }} />
    </React.Fragment>
  );
};

export default TermsAndConditionsLanding;
