import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'core/contexts/localization';

import { useStyles } from './styles';
import { UpdateOutlined } from '@material-ui/icons';
import logo from "assets/images/utriper-black-logo.png";
import "./styles.css";

const PendingTemplate = ({ email, goBackUrl }) => {

    const { t: translate } = useTranslation();

    const classes = useStyles();

    const history = useHistory();

    return (
        <React.Fragment>
            <button
                className="triper-button bg-blue-300"
                style={{ marginLeft: "15vw", marginTop: "3vh" }}
                onClick={() => history.replace(`/${goBackUrl}`)}
            >
                {translate("utils.backToSite")}
            </button>
            <div class="outer">
                <div class="middle">
                    <div class="inner" style={{ overflowWrap: "anywhere" }}>
                        <div className={classes.container}
                            style={{ marginInline: "auto", borderColor: "#A8B9E8", borderStyle: "solid", borderWidth: "0.5rem", paddingInline: "1rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}
                        >
                            <div style={{ width: "13rem", height: "4.5rem", marginInline: "auto", marginBottom: "4rem", marginTop: "1rem" }}>
                                <img src={logo} style={{ width: "inherit", height: "inherit" }} />
                            </div>
                            <h1 className={classes.text} style={{ marginBottom: "3rem" }}>
                                <strong>
                                    {translate("templates.pending.title")}
                                </strong>
                            </h1>
                            <div>
                              <UpdateOutlined style={{ height: "4rem", width: "4rem", display: "flex", marginInline: "auto", marginBottom: "1.5rem" }} />
                            </div>
                            <h1 className={classes.text} style={{ marginBottom: "1rem" }}>
                                <strong>
                                    {translate("templates.pending.beforeEmail")}
                                </strong>
                            </h1>
                            <a href="mailto:hello@utriper.com" className={classes.text} style={{ marginBottom: "1rem" }}>
                                <strong>
                                    hello@utriper.com
                                </strong>
                            </a>
                            <h1 className={classes.text} style={{ marginBottom: '3rem' }}>
                                <strong>
                                    {translate("templates.pending.afterEmail")}
                                </strong>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PendingTemplate;