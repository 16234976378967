import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${60}px)`,
      marginLeft: 60,
    },
    color: theme.palette.colors.white
  },
  appBarShift: {
    width: (props) => `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: (props) => props.drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    height: '30px',
    width: '30px',
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  role: {
    display: 'flex',
  },
  roleSeparator: {
    margin: `0px ${theme.spacing()}px`,
  },

  highlightRole: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.background,
  },
  redirectButtons: {
    borderRadius: 4,
    border: "none",
    cursor: "pointer",
  }
}));
