/* eslint-disable import/no-anonymous-default-export */
export default {
  primary: {
    main: '#363740',
    background: '#FFFFFF',
  },

  secondary: {
    main: '#0bcd58',
    secondary: '#0bcd58',
    dark: '#54CF86',
    background: '#F6F6F6',
  },
  text: {
    primary: '#333',
    secondary: '#363740',
    white: '#fff',
  },
  colors: {
    alto: '#E0E0E0',
    frenchPass: '#B2CFFE',
    padua: '#B0E8B8',
    harvestGold: '#DAB673',
    scienceBlue: '#004CD0',
    white: '#FFFFFF',
    mineShaft: '#2F2F2F',
    persianGreen: '#009688',
  },
};
