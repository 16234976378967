import React from 'react';
import { Form, Field } from 'react-final-form';
import TextInput from './TextInput';

const TextField = (props) => {
  const className = props.className ?? '';

  return (
    <Field
      className={className}
      name={props.name}
      label={props.label}
      render={(componentProps) => (
        <TextInput
          {...componentProps}
          {...props}
          onTextFieldChange={props.onTextFieldChange}
          errorMessage={props.errorMessage}
        />
      )}
    />
  );
};

export default TextField;
