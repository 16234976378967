import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';

const TextArea = ({
  meta: { touched, error },
  input: { ...inputProps },
  ignoreError = false,
  rows = 4, // Cantidad de filas visibles en el textarea
  placeholder, // Prop para el placeholder que se recibirá desde el Field
  label,
  ...props
}) => {
  const { t: translate } = useTranslation();
  const [hasError, setHasError] = useState(props.initialError);
  const [value, setValue] = useState(undefined);
  const [isFocused, setIsFocused] = useState(false); // Estado para controlar si el campo está enfocado

  useEffect(() => setHasError(props.initialError), [props.initialError]);

  return (
    <TextField
      {...inputProps}
      {...props}
      multiline // Convierte el TextField en un textarea
      minRows={rows} // Controla el número de líneas visibles
      placeholder={placeholder} // Muestra el placeholder solo cuando está enfocado
      label={label}
      InputLabelProps={{
        shrink: true, // Hace que el label flote desde el principio
      }}
      inputProps={{
        style: {
          lineHeight: '1.5', // Ajusta el valor según sea necesario
        },
      }}
      error={!!(touched && error && !ignoreError)}
      fullWidth
      variant="outlined"
    />
  );
};

TextArea.displayName = 'TextArea';

export default TextArea;
