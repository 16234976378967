import React from 'react';
import {
  DataProtectionAndManipulationSection,
  PrivacyPolicyAndDataProtectionSection,
} from '../ContractSections';

const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <PrivacyPolicyAndDataProtectionSection style={{ textAlign: 'justify' }} />
      <DataProtectionAndManipulationSection style={{ textAlign: 'justify' }} />
    </React.Fragment>
  );
};

export default TermsAndConditions;
