import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ErrorCheckbox = withStyles({
  root: {
    color: '#f44336',
  },
})((props) => <Checkbox color="default" {...props} />);

const CheckboxComponent = ({
  checked,
  onChange,
  labelComponent,
  hasError,
  errorMessage,
}) => {
  const RenderedCheckbox = hasError ? <ErrorCheckbox /> : <Checkbox style={{color: "#2a81e8"}} />;

  return (
    <FormControl error={hasError}>
      <FormControlLabel
        control={RenderedCheckbox}
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
        label={labelComponent}></FormControlLabel>
      {hasError ? (
        <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default CheckboxComponent;
