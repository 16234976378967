import React, { useContext, useState } from 'react';

import { CoTripersService } from 'core/api/services/coTripers';

const CoTripersContext = React.createContext(null);

export const CoTripersProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const searchCoTriper = async (query) => {
    try {
      setLoading(true);
      const coTripersData = await CoTripersService.get(query);

      if (!coTripersData) {
        throw new Error();
      }

      return coTripersData;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setLoading(false);
    }
  };
  return (
    <CoTripersContext.Provider value={{ searchCoTriper, loading }}>
      {children}
    </CoTripersContext.Provider>
  );
};

CoTripersProvider.displayName = 'CoTripersProvider';

export const useCoTripers = () => useContext(CoTripersContext);
