import React, { useContext, useState } from 'react';

const HostelContext = React.createContext(null);

export const HostelProvider = ({ children }) => {
    const [code, setCode] = useState(null);

    const [data, setData] = useState(null);

    return (
        <HostelContext.Provider
            value={{
                code,
                setCode,
                data, 
                setData
            }}>
            {children}
        </HostelContext.Provider>
    );
};

HostelProvider.displayName = 'HostelProvider';

export const useHostel = () => useContext(HostelContext);
