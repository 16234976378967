import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Logo from 'assets/icons/logo/white.png';
import cartagena from 'assets/images/cities/cartagena.jpg';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    experiencesHeader: {
        height: '400px',
        backgroundImage: `url(${cartagena})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        background: '#000',
        height: '100%',
        width: '100%',
        opacity: '0.5'
    },
    city: {
        textAlign: 'center',
        lineHeight: '400px',
    },
    logoContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '17rem',
        top: '20vh',
        textAlign: 'center',
    },
    logo: {
        width: 'inherit',
        height: 'inherit',
    },
}));

export default function Header() {
    const classes = useStyles();

    return (
        <Box className={classes.experiencesHeader}>
            <Box className={classes.overlay}></Box>
            <Box className={classes.logoContainer}>
                <img src={Logo} alt="Logo" className={classes.logo} />
            </Box>
        </Box>
    );
}
