import React, { useState, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '25px !important;',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginBottom: theme.spacing(1),
    },
}));

export const DateInput = ({
    addToDate,
    values_,
    input,
    disabled,
    meta,
    label,
    name,
    onChange
}) => {
    const [selectedDate, handleDateChange] = useState(null);
    const classes = useStyles();

    return (
        <FormControl>
            <div className={classes.root}>
                <DatePicker
                    disableFuture
                    openTo="year"
                    views={["year", "month", "date"]}
                    inputVariant="outlined"
                    id={name}
                    label={label}
                    disabled={disabled || false}
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={(value) => {
                        handleDateChange(value);
                        onChange(value);
                    }}
                    error={meta.touched ? !!meta.error : false}
                    helperText={meta.touched ? meta.error : ''}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </div>
        </FormControl>
    );
};

DateInput.displayName = 'DateInput';
