import { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
} from '@material-ui/core';
import {
    DelimitedNumericArrayParam,
    useQueryParam,
    withDefault,
} from 'use-query-params';
import { useFilters } from 'core/contexts/filter';
import { useExperiences } from 'core/contexts/experience';
import { useStyles } from './styles';

const IntegerArrayDefaultParam = withDefault(DelimitedNumericArrayParam, []);

export const TourOperatorSelect = ({ ...props }) => {
    const { qrExperiences } = useExperiences();


    const {
        tourOperatorsId,
        addTourOperatorId,
        removeTourOperatorId,
        isTourOperatorIdSelected,
    } = useFilters([]);
    const idOptions = [
        ...new Set(qrExperiences.map((experience) => experience.experiencerId)),
    ];
    const [selectedValues, setSelectedValues] = useState([]);
    const [showTourOperators, setShowTourOperators] = useState([]);
    const [firstShowTourOperators, setFirstShowTourOperators,] = useState(false);
    const [URLTourOperatorsId, setURLTourOperatorsId] = useQueryParam(
        'tourOperatorsId',
        IntegerArrayDefaultParam
    );
    useEffect(() => {
      if(!firstShowTourOperators && qrExperiences.length){
          const initialOptions = [...new Set(qrExperiences.map((experience) => experience.experiencerId))];
          setShowTourOperators(initialOptions);
          setFirstShowTourOperators(true)
          if(URLTourOperatorsId && URLTourOperatorsId.length) {
            addTourOperatorId(URLTourOperatorsId[0]);
            setSelectedValues([...selectedValues, URLTourOperatorsId[0]]);
          }
      }
  }, [qrExperiences]);

    const handleChange = (event, value) => {
        if (value.props.isSelected) {
            removeTourOperatorId(value.props.value);
            const tourOperatorIndex = URLTourOperatorsId.indexOf(value.props.value);
            if (tourOperatorIndex > -1) {
                URLTourOperatorsId.splice(tourOperatorIndex, 1);
                setURLTourOperatorsId(URLTourOperatorsId.filter((val) => val)); // filter out undefined and NaN values
            }
            setSelectedValues(
                selectedValues.filter((val) => val !== value.props.value)
            );
        } else {
            addTourOperatorId(value.props.value);
            setURLTourOperatorsId([
                ...URLTourOperatorsId.filter((val) => val),
                value.props.value,
            ]); // filter out undefined and NaN values
            setSelectedValues([...selectedValues, value.props.value]);
        }
    };

    return (
        <>
            <FormControl variant="outlined" style={props.style}>
                <style>
                    {`.MuiInputLabel-outlined {
              transform: translate(14px, 16px) scale(1);
          }`}
                </style>
                {showTourOperators.length > 1 &&
                <>
                <InputLabel id="select-tour-operator">
                    Tour Operators
                </InputLabel>
                <Select
                    input={<OutlinedInput label="Tour Operator" />}
                    multiple
                    labelId="select-tour-operator"
                    className="filter-button bg-gray-200"
                    value={selectedValues}
                    label="Tour Operators"
                    onChange={handleChange}
                    renderValue={(selected) => {
                        const selectedExperiencers = qrExperiences.filter((exp) =>
                         selected.includes(exp.experiencerId)
                        );
                        const groupedExperiencers = selectedExperiencers.reduce(
                            (groups, exp) => {
                                if (!groups[exp.experiencerId]) {
                                    groups[exp.experiencerId] = {
                                        name: exp.experiencerName,
                                        count: 1,
                                    };
                                } else {
                                    groups[exp.experiencerId].count += 1;
                                }
                                return groups;
                            },
                            {}
                        );
                        return Object.values(groupedExperiencers)
                            .map((exp) => {
                                if (exp.count === 1) {
                                    return exp.name;
                                } else {
                                    return exp.name; // `${exp.name} (${exp.count})` Replace with this to see the amount of experiences per tour operator
                                }
                            })
                            .join(', ');
                    }}
                    MenuProps={{
                        getContentAnchorEl: () => null,
                        PaperProps: {
                            style: {
                                maxHeight: '20rem',
                                width: 'max-content',
                            },
                        },
                    }}>
                    {idOptions.map((id) => {
                        const isSelected = isTourOperatorIdSelected(id);
                        const experiencer = qrExperiences.find(
                            (exp) => exp.experiencerId === id
                        );
                        return (
                            <MenuItem
                                key={id}
                                value={id}
                                isSelected={isSelected}>
                                <Checkbox
                                    checked={isSelected}
                                    style={{ color: '#2a81e8' }}
                                />
                                <ListItemText
                                    primary={experiencer.experiencerName}
                                />
                            </MenuItem>
                        );
                    })}
                </Select>
                </>}
            </FormControl>
        </>
    );

};
