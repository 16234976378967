import React, { useContext, useState } from 'react';

const SortsContext = React.createContext(null);

export const Order = {
    ASCENDANT: 0,
    DESCENDANT: 1,
};

export const SortsProvider = ({ children }) => {

    const [priceOrder, setPriceOrder] = useState(Order.ASCENDANT)

    const setAscendingPriceOrder = () => {
        if (priceOrder != Order.ASCENDANT) {
            setPriceOrder(Order.ASCENDANT);
        }
    }

    const setDescendingPriceOrder = () => {
        if (priceOrder != Order.DESCENDANT) {
            setPriceOrder(Order.DESCENDANT);
        }
    }

    const isPriceOrderAscendant = () => priceOrder == Order.ASCENDANT;

    return (
        <SortsContext.Provider
            value={{
                priceOrder,
                setAscendingPriceOrder,
                setDescendingPriceOrder,
                isPriceOrderAscendant,
                setPriceOrder
            }}>
            {children}
        </SortsContext.Provider>
    );
};

SortsProvider.displayName = 'SortsProvider';

export const useSorts = () => useContext(SortsContext);
