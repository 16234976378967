import React, { useState } from "react";
import Link from "./Links";
import { useTranslation } from 'core/contexts/localization';
import Terms from "components/TermsAndConditions";
import { HowWeTakeCareOfYourPrivacy, TermsAndConditions } from "components/TermsAndConditions/Footer";

const AboutSection = () => {

    const { t: translate } = useTranslation();

    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
    const [openHowWeTakeCareOfYourPrivacy, setOpenHowWeTakeCareOfYourPrivacy] = useState(false);

    return (
        <React.Fragment>
            <div style={{ textAlign: 'left', marginInline: "auto", width: "50%" }}>
                <p style={{ fontSize: "1rem" }}>
                    <strong>
                        {translate("footer.about.title")}
                    </strong>
                </p>
                <Link onClick={(e) => {
                    setOpenTermsAndConditions(true);
                    e.preventDefault();
                }}>
                    {translate("footer.about.termsAndConditions")}
                </Link>
                <br />
                <Link onClick={(e) => {
                    setOpenHowWeTakeCareOfYourPrivacy(true);
                    e.preventDefault();
                }}>
                    {translate("footer.about.howWeTakeCareOfYourPrivacy")}
                </Link>
            </div>
            <Terms
                open={openTermsAndConditions}
                onClose={() => setOpenTermsAndConditions(false)}
                withAcceptButton={false}
                termsToRender={<TermsAndConditions />}
                header={translate("footer.about.termsAndConditions").toUpperCase()}
            />
            <Terms
                open={openHowWeTakeCareOfYourPrivacy}
                onClose={() => setOpenHowWeTakeCareOfYourPrivacy(false)}
                withAcceptButton={false}
                termsToRender={<HowWeTakeCareOfYourPrivacy />}
                header={translate("footer.about.howWeTakeCareOfYourPrivacy").toUpperCase()}
            />
        </React.Fragment>
    );
}

export default AboutSection;