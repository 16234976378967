import React from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';


const FullScreenImage = ({ src, open, onClose }) => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Grid container className={classes.imageContainer}>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <img src={src} alt="experience" className={classes.image} />
            </Grid>
        </div>
    );
};

export default FullScreenImage;
