import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { ExitToApp, Close } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styles';
import { translate } from 'core/locales';
import { useAuth } from 'core/contexts/auth';
import WhiteLogo from 'assets/icons/menu/logo/white.png';
import ClosedMenuLogo from 'assets/icons/menu/logo/closed-menu.png';
import { triperMenu } from './items';

const Menu = ({ open, toggleMenu }) => {
  const history = useHistory();

  const { logout, role } = useAuth();

  const items = triperMenu;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const drawerWidth = isMobile ? '100vw' : 240;

  const classes = useStyles({ drawerWidth });

  const onLogoutClick = async () => {
    await logout();
    history.push('/');
  };

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      variant={isMobile ? 'persistent' : 'permanent'}
      anchor="left"
      open={open}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}>
      <div className={classes.header}>
        {open ? (
          <img
            alt="global-triper-logo"
            className={classes.logo}
            src={WhiteLogo}
          />
        ) : (
          <img
            alt="global-triper-logo"
            className={classes.closedMenuLogo}
            src={ClosedMenuLogo}
          />
        )}

        {isMobile && (
          <IconButton style={{ color: 'white' }} onClick={() => toggleMenu()}>
            <Close />
          </IconButton>
        )}
      </div>
      <List className={classes.list}>
        <ListItem button className={classes.openMenu} onClick={toggleMenu}>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
        </ListItem>
        {items.map(({ name, icon, link }) => (
          <ListItem button key={name} onClick={() => history.push(link)}>
            <ListItemIcon>
              <img
                alt={name}
                style={{ maxWidth: '1.7em', maxHeight: '1.7em' }}
                src={icon}
              />
            </ListItemIcon>
            {!!open && <ListItemText primary={translate(name)} />}
          </ListItem>
        ))}

        <ListItem
          button
          key={'sign-out'}
          className={classes.signOut}
          onClick={onLogoutClick}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          {!!open && (
            <ListItemText style={{ color: 'white' }} primary="Sign Out" />
          )}
        </ListItem>

        {!!open && (
          <Typography className={classes.copyright}>
            © Global Triper - Privacy policy
          </Typography>
        )}
      </List>
    </Drawer>
  );
};

Menu.displayName = 'Menu';

export default Menu;
