import React from 'react';
import { Layout } from "antd";
import { Grid, IconButton } from "@material-ui/core";
import { Icons } from "./SocialMedia";
import UsersSection from "./UsersSection";
import AboutSection from "./AboutSection";

import logo from "assets/images/utriper-black-logo.png"

const { Footer } = Layout;


const FooterComponent = () => {
    return (
        <Footer style={{ textAlign: 'center' }}>
            <Grid container>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <div style={{ width: "11rem", height: "4rem", marginInline: "auto" }}>
                        <img src={logo} style={{ width: "inherit", height: "inherit" }} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={3} xl={3}>
                    <Icons />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2} xl={3}>
                    <UsersSection />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
                    <AboutSection />
                </Grid>
            </Grid>
        </Footer>
    );
}

export default FooterComponent;