import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Button } from 'antd';
import { useTranslation } from 'core/contexts/localization';
import { useMediaQuery } from '@material-ui/core';
import { usePayment } from 'core/contexts/payment';
import { CheckoutService } from 'core/api/services/checkout';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import tmppse from '../../assets/icons/TMPPSE.png'

const OrderSummaryButtons = (props) => {
  const marginTop = props.marginTop ?? 0;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const history = useHistory();
  const { t: translate } = useTranslation();

  const { setMercadoPago, canSubmitForm, userData, paymentType } = usePayment();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://sdk.mercadopago.com/js/v2';
    script.async = true;
    script.onload = async () => {
      setMercadoPago(
        new window.MercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, {
          locale: 'es-CO',
        })
      );
    };

    document.body.appendChild(script);

    // const paypalScript = document.createElement('script');
    // const paypalClientId =
    //   'AfPTlmsDOGYTJYgm_zS2fNzzQssfcmwyHcgQ2M2ZUq6oxY5NYLmcn8QsTBG4kscFEUlPAKrIBU9N_bUn';
    // paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=USD&vault=true`;
    // paypalScript.async = true;

    // paypalScript.onload = async () => {
    //   window.paypal
    //     .Buttons({
    //       style: {
    //         shape: 'rect',
    //         // color: 'black',
    //         // layout: 'vertical',
    //         // label: 'subscribe',
    //         // tagline: 'false'
    //       },
    //       // Sets up the transaction when a payment button is clicked
    //       createOrder: async function (data, actions) {
    //         console.log("inside");

    //         // const orderId = await props.onPaypalCreateOrder();
    //         const resp = actions.order.create({
    //           purchase_units: [
    //             {
    //               amount: {
    //                 value: '1.5', // Can reference variables or functions. Example: `value: document.getElementById('...').value`
    //               },
    //             },
    //           ],
    //         });
    //         // return orderId;
    //         return resp;
    //       },

    //       // Finalize the transaction after payer approval
    //       onApprove: function (data, actions) {
    //         return actions.order.capture().then(function (orderData) {
    //           // Successful capture! For dev/demo purposes:
    //           console.log(
    //             'Capture result',
    //             orderData,
    //             JSON.stringify(orderData, null, 2)
    //           );
    //           let transaction =
    //             orderData.purchase_units[0].payments.captures[0];
    //           alert(
    //             'Transaction ' +
    //             transaction.status +
    //             ': ' +
    //             transaction.id +
    //             '\n\nSee console for all available details' +
    //             `${transaction}`
    //           );

    //           // When ready to go live, remove the alert and show a success message within this page. For example:
    //           // var element = document.getElementById('paypal-button-container');
    //           // element.innerHTML = '';
    //           // element.innerHTML = '<h3>Thank you for your payment!</h3>';
    //           // Or go to another URL:  actions.redirect('thank_you.html');
    //         });
    //       },
    //     })
    //     .render('#paypal-button-container');
    // };

    // document.body.appendChild(paypalScript);

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);

  return (
    <React.Fragment>
      {/* <style>
        {`
          iframe { 
            width: 12.5rem !important;
          }`
        }
      </style> */}
      <Grid container style={{ marginTop, marginBottom: "0.75rem" }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={8}
          xl={8}
          style={isMobile ? { justifyContent: "center", display: "flex", paddingRight: '2em' } : { paddingRight: '2em' }}>
          <Button
            type="button"
            onClick={props.onCancel}
            size="large"
            style={{
              color: 'grey',
              float: 'right',
              width: "12.5rem",
              marginBottom: "0.75rem"
            }}>
            {translate('checkout.cancel_order')}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          xl={4}
          style={{
            paddingRight: '2em',
            // width: '12rem',
            display: 'flex',
            justifyContent:'center'
          }}>
          <Button
            type="button"
            disabled={!canSubmitForm(true)}
            onClick={async () => {
              await props.onBook();
            }}
            size="large"
            hidden={props.totalPrice > 0}
            style={{
              backgroundColor: '#2a81e8',
              color: 'white',
              float: 'right',
              width: "12.5rem",
              marginBottom: "0.75rem",
            }}>
            {translate('checkout.book_order')}
          </Button>
          <Button
            type="button"
            disabled={!canSubmitForm(true)}
            hidden={props.totalPrice == 0 || paymentType !== 1}
            onClick={async () => {
              await props.onMercadoPagoCreateOrder();
            }}
            size="large"
            // className="bg-blue-300"
            style={{
              color: 'white',
              paddingTop: 0,
              width: "12.5rem",
              borderRadius: "4px",
              borderColor: "#2a81e8",
              marginInline: "auto",
              display: "flow-root"
            }}>
            {/* {translate('checkout.continue')} */}
            <img
              src={tmppse}
              style={{
                height: 'calc(100% - 1px)',
                objectFit: 'cover',
                position: 'relative',
              }}
              alt="tmpse"
            />
          </Button>
          {/* <div
            hidden={props.totalPrice == 0}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <p style={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '0.75rem', textAlign: "center" }}>
              {`* ${translate("checkout.onlyColombianCards")}`}
            </p>
          </div> */}
          <div hidden={props.totalPrice == 0 || paymentType !== 2} style={{ display: "flex", justifyContent: "center", minWidth: "12.5rem" }}>
            <PayPalScriptProvider
              options={{
                'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
                vault: true,
                intent: 'capture',
              }}>
              <PayPalButtons
                disabled={!canSubmitForm(true)}
                style={{ layout: 'vertical', color: 'black', label:'checkout' }}
                forceReRender={[userData, props.checkoutExperiences, canSubmitForm]}
                createOrder={async function (data, actions) {
                  // if (!canSubmitForm()) {
                  //   throw new Error('Cannot submit form');
                  // }
                  const orderId = await props.onPaypalCreateOrder();
                  // const resp = actions.order.create({
                  //   purchase_units: [
                  //     {
                  //       amount: {
                  //         value: '1.5', // Can reference variables or functions. Example: `value: document.getElementById('...').value`
                  //       },
                  //     },
                  //   ],
                  // });
                  // return resp;
                  console.log("inside", orderId, data, actions);
                  return orderId;
                }}
                // Finalize the transaction after payer approval
                onApprove={async function (data, actions) {
                  console.log('data', data, 'actions', actions);
                  await CheckoutService.onApprovedPaypal(data.orderID);
                  // return actions.order.capture().then(function (orderData) {
                  //   // Successful capture! For dev/demo purposes:
                  //   console.log(
                  //     'Capture result',
                  //     orderData,
                  //     JSON.stringify(orderData, null, 2)
                  //   );
                  //   let transaction =
                  //     orderData.purchase_units[0].payments.captures[0];
                  //   alert(
                  //     'Transaction ' +
                  //     transaction.status +
                  //     ': ' +
                  //     transaction.id +
                  //     '\n\nSee console for all available details' +
                  //     `${transaction}`
                  //   );

                  //   // When ready to go live, remove the alert and show a success message within this page. For example:
                  //   // var element = document.getElementById('paypal-button-container');
                  //   // element.innerHTML = '';
                  //   // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                  //   // Or go to another URL:  actions.redirect('thank_you.html');
                  console.log("actions ===", actions)
                  console.log("user data inside approval", userData)
                  history.replace("/purchase");
                  // actions.redirect('http://localhost:3000/purchase');
                  // });
                }}
              />
            </PayPalScriptProvider>
          </div>
          {/* <div
            hidden={props.totalPrice == 0}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <p style={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '0.75rem', textAlign: "center", maxWidth: "12.5rem" }}>
              {`* ${translate("checkout.enable_popups")}`}
            </p>
          </div> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default OrderSummaryButtons;
