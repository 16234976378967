import React, { useContext, useState } from 'react';

import { LocationService } from 'core/api/services/location';

const LocationsContext = React.createContext(null);

export const LocationsProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLocations = async (query) => {
    try {
      setLoading(true);
      const locationsData = await LocationService.get(query);

      if (!locationsData) {
        throw new Error();
      }

      setLocations(locationsData);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };
  return (
    <LocationsContext.Provider
      value={{
        locations,
        getLocations,
        loading,
      }}>
      {children}
    </LocationsContext.Provider>
  );
};

LocationsProvider.displayName = 'LocationsProvider';

export const useLocations = () => useContext(LocationsContext);
