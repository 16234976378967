import { fetch, Method } from 'core/api/fetch';
import { setSession } from '../session';
const AuthRoutes = {
  getToken: '/auth/landing',
  getValidation: "/auth/validation",
};

export const AuthService = {
  getToken: async () => {
    try {
      const data = await fetch(AuthRoutes.getToken, {
        method: Method.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("data ===> ", data);
      if(!data || !data.token) {
        throw new Error("No token present");
      }

      setSession(data.token);

      return data.token;
    } catch (err) {
      throw new Error(err);
    }
  },
  getValidation: async () => {
    try {
      const data = await fetch(AuthRoutes.getValidation, {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return data === "OK";
    } catch (err) {
      return false;
    }
  },
};
