import { fetch, Method } from 'core/api/fetch';

const TriperRoutes = {
  update: '/api/v1.0.0/triper/update_triper',
  booking: {
    get: (code) => `/api/v1.1.0/triper/booking/${code}`,
    checkin: {
      post: '/api/v1.1.0/triper/booking/checkin',
      patch: '/api/v1.1.0/triper/booking/checkin',
    }
  }
};

export const TriperService = {
  update: async (info) => {
    try {
      const { data } = await fetch(TriperRoutes.update, {
        method: Method.PATCH,
        authenticated: true,
        data: info,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return Array.isArray(data) ? data[0] : false;
    } catch (err) {
      throw new Error(err);
    }
  },

  checkinAuth: async (info) => {
    try {
      const res = await fetch(TriperRoutes.booking.checkin.post, {
        method: Method.POST,
        authenticated: true,
        data: info,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return res;
    } catch (err) {
      throw new Error(err);
    }
  },

  checkinTriper: async (info) => {
    try {
      const res = await fetch(TriperRoutes.booking.checkin.patch, {
        method: Method.PATCH,
        authenticated: true,
        data: info,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return res;
    } catch (err) {
      throw new Error(err);
    }
  },


  getBooking: async (info) => {
    try {
      const res = await fetch(TriperRoutes.booking.get(info), {
        method: Method.GET,
        authenticated: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return res;
    } catch (err) {
      throw new Error(err);
    }
  },

};
