import React, { useState, useEffect, useRef } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { TextField, Popper, Paper, Grow, Snackbar, useMediaQuery, makeStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'core/contexts/localization';
import { useFilters } from 'core/contexts/filter';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  inputText: {
    width: '100%'
  }
})

export const GooglePlacesAutocomplete = ({ initialValue = '', locationId }) => {
  const { t: translate } = useTranslation();
  const { setLocationData } = useFilters();
  const [URLLat, setURLLat] = useQueryParam('lat', NumberParam);
  const [URLLng, setURLLng] = useQueryParam('lng', NumberParam);
  const [URLLocation, setURLLocation] = useQueryParam('location', StringParam);
  const classes = useStyles()

  const [address, setAddress] = useState(initialValue);

  const history = useHistory()

  useEffect(() => {
    setAddress(URLLocation ?? initialValue ?? '');
  }, [initialValue]);

  // for debugging purposes only
  // useEffect(() => {
  //   let args = [
  //     ['initialValue', initialValue],
  //     ['address', address],
  //     ['URLLocation', URLLocation],
  //   ];
  //   console.table(args);
  // }, [initialValue, address, URLLocation]);

  // handle change is a simple setAddress value now
  const handleChange = (value) => {
      setAddress(value)
      if (value === ''){
        history.push('/')
    }
  };
  
  // we watch for changes on url location, like when we click on a div with image - to autocomplete the search bar with that value
  useEffect(() => {
    setAddress(URLLocation)
  }, [URLLocation])

  // we make API calls on handleSelect
  const handleSelect = (value) => {  
    geocodeByAddress(value)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setAddress(value);
          setURLLat(latLng.lat);
          setURLLng(latLng.lng);
          setURLLocation(value);
          console.log('name: ', value);
          console.log('lng: ', latLng.lng);
          console.log('lat: ', latLng.lat);
          setLocationData({ name: value, lng: latLng.lng, lat: latLng.lat });
        })
        .catch((error) => console.error('Error on handleSelect', error));
  };

  const anchorRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <PlacesAutocomplete
      key={locationId}
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const open = loading || !isEmpty(suggestions);

        const { width: minWidth } =
          anchorRef.current?.getBoundingClientRect?.() ?? {};

        return (
          <>
            <div ref={anchorRef} style={isMobile ? { marginTop: 10 } : isMedium ? {marginTop: 20} : {marginTop: 20}}>
              <TextField
              className={classes.inputText}
                InputProps={{
                  style: {
                    fontSize: 20,
                    borderRadius: 30,
                    height: 60,
                    width: '100%',
                    color: '#000000',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginRight: '1.2rem', cursor: 'pointer' }}>
                      <ClearIcon onClick={() => handleChange('')} />
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                {...getInputProps({
                  placeholder: translate('filterBy.whereTo'),
                })}
              />
            </div>
            <Popper
              style={{ zIndex: 1500 }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}>
                  <Paper
                    style={{
                      minWidth,
                      marginTop: '4px',
                      padding: '4px 8px',
                    }}>
                    {loading ? <div>...loading</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                        margin: '6px auto',
                        width: '100%',
                        cursor: 'pointer',
                      };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        );
      }}
    </PlacesAutocomplete>
  );
};
