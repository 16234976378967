import axios from 'axios';
import { isEmpty } from 'lodash';

import { getSession } from 'core/api/session';

//TODO Esto hay que cambiarlo cuando el endpoint funcione independientemente del país
const LocationRoutes = {
  search: (query) => `/api/v1.0.0/location/search/${query}/Colombia`,
};

let cancelToken;

export const LocationService = {
  get: async (query) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      cancelToken = axios.CancelToken.source();
      const token = getSession();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}${LocationRoutes.search(query)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          cancelToken: cancelToken.token,
        }
      );

      const result = data?.data;

      return isEmpty(result) ? [] : result;
    } catch (err) {
      throw new Error(err);
    }
  },
};
