// imagenes para las cards
import medellin from '../../../assets/images/cities/medellin.jpg';
import sanAndres from '../../../assets/images/cities/sanAndres.jpg';
import santaMarta from '../../../assets/images/cities/santaMarta.jpg';
import tayrona from '../../../assets/images/cities/tayrona.jpg';
import villaDeLeyva from '../../../assets/images/cities/villaDeLeyva.jpg';
import laGuajira from '../../../assets/images/cities/laGuajira.jpg';
import bogota from '../../../assets/images/cities/bogota.jpg';
import salento from '../../../assets/images/cities/salento.jpg';
// import barranquilla from '../../../assets/images/cities/barranquilla.jpg'
import cartagena from '../../../assets/images/cities/cartagena.jpg';

const destinations = [
    {
        id: 1,
        name: 'Medellín, Medellin, Antioquia, Colombia',
        cardText: 'Medellín',
        lat: 6.2476376,
        lng: -75.56581530000001,
        imageUrl: medellin,
        url: '/?lat=6.2476376&lng=-75.56581530000001&location=Medellín%2C Medellin%2C Antioquia%2C Colombia&radius=100'
    },
    {
        id: 2,
        name: 'San Andrés, San Andres and Providencia, Colombia',
        cardText: 'San Andrés',
        lat: 12.576855,
        lng: -81.705052,
        imageUrl: sanAndres,
        url: '/?lat=12.576855&lng=-81.705052&location=San Andrés%2C San Andres and Providencia%2C Colombia&radius=100'
    },
    {
        id: 3,
        name: 'Santa Marta, Magdalena, Colombia',
        cardText: 'Santa Marta',
        lat: 11.2403547,
        lng: -74.2110227,
        imageUrl: santaMarta,
        url: '?lat=11.2403547&lng=-74.2110227&location=Santa Marta%2C Magdalena%2C Colombia&radius=100'
    },
    {
        id: 4,
        name: 'Tayrona National Park, Magdalena, Colombia',
        cardText: 'Tayrona',
        lat: 11.3064409,
        lng: -74.0657561,
        imageUrl: tayrona,
        url: '/?lat=11.3064409&lng=-74.0657561&location=Tayrona National Park%2C Magdalena%2C Colombia&radius=100'
    },
    {
        id: 5,
        name: 'Villa de Leyva, Boyacá, Colombia',
        cardText: 'Villa de Leyva',
        lat: 5.636499,
        lng: -73.527058,
        imageUrl: villaDeLeyva,
        url: '/?lat=5.636499&lng=-73.527058&location=Villa de Leyva%2C Boyaca%2C Colombia&radius=100'
    },
    {
        id: 6,
        name: 'La Guajira, Colombia',
        cardText: 'La Guajira',
        lat: 11.3547743,
        lng: -72.5204827,
        imageUrl: laGuajira,
        url: '/?lat=11.3547743&lng=-72.5204827&location=La%20Guajira%2C%20Colombia&radius=150'
    },
    {
        id: 7,
        name: 'Bogotá, Bogota, Colombia',
        cardText: 'Bogotá',
        lat: 4.710988599999999,
        lng: -74.072092,
        imageUrl: bogota,
        url: '/?lat=4.710988599999999&lng=-74.072092&location=Bogotá%2C%20Bogota%2C%20Colombia&radius=100'
    },
    {
        id: 8,
        name: 'Salento, Quindio, Colombia',
        cardText: 'Salento',
        lat: 4.637365,
        lng: -75.5703107,
        imageUrl: salento,
        url: '/?lat=4.637365&lng=-75.5703107&location=Salento%2C%20Quindio%2C%20Colombia&radius=100'
    },
    {
        id: 9,
        name: 'Cartagena, Cartagena Province, Bolivar, Colombia',
        cardText: 'Cartagena',
        lat: 10.3932277,
        lng: -75.4832311,
        imageUrl: cartagena,
        url: '/?lat=10.3932277&lng=-75.4832311&location=Cartagena%2C%20Cartagena%20Province%2C%20Bolivar%2C%20Colombia&radius=100'
    },
];

export default destinations;
