import React, { useContext, useState } from 'react';
import { isEmpty } from 'lodash';


const PaggingContext = React.createContext(null);

export const PaggingProvider = ({ children }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const resetCurrentPage = () => {
        setCurrentPage(1);
    };

    return (
        <PaggingContext.Provider
            value={{
                nextPage,
                resetCurrentPage,
                currentPage,
                hasMorePages,
                setHasMorePages
            }}>
            {children}
        </PaggingContext.Provider>
    );
};

PaggingProvider.displayName = 'PaggingProvider';

export const usePagging = () => useContext(PaggingContext);
